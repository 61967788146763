import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { IGetEventListAPIData } from "../../../apis/eventAPI/GetEventListAPI"
import ImageComponent from "../../../components/ImageComponent"
import { getFormattedDateSec } from "../../../utils/functions/CommonFunctions"

const EventCard = ({ data }: { data: IGetEventListAPIData }) => {
    const navigate = useNavigate()
    return (
        <div onClick={() => {
            navigate(`/event/${data?.eventId}`)
        }} className="d-flex cursor-pointer mb-30 p-10 shadow rounded-10 bg-white gap-3">
            <ImageComponent src={data?.logo} width={"100px"} height={"100px"} />
            <div className='mw-75'>
                <div className="d-flex font-15">
                    <p className="text-danger text-wrap responsive-event-time">{getFormattedDateSec(data?.startTime)}</p>
                    {
                        data?.type === "private" ?
                            <p className="ml-10 text-danger fw-600 bg-white">
                                Private
                            </p> : null
                    }
                </div>
                <p className="c-black mt-10 text-break responsive-event-name">{data?.name}</p>
                <p className="c-gray mt-10 text-break ellipsis">
                    <FontAwesomeIcon icon={faLocationDot} className="mr-10" />
                    {data?.address}
                </p>
            </div>
        </div>
    )
}

export default EventCard