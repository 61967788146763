import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const GetByNFTAPI = (id: string): Promise<IPublicGetByNFTAPI> => {
	return HTTPService.get(API_END_POINTS.NFT.GET_BY_NFT + `/${id}`);
};

export interface IPublicGetByNFTAPIData {
	attributes: {
		trait_type: string;
		value: string;
	}[];
	categoryId: string;
	compressedFilePath: string;
	createdAt: string;
	createdBy: {
		email: string;
		firstName: string;
		lastName: string;
	};
	currency: string;
	description: string;
	filePath: string;
	fileThumbPath: string;
	fileType: string;
	gateway: number;
	history: {
		createdAt: string;
		currency: string;
		event: string;
		from: string;
		txUrl: string;
		fromUser: {
			email: string;
			name: string;
			profilePic: string;
			userId: string;
			walletAddress: string;
		} | null;
		price: string;
		to: string
		toUser: {
			email: string;
			name: string;
			profilePic: string;
			userId: string;
			walletAddress: string;
		} | null;
	}[];
	id: number;
	ipfsFileHash: string;
	ipfsJsonHash: string;
	isApproved: boolean;
	isReserved: boolean;
	isVisible: boolean;
	nftId: string;
	ownBy: string;
	owner: {
		email: string;
		name: string;
		profilePic: string;
		userId: string;
		walletAddress: string;
	} | null;
	price: null;
	tags: [];
	title: string;
	tokenId: number;
	txUrl: string;
	updatedAt: string;
	userId: string;
	requestedBy: string[
	// {
	// 	email: string;
	// 	name: string;
	// 	profilePic: string;
	// 	userId: string;
	// 	walletAddress: string;
	// }
	];
}

interface IPublicGetByNFTAPI extends IApiRes {
	data: IPublicGetByNFTAPIData;
}
