import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { IGetProfileAPIData, getProfileAPI } from "../../apis/user/GetProfileAPI"
import { images } from "../../assets/images/Images"
import CustomTooltip from "../../components/tooltip/ToolTip"
import { RootState } from "../../store/Store"
import { setUserLoginData } from "../../store/UserSlice"
import "../../style/profile.scss"
import { cookieKeys } from "../../utils/constants/Constants"
import { RoutePathConst } from "../../utils/constants/RoutesPathConst"
import { environment } from "../../utils/constants/environment"
import { copyToClipboard, getDecryyptedCookie, getFormattedDateMonths, setEncryptedCookie } from "../../utils/functions/CommonFunctions"
import NFTFooter from "../nft/components/NFTFooter"
import NFTHeader from "../nft/components/NFTHeader"
import NFTQrCodeModal from "../nft/components/NFTQrCodeModal"
import ProfileModal from "./components/ProfileModal"

const Profile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userReducer = useSelector((state: RootState) => state.user)

    const [user, setUser] = useState<IGetProfileAPIData>()
    const [modal, setModal] = useState({ isOpen: false })
    const [qrCodeModal, setQrCodeModal] = useState({
        isOpen: false,
        id: ""
    });

    const getUserAction = () => {
        getProfileAPI().then(res => {
            if (res.status === 200) {
                setUser(res.data)
                const newObject = getDecryyptedCookie(cookieKeys.cookieUser)
                newObject.user.name = res.data?.name
                newObject.user.profileImage = res.data?.profileImage
                setEncryptedCookie(cookieKeys.cookieUser, newObject)
                dispatch(setUserLoginData(newObject.user))
            }
        })
    }

    useEffect(() => {
        getUserAction()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setModal({ isOpen: false })
    }

    const handleQrcodeToggle = () => {
        setQrCodeModal({ isOpen: false, id: "" })
    }

    return (
        <div className="nft-section">
            <NFTHeader />
            <div className="position-relative banner-section" style={{
                background: "linear-gradient(90deg, #B02896 0%, #220197 100%)"
            }}>
                <img className="w-100" src={"https://edx-nft-live.s3.eu-central-1.amazonaws.com/assets/images/profile-banner.png"} alt="" />
                <p className="cursor-pointer edit-profile" onClick={() => setModal({ isOpen: true })}>Edit Profile</p>
            </div>

            <Container>
                <Row>
                    <Col sm={12} className="position-relative mt-20">
                        <img src={user?.profileImage || userReducer?.user?.profileImage || images?.defaultUserImg} className="profile-img c-bg-green position-absolute" alt="user-profile" />
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="d-flex gap-2">
                                <div style={{ width: "150px" }}></div>
                                <div className="" style={{ maxWidth: 800 }}>
                                    <h1 className="mb-0 text-truncate">{user?.name || userReducer?.user?.name}</h1>
                                    <p className="text-change fw-600 text-truncate">{user?.email || userReducer?.user?.email}</p>
                                </div>
                            </div>
                            <div className="d-flex profile-edit justify-content-end gap-3 mt-md-0 mt-4">
                                <div className="d-flex gap-1 cursor-pointer align-items-center" onClick={() => navigate(RoutePathConst.private.ownNft)}>
                                    <h2 className="c-black fw-600">{user?.ownNftCount || 0}</h2>
                                    <h6 className="c-black">My Digital Owner's</h6>
                                </div>
                                <div className="d-flex align-items-center" onClick={() => setQrCodeModal({ isOpen: true, id: `${environment?.domainUrl}user/${user?.userId || userReducer?.user?.userId}` })}>
                                    <CustomTooltip direction="top" content="Profile QR Code">
                                        <img className="cursor-pointer" onClick={handleToggle} src={images.qrCode} alt="qrCode" />
                                    </CustomTooltip>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} className="mt-30">
                        <div className="bg-white d-flex rounded-1 p-2" style={{ height: "70px" }}>
                            <div className="c-bg-green d-flex justify-content-center align-items-center rounded-1 px-4">
                                <p className="fw-600 text-white">Overview</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} className="mt-30">
                        <div className="bg-white rounded-1 p-40">
                            <p className="c-black fw-600">Full Name</p>
                            <p className="c-gray text-break mt-1">{user?.name || userReducer?.user?.name}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} className="mt-30">
                        <div className="bg-white rounded-1 p-40">
                            <p className="c-black fw-600">Email</p>
                            <p className="c-gray text-break mt-1">{user?.email || userReducer?.user?.email}</p>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={5} className="mt-30">
                        <div className="bg-white rounded-1 p-40">
                            <p className="c-black fw-600">Joined</p>
                            <p className="c-gray text-break mt-1">{getFormattedDateMonths(user?.createdAt || userReducer?.user?.createdAt)}</p>
                        </div>
                    </Col>
                    <Col lg={5} md={9} sm={12} className="mt-30 mb-30">
                        <div className="bg-white rounded-1 cursor-pointer p-40" onClick={() => { copyToClipboard("Wallet address", user?.walletAddress || "") }}>
                            <p className="c-black fw-600">Wallet Address</p>
                            <p className="c-gray text-break mt-1">{user?.walletAddress || userReducer?.user?.walletAddress}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <NFTQrCodeModal handleToggle={handleQrcodeToggle} modal={qrCodeModal} />
            <ProfileModal modal={modal} user={user} setUser={setUser} onClick={handleToggle} reCallAPI={getUserAction} />
            <NFTFooter />
        </div>
    )
}

export default Profile