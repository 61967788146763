import { useRoutes } from "react-router-dom";
import ComingSoonModal from "../components/ComingSoonModal";
import { privateRoutes } from "./Routes";

export const PrivateRouteComponent = () => {
  const privateRoutesRender = useRoutes(privateRoutes);
  return <>
    <ComingSoonModal />
    {privateRoutesRender}
  </>;
};
