import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { environment } from "../../utils/constants/environment";
import { IApiRes } from "../ActionType";

export const GetValidateHashAPI = (validateHash: string): Promise<IGetValidateHashAPI> => {
	return HTTPService.get(environment.mobileBaseUrl + API_END_POINTS.VALIDATE_HASH.GET_VALIDATE_HASH + validateHash);
};

export const GetEventValidateHashAPI = (validateHash: string): Promise<IGetValidateHashAPI> => {
	return HTTPService.get(environment.mobileBaseUrl + API_END_POINTS.VALIDATE_HASH.GET_EVENT_VALIDATE_HASH + validateHash);
};

export interface IGetValidateHashAPIRes {
	comment: string;
	name: string;
	createdAt: string;
	deviceId: string;
	qrCodeId: string;
	quesId: string;
	event: string;
	eventId: string;
	address: string;
	location: {
		latitude: string;
		longitude: string;
	};
	checkPoint: {
		checkPointId: string,
		createdBy: string
	}
	reviewId: string;
	txId: string;
	updatedAt: string;
	images: string[];
	question: {
		text: string;
	};
}

interface IGetValidateHashAPI extends IApiRes {
	data: any;
}
