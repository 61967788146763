/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserVerifyEmailAPI } from "../apis/authAPI/userVerifyEmailAPI";
import { RoutePathConst } from "../utils/constants/RoutesPathConst";
import { toastSuccess } from "../utils/functions/CommonFunctions";

const useVerifyEmail = () => {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname.includes("/user-verify") && params?.inviteToken) {
			UserVerifyEmailAPI(params?.inviteToken)
				.then((res) => {
					if (res.status === 200) {
						navigate(RoutePathConst.public.allNft);
						toastSuccess(res.message);
					}
				})
				.catch(() => {
					navigate(RoutePathConst.public.allNft);
				});
		}
	}, [location.pathname]);

	return null;
};

export default useVerifyEmail;
