export const StatusCode = {
	ok: 200,
	created: 201,
	accepted: 202,
	conditional: 428,
	no_content: 204,
	too_early: 425,
	bad_request: 400,
	unauthorized: 401,
	forbidden: 403,
	not_found: 404,
	method_not_allowed: 405,
	not_acceptable: 406,
	maintenance: 503
};
