/* eslint-disable react-hooks/exhaustive-deps */
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import { useEffect, useId } from "react"
import { Input, Modal, ModalBody } from "reactstrap"
import * as Yup from "yup"
import { handleFormikTrim } from "../../../utils/functions/CommonFunctions"

interface IMetaData {
    handleToggle: () => void;
    metaData: {
        isOpen: boolean;
    };
    setValue: any;
    data: any;
}

const MetaDataModal = ({ handleToggle, metaData, setValue, data }: IMetaData) => {
    const id = useId()
    const formik = useFormik({
        initialValues: {
            metaData: [{ trait_type: "", value: "" }]
        },
        validationSchema: Yup.object().shape({
            metaData: Yup.array()
                .of(
                    Yup.object().shape({
                        trait_type: Yup.string().required(),
                        value: Yup.string().required(),
                    }))
        }),
        onSubmit: (value) => {
            setValue("metaData", value.metaData)
            handleToggleMetaData()
        }
    })
    const { resetForm, errors, handleSubmit, values, setErrors, handleBlur, setFieldValue, touched } = formik

    const handleToggleMetaData = () => {
        resetForm()
        handleToggle()
    }
    const handleAddNew = () => {
        const newArray = [...values?.metaData]
        newArray.push({
            trait_type: "",
            value: ""
        })
        newArray.length < 5 && setFieldValue("metaData", newArray)
    }

    const handleDeleteMetaData = (index: number) => {
        const tempArray = [...values?.metaData]
        tempArray.splice(index, 1)
        if (values?.metaData?.length === 1 && index === 0) {
            setFieldValue("metaData", [{ key: "", value: "" }])
            setErrors({ ...errors, metaData: [] })
        } else {
            setFieldValue("metaData", tempArray)
        }
    }

    useEffect(() => {
        if (metaData) {
            setFieldValue("metaData", data)
        }
    }, [metaData, data])

    return (
        <Modal isOpen={metaData?.isOpen} toggle={handleToggleMetaData} centered size="lg">
            <ModalBody className="p-30">
                <FontAwesomeIcon icon={faXmark} onClick={handleToggleMetaData} className="cursor-pointer c-green position-absolute" style={{ top: "10px", right: "20px" }} />
                <div className="d-flex justify-content-between align-items-center">
                    <p className="c-black font-20 fw-600">Meta data</p>
                    {
                        values.metaData?.length < 4 ?
                            <p className="c-green fw-600 cursor-pointer" onClick={handleAddNew}>Add New</p>
                            : null
                    }
                </div>
                {
                    values?.metaData?.map((res, index) => (
                        <div className="d-flex gap-3 mt-30" key={`${id}${index}`}>
                            <Input className="p-20" placeholder="Name" onBlur={handleBlur} name={`metaData[${index}].trait_type`}
                                onChange={(e) => {
                                    handleFormikTrim(e.target.name, e.target.value, setFieldValue)
                                }}
                                maxLength={20}
                                value={values.metaData[index].trait_type}
                                // @ts-ignore
                                invalid={Boolean(touched?.metaData?.[index]?.trait_type && errors?.metaData?.[index]?.trait_type)} 
                                />
                            <Input className="p-20" placeholder="Value"
                                onBlur={handleBlur} onChange={(e) => {
                                    handleFormikTrim(e.target.name, e.target.value, setFieldValue)
                                }}
                                maxLength={20}
                                name={`metaData[${index}].value`}
                                value={values.metaData?.[index]?.value}
                                // @ts-ignore
                                invalid={Boolean(touched?.metaData?.[index]?.value && errors?.metaData?.[index]?.value)}
                            />
                            <div onClick={() => handleDeleteMetaData(index)} className={`cursor-pointer d-flex ${values?.metaData?.length === 1 ? "disable" : ""} p-20 rounded-2 justify-content-center align-items-center`} style={{ border: "1px solid #dee2e6" }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </div>
                    ))
                }
                <div className="d-flex justify-content-end gap-3 mt-30">
                    <button className="custom-primary-outline" onClick={handleToggleMetaData}>Cancel</button>
                    <button type="submit" onClick={() => handleSubmit()} className="custom-primary">Save</button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default MetaDataModal