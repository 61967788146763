import { useEffect, useState } from "react"
import { Input, Label } from "reactstrap"
import { IGetCategoryAPIRes } from "../../../../../apis/categoryAPI/GetCategoryAPI"
import { primaryColor } from "../../../../../utils/constants/Constants"

const CategoryInputCheck = ({ data, onClick, filter }: {
    data: IGetCategoryAPIRes, onClick: (res: IGetCategoryAPIRes) => void, filter: {
        page: number;
        limit: number;
        filter: string[];
    };
}) => {
    const [check, setCheck] = useState(false)

    useEffect(() => {
        if (filter.filter.includes(data?.categoryId)) {
            setCheck(true)
        } else {
            setCheck(false)
        }
    }, [filter, data])

    return (
        <Label check className="c-black fw-600 cursor-pointer">
            <Input checked={check} className="mr-10" color={primaryColor} type="checkbox" onChange={(e) => {
                onClick(data)
                setCheck(e.target.checked)
            }} />
            {data?.name}
            {data?.nftCount === 0 ? ` (${data?.nftCount})` : ` (${data?.nftCount > 9 ? data?.nftCount : "0" + data?.nftCount} )`}
        </Label>
    )
}

export default CategoryInputCheck