import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { images } from "../../../assets/images/Images";
import { RootState } from "../../../store/Store";
import { powerByText } from "../../../utils/constants/Constants";
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst";
import { environment } from "../../../utils/constants/environment";
import { redirectNewTap } from "../../../utils/functions/CommonFunctions";

const NFTFooter = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { isLogin } = useSelector((state: RootState) => state.authSlice)
	return (
		<Container className="mt-20 public-nft-footer">
			<Row>
				<Col sm={10}>
					<div className="w-100">
						<p className="c-black fw-600">Project Partner</p>
						<img width={160} onClick={() => redirectNewTap(environment.bankUrl || "")} alt="Liechtensteinischer Bankenverband logo" className="mt-20 cursor-pointer" src={images.logoBRI} />
					</div>
				</Col>

				{location?.pathname !== RoutePathConst.public.welcomePageIos && (
					<Col sm={2}>
						<div className="w-100">
							<p className="c-black mb-10 fw-600 public-nft-footer-title">Explore</p>
							<div className="d-flex public-nft-footer-explore flex-column align-items-start">
								<p className={`${location?.pathname === RoutePathConst.public.allNft ? "text-white" : location?.pathname === "/" ? "text-white" : "c-black"} cursor-pointer`} onClick={() => navigate(RoutePathConst.public.allNft)}>
									All Digital Owner's
								</p>
								{
									isLogin ?
										<p className={`${location?.pathname === RoutePathConst.private.createNFT ? "text-white" : "c-black"} cursor-pointer`} onClick={() => navigate(RoutePathConst.private.createNFT)}>
											Create Digital Owner
										</p> : null
								}
								<p className={`${location?.pathname === RoutePathConst.public.validateHash ? "text-white" : "c-black"} cursor-pointer`} onClick={() => navigate(RoutePathConst.public.validateHash)}>
									Validate Hash
								</p>
								<p className="c-black cursor-pointer" onClick={() => redirectNewTap(RoutePathConst.public.smartCity)}>
									Smart City
								</p>
							</div>
						</div>
					</Col>
				)}
				<Col sm={12}>
					<hr className="c-green" />
				</Col>
				<Col sm={12}>
					<p className="c-black text-center mb-20 cursor-pointer" onClick={() => redirectNewTap(environment.edexaUrl || "")}>
						{powerByText}
					</p>
				</Col>
			</Row>

		</Container>
	);
};

export default NFTFooter;
