import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { GetByNFTAPI } from '../../../apis/nftAPI/GetByNFTAPI';
import { setLoading } from '../../../store/LoaderSlice';
import { moduleType, qrCodeURL } from '../../../utils/constants/Constants';
import { RoutePathConst } from '../../../utils/constants/RoutesPathConst';
import { toastError } from '../../../utils/functions/CommonFunctions';
import "./qr-code-scanner.scss";

export const QRCodeScanner = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [permission, setPermission] = useState(true)
    const [data, setData] = useState('Please scan the QR codes now!');
    const [modal, setModal] = useState({
        isOpen: false,
        text: "The Digital Owner does not exit. It cloud potentially be made Private either by User or Admin."
    })

 const handleUrlAction = (result: string) => {
        try {
            if (String(result).includes(qrCodeURL.checkpoint)) {
                navigate(`/checkpoint/${result.split(qrCodeURL.checkpoint)[1]}`, { state: { qrCodeType: moduleType.checkpoint } })
            } else if (String(result).includes(qrCodeURL.publicEvent)) {
                navigate(`/checkpoint/${result.split("qrcodeid=")[1]}`, { state: { qrCodeType: moduleType.event } })
            } else if (String(result).includes(qrCodeURL.privateEvent)) {
                navigate("/event/" + result.split("?eventid=")[1])
            } else if (String(result).includes(qrCodeURL.nft)) {

                if (result?.includes("nft")) {
                    setData("Loading...")
                    dispatch(setLoading(true))
                    GetByNFTAPI(result.split("/nft/")[1]).then(res => {
                        if (res.status === 200) {
                            dispatch(setLoading(false))
                            navigate("/nft/" + res.data.id)
                            navigator?.vibrate(200);
                        }
                    }).catch(() => {
                        dispatch(setLoading(false))
                        setData("No Data")
                        setModal({
                            isOpen: true,
                            text: "The Digital Owner does not exit. It cloud potentially be made Private either by User or Admin."
                        })
                    })
                } else {
                    setData("No Data")
                    setModal({
                        isOpen: true,
                        text: "The Digital Owner does not exit. It cloud potentially be made Private either by User or Admin."
                    })
                }
                navigate(`/nft/${String(result).split(qrCodeURL.nft)[1]}`)
            } else {
                toastError("QR Code is invalid!")
            }
        } catch (error) {
            console.error('No QR Code Found:', error);
            toastError("No QR Code Found")
        }
    }

    const handleURL = (url: string) => {
        handleUrlAction(url)
        // if (url?.includes("nft")) {
        //     setData("Loading...")
        //     dispatch(setLoading(true))
        //     GetByNFTAPI(url.split("/nft/")[1]).then(res => {
        //         if (res.status === 200) {
        //             dispatch(setLoading(false))
        //             navigate("/nft/" + res.data.id)
        //             navigator?.vibrate(200);
        //         }
        //     }).catch(() => {
        //         dispatch(setLoading(false))
        //         setData("No Data")
        //         setModal({
        //             isOpen: true,
        //             text: "The NFT does not exit. It cloud potentially be made Private either by User or Admin."
        //         })
        //     })
        // } else {
        //     setData("No Data")
        //     setModal({
        //         isOpen: true,
        //         text: "The NFT does not exit. It cloud potentially be made Private either by User or Admin."
        //     })
        // }
    }

    return (
        <div className="vh-100 position-relative">
            <div style={{ zIndex: 1 }} onClick={(e) => {
                e.stopPropagation()
                navigate(-1)
            }} className='position-absolute cursor-pointer bg-dark-subtle rounded-1 p-10 d-flex justify-content-center align-items-center'>
                <FontAwesomeIcon className='text-white' icon={faArrowLeftLong} />
            </div>
            <h1 className='text-center mt-10 fw-600'>Scan with QR-Code</h1>
            {
                permission ?
                    <QrReader
                        constraints={{
                            facingMode: 'environment'
                        }}
                        onResult={(result: any, error: any) => {
                            if (!!result) {
                                handleURL(result?.text)
                                setPermission(false)
                            }
                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        className='mt-30'
                    /> : null
            }
            <div className='d-flex justify-content-center align-items-center mt-20'>{data === "Loading..." ? <>
                <p className='fw-600'>{data}</p><Spinner className='ml-10' size={"sm"} />
            </> : <p className='text-center'>{data}</p>}</div>
            <Modal isOpen={modal.isOpen} centered>
                <ModalBody>
                    <p className='c-gray'>{modal?.text}</p>
                    <div className='d-flex justify-content-end mt-20'>
                        <p className='cursor-pointer c-green' onClick={() => {
                            setModal({ ...modal, isOpen: false })
                            navigate(RoutePathConst.public.allNft)
                        }}>Okay</p>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};