import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./AuthSlice";
import comingSoonSlice from "./ComingSoonSlice";
import deviceIdSlice from "./DeviceIdSlice";
import globalSettingSlice from "./GlobalSettingSlice";
import labelSlice from "./LabelSlice";
import loaderSlice from "./LoaderSlice";
import notificationSlice from "./NotificationSlice";
import userSlice from "./UserSlice";

const rootReducer = combineReducers({
	loader: loaderSlice,
	authSlice: authSlice,
	globalSetting: globalSettingSlice,
	user: userSlice,
	comingSoon: comingSoonSlice,
	notification: notificationSlice,
	deviceId: deviceIdSlice,
	label: labelSlice
});

const store = configureStore({
	reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
