import { useState } from "react"
import { Spinner } from "reactstrap"
import { images } from "../assets/images/Images"

const ImageComponent = ({ src, className, width, height }: { src: string, className?: string, width?: string, height?: string }) => {
    const [imgState, setImgState] = useState({
        isError: false,
        isLoading: true,
        src
    })
    const onError = () => {
        setImgState({ ...imgState, isError: true })
    }
    const onLoad = () => {
        setImgState({ ...imgState, isLoading: false })
    }

    return (
        <div className={`${width || "w-100"} rounded-2 overflow-hidden ${className}`} style={{ height: height || "250px", width: width || "", minWidth: width || ""}}>
            {
                imgState.isLoading ?
                    <div className="h-100 bg-dark d-flex justify-content-center align-items-center">
                        <Spinner className="text-white" />
                    </div> : null
            }
            <img src={imgState.isError ? images.nftError : imgState.src} onLoad={onLoad} onError={onError} className="w-100 object-fit-cover h-100" alt="comment" />
        </div>
    )
}

export default ImageComponent

