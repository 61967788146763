import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { IUserValidateAPIData, UserValidateAPI } from "../../../apis/nftAPI/UserValidateAPI";
import { images } from "../../../assets/images/Images";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { RootState } from "../../../store/Store";
import { debounceTimeInMilliseconds } from "../../../utils/constants/Constants";


interface INFTReserveToggle {
    handleClick: (modalType: "reserve" | "transfer", key?: string, callBack?: any) => void;
    isOpen: {
        open: boolean;
    };
    handleClose: () => void;
}

const NFTTransferToggle = ({ handleClick, isOpen, handleClose }: INFTReserveToggle) => {
    const { user } = useSelector((state: RootState) => state.user);
    const isLoading = useSelector((state: RootState) => state.loader.isLoading);

    const [search, setSearch] = useState("");
    const [data, setData] = useState<IUserValidateAPIData | null>()

    const handleToggle = () => {
        handleClose()
        setSearch("")
        setData(undefined)
    }

    const UserValidateAction = (name: string) => {
        UserValidateAPI(name).then(res => {
            if (res.status === 200) {
                setData(res.data)
            }
        }).catch(err => {
            if (err.data.status === 404) {
                setData(null)
            }
        })
    }

    const handleSubmit = () => {
        handleClose()
        handleClick("transfer", data?.userId, handleToggle)
    }

    useDebouncedEffect(
        () => {
            if (search) {
                UserValidateAction(search);
            } else {
                setData(undefined)
            }
        },
        debounceTimeInMilliseconds,
        [search]
    );

    return (
        <Modal isOpen={isOpen.open} size="lg" centered toggle={handleToggle}>
            <ModalBody className="p-4 position-relative">
                <FontAwesomeIcon icon={faXmark} className="cursor-pointer c-green r-25 position-absolute" onClick={handleToggle} />
                <p className="fw-600 font-26 c-black mb-0">Transfer Digital Owner</p>
                <div className="mt-20">
                    <Label className="c-black" htmlFor="title">
                        Validate user <span className="text-danger">*</span>
                    </Label>
                    <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Enter a valid email address or wallet address." invalid={Boolean(data === null)} valid={Boolean(data?.walletAddress)} />
                </div>
                {
                    data?.walletAddress ?
                        <Alert className="mt-20 rounded-2 p-20" color={data?.walletAddress === user.walletAddress ? "danger" : "success"}>
                            {
                                data?.walletAddress === user.walletAddress ?
                                    "Digital Owner cannot be transferred to yourself"
                                    :
                                    <div className="d-flex gap-3">
                                        <img src={images?.defaultUserImg} alt="user profile" className="overflow-hidden" width={70} height={70} style={{ borderRadius: "50%" }} />
                                        <div className="w-75">
                                            <p className="fw-600 text-break">{data?.name}</p>
                                            <p className="fw-600 text-break">{data?.email}</p>
                                            <p className="fw-600 text-break">{data?.walletAddress}</p>
                                        </div>
                                    </div>
                            }
                        </Alert> : null
                }
                {
                    data === null ?
                        <Alert color="danger" className="mt-10">
                            User does not exist
                        </Alert>
                        : null
                }
            <div className="d-flex gap-3 justify-content-end mt-20">
                    <button disabled={isLoading} className="custom-primary-outline" onClick={handleToggle}>
                        Cancel
                    </button>
                    <button type="submit" disabled={isLoading ? true : Boolean(data?.walletAddress ? data?.walletAddress === user.walletAddress : true)} className="custom-primary" onClick={() => handleSubmit()}>
                        Transfer Digital Owner
                        {isLoading ? <Spinner size={"sm"} className="c-black ml-10" /> : null}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default NFTTransferToggle;
