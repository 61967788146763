/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
import { IGetProfileAPIData } from "../../../apis/user/GetProfileAPI";
import { updateProfileAPI } from "../../../apis/user/UpdateProfileAPI";
import { images } from "../../../assets/images/Images";
import { setLoading } from "../../../store/LoaderSlice";
import { RootState } from "../../../store/Store";
import { setUserLoginData } from "../../../store/UserSlice";
import { cookieKeys } from "../../../utils/constants/Constants";
import { getBase64, getDecryyptedCookie, handleFormikTrim, setEncryptedCookie, toastError, toastSuccess } from "../../../utils/functions/CommonFunctions";

interface IProfileModal {
    modal: {
        isOpen: boolean;
    };
    onClick: () => void;
    user: IGetProfileAPIData | undefined;
    reCallAPI: () => void;
    setUser: React.Dispatch<React.SetStateAction<IGetProfileAPIData | undefined>>;
}

const ProfileModal = ({ modal, onClick, user, setUser, reCallAPI }: IProfileModal) => {
    const dispatch = useDispatch()

    const { isLoading } = useSelector((state: RootState) => state.loader);
    const [img, setImg] = useState({
        imgSrc: ""
    })

    const formik = useFormik({
        initialValues: {
            name: String(user?.name) || "",
            profileImage: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Full name is required").min(3, "Minimum 3 characters required").max(80, "Maximum 80 characters required"),
            profileImage: Yup.mixed()
        }),
        onSubmit: (value) => {
            dispatch(setLoading(true))
            const formData = new FormData()
            if(user?.name !== value?.name){
                formData.append("name", value?.name)
            }
            if(user?.profileImage !== value?.profileImage){
                formData.append("profileImage", value?.profileImage)
            }
            updateProfileAPI(formData).then(res => {
                if (res.status === 200) {
                    toastSuccess(res.message)
                    handleToggle()
                    if (value?.profileImage) {
                        setUser((preState: any) => {
                            return { ...preState, name: value.name, profileImage: img.imgSrc, }
                        })
                    }
                    setUser((preState: any) => {
                        return { ...preState, name: value.name, }
                    })
                    const newObject = getDecryyptedCookie(cookieKeys.cookieUser)
                    newObject.user.name = value?.name
                    
                    setEncryptedCookie(cookieKeys.cookieUser, newObject)
                    dispatch(setUserLoginData(newObject.user))
                    dispatch(setLoading(false))
                    reCallAPI()
                }
            })

        }
    })
    const { errors, handleSubmit, values, handleBlur, setFieldValue, touched } = formik

    const handleToggle = () => {
        if (!isLoading) {
            onClick()
        }
    }

    useEffect(() => {
        if (user) {
            setFieldValue("name", user?.name)
            setFieldValue("profileImage", user?.profileImage)
            setImg({
                // @ts-ignore
                imgSrc: user?.profileImage
            });
        }
    }, [user])

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputElement = e.target;
        const file = inputElement?.files?.[0];
        if (file) {
            const fileType = file.type;
            const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"];
            if (validImageTypes.includes(fileType)) {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = function () {
                    const width = img.width;
                    const height = img.height;
                    if (width < 336 || height < 280) {
                        toastError("Image dimensions must be at least 336x280 pixels!");
                        return;
                    } else {
                        if (file.size > 20000000) {
                            toastError("File size should not exceed more than 20MB");
                        } else {
                            getBase64(file).then((res) => {
                                if (res) {
                                    setFieldValue("profileImage", file);
                                    setImg({
                                        // @ts-ignore
                                        imgSrc: res
                                    });
                                }
                            });
                        }
                    }
                };
            } else {
                toastError("Please select image only");
            }
            inputElement.value = '';
        }
    };

    return (
        <Modal centered size="lg" isOpen={modal.isOpen} toggle={handleToggle}>
            <ModalBody>
                <div className="w-100">
                    <FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green r-25 position-absolute" />
                    <p className="fw-600 font-20">Edit Profile Details</p>
                    <div className="d-flex flex-lg-row flex-column gap-3 flex-wrap">
                        <div className="d-flex mt-md-0 mt-3 justify-content-center align-items-center overflow-hidden">
                            <Label htmlFor="profile">
                                <img className="profile-img c-border-green cursor-pointer" src={img?.imgSrc || images.defaultUserImg} alt="" />
                                <Input id="profile"
                                    onChange={(e) => {
                                        handleFile(e)
                                    }}
                                    type="file" className="d-none" />
                            </Label>
                        </div>
                        <div className="flex-grow-1">
                            <div className="mt-20">
                                <Label className="c-black fw-600">Full Name</Label>
                                <Input
                                    invalid={Boolean(touched?.name && errors?.name)}
                                    value={values.name} name="name" id="name" onChange={(e) => handleFormikTrim(e.target.name, e.target.value, setFieldValue)} onBlur={handleBlur}
                                    placeholder="Enter your full name"
                                />
                                {touched?.name && errors?.name && <span className="text-danger">{errors?.name}</span>}
                            </div>
                            <div className="mt-10">
                                <Label className="c-black fw-600">Email</Label>
                                <Input disabled value={user?.email} placeholder="Enter your Email" />
                            </div>
                            <div className="mt-10">
                                <Label className="c-black fw-600">Wallet Address</Label>
                                <Input disabled value={user?.walletAddress} placeholder="Enter your Email" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end gap-3">
                        <button disabled={isLoading} type="submit" className="custom-primary-outline mt-20" onClick={handleToggle}>Cancel</button>
                        <button disabled={isLoading} type="submit" className="custom-primary mt-20" onClick={() => handleSubmit()}>
                            {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className=" fw-bold">Loading...</span>
                                    <Spinner className="ml-10" size={"sm"} />
                                </div>
                            ) : (
                                "Update Profile"
                            )}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal >
    )
}

export default ProfileModal