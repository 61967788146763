import React, { useEffect, useState } from 'react';
import { getFormattedDateSec } from '../../../utils/functions/CommonFunctions';

interface CountdownTimerProps {
    futureDate: string;
    onFinish: () => void; // Callback function to be called when the timer finishes
}

interface TimeRemaining {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ futureDate, onFinish }) => {

    const currentDate = new Date();
    const timeDifference = new Date(futureDate).getTime() - currentDate.getTime();
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>({
        days,
        hours,
        minutes,
        seconds,
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentDate = new Date();
            const timeDifference = new Date(futureDate).getTime() - currentDate.getTime();

            if (timeDifference <= 0) {
                clearInterval(intervalId);
                setTimeRemaining({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });

                // Call the parent function when the timer finishes
                onFinish();
            } else {
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                setTimeRemaining({
                    days,
                    hours,
                    minutes,
                    seconds,
                });
            }
        }, 1000);



        return () => clearInterval(intervalId);
    }, [futureDate, onFinish]);

    return (
        <div className='mt-20'>
            <p className='text-danger fw-600 text-center'>Event will be start</p>
            <p className='text-danger fw-600 text-center'>
                {
                    timeRemaining?.days > 0 ? <>
                        {getFormattedDateSec(futureDate)}
                    </> :
                        <>
                            {timeRemaining.hours > 0 ? timeRemaining.hours > 9 ? timeRemaining.hours : "0" + timeRemaining.hours : timeRemaining.hours + "0 "} {" "} : {" "}
                            {timeRemaining.minutes > 9 ? timeRemaining.minutes : "0" + timeRemaining.minutes} {" "} : {" "} 
                            {timeRemaining.seconds > 9 ? timeRemaining.seconds : "0" + timeRemaining.seconds}
                        </>
                }
            </p>
        </div>
    );
};

export default CountdownTimer;
