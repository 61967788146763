/* eslint-disable jsx-a11y/heading-has-content */
import { AnimatePresence, motion } from "framer-motion";
import { Col } from "reactstrap";

const NFTSkeleton = () => {
	return (
		<Col sm="12" md="6" lg="4" xl="3" className="mt-30">
			<AnimatePresence>
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="card is-loading">
					<div className="image" style={{ height: 305 }}></div>
					<div className="content">
						<h2></h2>
						<h2 className="w-25 mt-10"></h2>
						<h2 className="w-50 mt-20"></h2>
						<div className="d-flex  mt-10 justify-content-between">
							<h2 className="w-50"></h2>
							<h2 className="w-25"></h2>
						</div>
					</div>
				</motion.div>
			</AnimatePresence>
		</Col>
	);
};

export default NFTSkeleton;
