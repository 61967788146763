import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { RootState } from "../store/Store";
import "../style/loader.scss";

const Loader = () => {
	const { isLoading } = useSelector((state: RootState) => state.loader);
	return isLoading ? (
		<div className="loader-wrapper">
			<Spinner type="grow" color="light" />
		</div>
	) : null;
};

export default Loader;
