import { faQrcode } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import NFTFooter from "../nft/components/NFTFooter"
import NFTHeader from "../nft/components/NFTHeader"
import QRCodeDecoder from "./QRCodeDecoder"


const ScanQRCode = () => {
    const navigate = useNavigate()
    return (
        <>
            <NFTHeader />
            <Container className="mt-100">
                <p className="text-center c-black font-26"> </p>
                <div className="text-center mt-20 p-40 rounded-2" style={{ border: "1px solid gray" }} onClick={() => navigate("/qr-code-scanner")}>
                    <FontAwesomeIcon icon={faQrcode} size="3x" className="c-black" />
                    <p className="c-black mt-20">Scan with QR-Code</p>
                </div>
                <QRCodeDecoder />
            </Container>
            <NFTFooter />
        </>
    )
}

export default ScanQRCode