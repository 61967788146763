import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { GetOrganizationAPI, IGetOrganizationAPIData } from "../../../apis/SmartCityMapAPI/OrganizationAPI";
import { IGetSmartCityMapAPIData } from "../../../apis/SmartCityMapAPI/SmartCityMapAPI";
import { images } from "../../../assets/images/Images";
import { primaryColor } from "../../../utils/constants/Constants";
import { environment } from "../../../utils/constants/environment";
import { handleCopy, redirectNewTap } from "../../../utils/functions/CommonFunctions";
import NFTQrCodeModal from "../../nft/components/NFTQrCodeModal";
import PaymentQrCodeModal from "./PaymentQrCodeModal";

interface IPaymentQrCodeModal {
    modal: {
        isOpen: boolean;
        data: IGetSmartCityMapAPIData | null;
    };
    handleToggle: () => void;
}

const OrgDetailsModal = ({ modal, handleToggle }: IPaymentQrCodeModal) => {
    const { data } = modal

    const [isLoading, setIsLoading] = useState(false)
    const [orgData, setOrgData] = useState<IGetOrganizationAPIData>()
    const [payMentModal, setPayMentModal] = useState({
        isOpen: false,
        id: ""
    });
    const [qrModal, setQrModal] = useState({
        isOpen: false,
        id: ""
    });

    useEffect(() => {
        modal.isOpen && modal.data?.dataId && getApi(modal.data?.dataId)
    }, [modal.isOpen, modal.data?.dataId])

    const handleQrModalToggle = () => {
        handleToggle()
        setQrModal({ ...qrModal, isOpen: !qrModal.isOpen })
    };

    const getApi = (id: string) => {
        setIsLoading(true)
        GetOrganizationAPI(id).then(res => {
            if (res.status === 200) {
                setOrgData(res.data)
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const handlePaymentToggle = () => {
        handleToggle()
        setPayMentModal({ ...payMentModal, isOpen: false })
    };

    return (
        <>
            <Modal centered size="md" isOpen={modal.isOpen} className="overflow-hidden" toggle={handleToggle}>
                <div style={{ zIndex: "10000", borderRadius: "50%", height: "20px", width: "20px", top: "10px", right: "10px" }} className="cursor-pointer position-absolute d-flex justify-content-center align-items-center bg-white">
                    <FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="c-black font-14" />
                </div>
                <ModalBody className="overflow-hidden p-0">
                    <div className="overflow-hidden">
                        <div className="position-relative bg-dark rounded-10" style={{ height: "180px" }}>
                            <img src={data?.marker?.bannerImage} alt={data?.marker?.name} className="w-100 rounded-2 object-fit-cover" height={180} />
                            <div style={{ right: 10, bottom: 10 }} className="d-flex cursor-pointer rounded-10 position-absolute justify-content-end align-items-center">
                                <div className="bg-white rounded-1" style={{ padding: "6px 6px 2px 6px" }}>
                                    <QRCodeCanvas
                                        size={60}
                                        id="qrcode"
                                        fgColor={primaryColor}
                                        value={`${String(environment.domainUrl) + "org/" + data?.dataId}`}
                                        color={primaryColor}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleToggle()
                                            setQrModal({ id: `${String(environment.domainUrl) + "org/" + data?.dataId}`, isOpen: true });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-20">
                            <div className="d-flex justify-content-between align-items-center">
                                <img className="rounded-10" src={data?.marker?.image} alt={data?.marker?.name} height={100} width={100} />
                                <img src={"https://account-files-bucket.s3.ap-south-1.amazonaws.com/bmessage/assets/images/verified.png"} alt={data?.marker?.name} height={80} width={80} />
                            </div>
                            <div className="mt-10">
                                <p className="c-black fw-600 font-20">{data?.marker?.name}</p>
                                <div className="mt-10">
                                    {isLoading ? <div className="d-flex mt-10 justify-content-center align-items-center">
                                        <Spinner size={"sm"} />
                                    </div> :
                                        <div className="d-flex gap-3 align-items-center">
                                            <img src={images?.map?.event} alt="event" height={40} />
                                            <p className="c-black fw-600">Events ({orgData?.usages?.consumedEvents})</p>
                                        </div>
                                    }
                                    {isLoading ? null :
                                        <div className="d-flex gap-3 align-items-center mt-10">
                                            <img src={images?.map?.checkpoint} alt="checkpoint" height={40} />
                                            <p className="c-black fw-600">Discover Checkpoint ({orgData?.usages?.consumedCheckPoints})</p>
                                        </div>
                                    }
                                </div>
                                <div className="mt-10">
                                    <p className="c-black fw-600">About business</p>
                                    <p className="text-secondary">{data?.marker?.description}</p>
                                </div>
                                {/* <button className="custom-primary w-100 mt-10" onClick={() => {
                                    handleToggle()
                                    setPayMentModal({ id: `${String(environment.domainUrl) + "pay/X Company"}`, isOpen: true });
                                }}>Make Payment</button> */}
                                {isLoading ? <div className="d-flex mt-10 justify-content-center align-items-center">
                                    <Spinner size={"sm"} />
                                </div> : orgData?.orgSocials && orgData?.orgSocials?.filter(res => res.link !== null).length > 0 ? <div className="mt-10">
                                    <p className="c-black fw-600">Social links</p>
                                    <div className="d-flex gap-3 flex-wrap mt-10">
                                        {
                                            orgData?.orgSocials?.map(res => (
                                                res.link ?
                                                    <img data-toggle="tooltip" data-placement="bottom" title={res?.name} className="cursor-pointer" src={res.logo} alt={res.name} width={40} onClick={() => {
                                                        res.type === "number" ? handleCopy("Contact details", res?.link) : window.open(res.link)
                                                    }} />
                                                    : null
                                            ))
                                        }
                                    </div>
                                </div> : null}
                            </div>
                            <div className="d-flex gap-1 mt-10 justify-content-center align-items-center">
                                <p className="c-black font-ubuntu">Powered by</p>
                                <img src={images.edexaLogo} alt="edexaLogo" className="cursor-pointer" onClick={() => redirectNewTap(environment.edexaUrl || "")} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
            <NFTQrCodeModal handleToggle={handleQrModalToggle} modal={qrModal} />
            <PaymentQrCodeModal handleToggle={handlePaymentToggle} modal={payMentModal} />
        </>
    )
}

export default OrgDetailsModal