import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { images } from "../assets/images/Images";
import { setComingSoonModal } from "../store/ComingSoonSlice";
import { RootState } from "../store/Store";

const ComingSoonModal = () => {
	const dispatch = useDispatch();
	const modal = useSelector((state: RootState) => state.comingSoon);

	const handleClose = () => {
		dispatch(
			setComingSoonModal({
				isOpen: false,
				label: modal.label
			})
		);
	};

	return (
		<Modal isOpen={modal.isOpen} toggle={handleClose} centered>
			<div className="p-40 text-center position-relative">
				<FontAwesomeIcon icon={faXmark} onClick={handleClose} className="position-absolute cursor-pointer c-green" style={{ right: 20, top: 20 }} />
				<img src={images.logo} width={250} alt="logo" />
				<p className="fw-bold mt-20 text-uppercase">Coming Soon! {modal.label} MODULE</p>
				{modal.label.toLocaleLowerCase() === "notification" ? (
					<p className="mt-10 c-gray">
						We're excited to introduce the Notification module in TowerConnect.
					</p>
				) : null}
				{modal.label.toLocaleLowerCase() === "transfer" ? (
					<p className="mt-10 c-gray">
						We're excited to introduce the Transfer Digital Owner module in TowerConnect.
					</p>
				) : null}
				<button className="custom-primary w-50 mt-20" onClick={handleClose}>
					OK
				</button>
			</div>
		</Modal>
	);
};

export default ComingSoonModal;
