import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const GetReportListAPI = (id: string): Promise<IGetReportListAPI> => {
	return HTTPService.get( API_END_POINTS.VALIDATE_HASH.GET_REPORT_LIST + id);
};

export interface IGetReportListAPIRes {
	createdAt: string;
	question: {
		text: string;
	};
	txId: string;
}

interface IGetReportListAPI extends IApiRes {
	data: IGetReportListAPIRes[];
}
