/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { faExpand, faPlusCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import { useEffect, useId, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ReactSelect from "react-select"
import { Alert, Col, Container, FormGroup, Input, Modal, Row, Spinner } from "reactstrap"
import * as Yup from "yup"
import { createNFTAPI } from "../../apis/nftAPI/CreateNFTAPI"
import { getProfileAPI } from '../../apis/user/GetProfileAPI'
import { images } from "../../assets/images/Images"
import { RootState } from "../../store/Store"
import { NFTCreateStatus } from "../../utils/constants/Constants"
import { RoutePathConst } from "../../utils/constants/RoutesPathConst"
import { getBase64, handleFormikTrim, toastError, toastSuccess } from "../../utils/functions/CommonFunctions"
import NFTFooter from "../nft/components/NFTFooter"
import NFTHeader from "../nft/components/NFTHeader"
import CategoryField from "./components/CategoryField"
import MetaDataModal from "./components/MetaData"
import "./nft-form.scss"

const NFTForm = () => {
  const id = useId()
  const navigate = useNavigate()
  const { user } = useSelector((state: RootState) => state.user);

  const [currencyOption, setCurrencyOptions] = useState<any[]>([
    {
      label: "EURO",
      value: "EURO"
    },
    {
      label: "CHF",
      value: "CHF"
    }
  ]);
  const [currencyData, setCurrencyData] = useState({
    label: "EURO",
    value: "EURO"
  });
  const [metaData, setMetaData] = useState({ isOpen: false })
  const [nftStatus, setNftStatus] = useState(NFTCreateStatus.create)
  const [imgState, setImgState] = useState({
    imgSrc: "",
    isOpen: false,
    isError: false,
    isLoading: true
  })

  const getUserAction = () => {
    getProfileAPI().then(res => {
      if (res.status === 200) {
        setFieldValue("walletAddress", res?.data?.walletAddress)
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      priceToggle: false,
      file: "",
      walletAddress: user?.walletAddress,
      categoryId: "",
      metaData: [{ trait_type: "", value: "" }],
      price: null,
      currency: "EURO",
      copies: "1",
      gateway: "4",
      fileType: "image",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required").min(3, "Minimum 3 characters required").max(80, "Title should not exceed 80 characters"),
      description: Yup.string().required("Description is required").min(3, "Minimum 3 characters required").max(750, "Description should not exceed 750 characters"),
      categoryId: Yup.string().required("Category is required").min(3, "Minimum 3 characters required").max(80, "Maximum 80 characters required"),
      file: Yup.mixed().required("File is required"),
      price: Yup.number()
        .typeError("Price must be a number ex. 10.10")
        .nullable()
        .when("priceToggle", (priceToggle, schema) => {
          if (priceToggle[0]) {
            return schema.min(1, "Must be more than 0").required("Price is required").max(99999, "Must be less than 99999");
          }
          return schema;
        }),
      metaData: Yup.array()
        .of(
          Yup.object().shape({
            trait_type: Yup.string().required(),
            value: Yup.string().required()
          }))
    }),
    onSubmit: (data) => {
      const formData: any = new FormData();
      Object.keys(data).map((key) => {
        if (key === "metaData") {
          formData.append("attributes", JSON.stringify(data[key]));

        } else if (key === "file") {
          formData.append("file", data[key]);

        } else if (key === "categoryId") {
          formData.append("category", data[key]);

        } else if (key === "currency") {
          if (data[key]) {
            formData.append("currency", data[key]);
          }

        } else if (key === "price") {
          if (values.priceToggle) {
            if (data[key]) {
              formData.append("price", data[key]);
            }
          }
        } else if (key === "priceToggle") {
          // formData.append("priceToggle", data[key]);
        } else if (key === "walletAddress") {
          // formData.append("walletAddress", data[key]);

        } else {
          // @ts-ignore
          formData.append(key, data[key]);
        }
      });

      setNftStatus(() => NFTCreateStatus.inprogress)
      createNFTAPI(formData).then(res => {
        if (res.status === 200) {
          toastSuccess(res.message)
          setNftStatus(() => NFTCreateStatus.minted)
        }
      }).catch(err => {
        setNftStatus(() => NFTCreateStatus.create)
      })

    }
  })
  const { errors, handleSubmit, values, setErrors, handleBlur, setFieldValue, touched } = formik

  useEffect(() => {
    if (user?.walletAddress) {
      setFieldValue("walletAddress", user?.walletAddress)
    } else {
      getUserAction();
    }
  }, [user])

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target;
    const file = inputElement?.files?.[0];
    if (file) {
      const fileType = file.type;
      const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"];
      if (validImageTypes.includes(fileType)) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = function () {
          const width = img.width;
          const height = img.height;
          if (width < 336 || height < 280) {
            toastError("Image dimensions must be at least 336x280 pixels!");
            return;
          } else {
            if (file.size > 20000000) {
              toastError("File size should not exceed more than 20MB");
            } else {
              getBase64(file).then((res) => {
                if (res) {
                  setFieldValue("file", file);
                  setImgState({
                    ...imgState,
                    // @ts-ignore
                    imgSrc: res
                  });
                }
              });
            }
          }
        };
      } else {
        toastError("Please select image only");
      }
      inputElement.value = '';
    }
  };

  const handleToggleImgState = () => {
    setImgState({ ...imgState, isOpen: !imgState.isOpen })
  }

  const handleToggleMetaData = () => {
    setMetaData({ isOpen: !metaData?.isOpen })
  }

  const handleDeleteMetaData = (index: number) => {
    const tempArray = [...values?.metaData]
    tempArray.splice(index, 1)
    if (values?.metaData?.length === 1 && index === 0) {
      setFieldValue("metaData", [{ trait_type: "", value: "" }])
      setErrors({ ...errors, metaData: [] })
    } else {
      setFieldValue("metaData", tempArray)
    }
  }

  return (
    <>
      <NFTHeader />
      <Container className="">
        <Row className="mt-100 gap-lg-0 gap-3">
          {
            nftStatus === NFTCreateStatus.inprogress ?
              <Col sm="12">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "63vh" }}>
                  <Spinner />
                  <p className="nft-responsive-text">Digital Owner Mint is in progress...</p>
                </div>
              </Col> : null
          }
          {
            nftStatus === NFTCreateStatus.minted ?
              <Col sm="12">
                <div style={{ height: "63vh" }} className="d-flex flex-column justify-content-center align-items-center">
                  <p className="nft-responsive-text">Your Digital Owner has been minted!</p>
                  <p className="mt-20 c-black">Congratulations, Your Digital Owner has officially been minted on edeXa.</p>
                  <button className="c-bg-green rounded-2 mt-30 text-white p-20" onClick={() => navigate(RoutePathConst.private.ownNft)}>View Digital Owner</button>
                </div>
              </Col> : null
          }
          {
            nftStatus === NFTCreateStatus.create ?
              <>
                <Col lg="6">
                  <div className="border-1 position-relative d-flex cursor-pointer justify-content-center align-items-center h-100 rounded-2 overflow-hidden" style={{ maxHeight: "550px", border: imgState?.imgSrc ? "" : "1px solid #fff", borderColor: touched?.file ? errors?.file ? "red" : "#fff" : "#fff" }} onClick={() => {
                    document.getElementById("file")?.click()
                  }}>
                    {
                      imgState?.imgSrc ?
                        <>
                          <span
                            className={"gofullpageicon"}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleToggleImgState();
                            }}>
                            <FontAwesomeIcon className="font-20" icon={faExpand} />
                          </span>
                          <img src={imgState?.imgSrc} className="w-100 h-100 object-fit-cover" alt="logo" />
                        </>
                        : <div className="d-flex flex-column justify-content-center align-items-center">
                          <img src={images?.uploadImage} alt="upload" className="w-100" />
                          <p className="c-black font-20">Upload a File </p>
                          {/* <p className="c-gray">Drag and drop files here</p> */}
                          <p className="c-gray">Click and upload file here</p>
                          {
                            touched?.file ? errors?.file ?
                              <Alert color="danger" className="mt-10">{errors?.file}</Alert>
                              : null : null
                          }
                        </div>
                    }
                    <Input type="file" onChange={handleFile} className="d-none" accept="image/*" id="file" />
                  </div>
                </Col>
                <Col lg="6">
                  <h2 className="c-black fw-900 mb-0">Title and Description</h2>
                  <p className="c-black mt-20">Important Information - Once your Digital Owner is deployed on the network, you will not be able to edit or update any information.</p>
                  <div className="mt-30">
                    <Input
                      className={`p-20 custom-border ${touched?.title && errors?.title ? "custom-border-red" : ""}`}
                      placeholder="Title"
                      name="title"
                      onChange={(e) => {
                        handleFormikTrim(e.target.name, e.target.value, setFieldValue)
                      }}
                      onBlur={handleBlur}
                      value={values.title}
                      invalid={Boolean(touched?.title && errors?.title)}
                    />
                    {touched?.title && errors?.title && <span className="text-danger">{errors.title}</span>}
                  </div>
                  <div className="mt-20">
                    <Input type="textarea" placeholder="Description"
                      className={`p-20 custom-border ${touched?.description && errors?.description ? "custom-border-red" : ""}`}
                      name="description"
                      onChange={(e) => {
                        handleFormikTrim(e.target.name, e.target.value, setFieldValue)
                      }}
                      onBlur={handleBlur}
                      value={values?.description}
                      invalid={Boolean(touched?.description && errors?.description)}
                    />
                    {touched?.description && errors?.description && <span className="text-danger">{errors?.description}</span>}
                  </div>

                  {/* <div className={`mt-20 d-flex align-items-center`}>
                    <Label htmlFor="price" className="mr-10 mb-0 fw-600 c-black">
                      Is For Sell
                    </Label>
                    <ReactSwitch
                      height={17} onColor="#fff" offColor="#fff" handleDiameter={15} width={30} uncheckedIcon={false} checkedIcon={false} checked={values.priceToggle} onChange={(e) => {
                        setFieldValue("priceToggle", e);
                      }} />
                    <CustomTooltip
                      className="ml-10"
                      direction="right"
                      content={values.priceToggle ? "This NFT is now available for sale. Please provide the currency and price." : "This NFT is no longer available for sale."}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </CustomTooltip>
                  </div> */}
                  {values.priceToggle ? (
                    <div className="d-flex gap-3 mt-20">
                      <div className="w-100">
                        <FormGroup className='max-category-menu'>
                          <ReactSelect
                            className="cursor-select"
                            isSearchable={false}
                            id="react-select-2-listbox"
                            placeholder={"Currency"}
                            value={currencyData}
                            onChange={(e: any) => {
                              setCurrencyData(e);
                              setFieldValue("currency", e.value);
                            }}
                            options={currencyOption}
                          />
                        </FormGroup>
                      </div>
                      <div className="w-100">
                        <Input
                          id="price"
                          name="price"
                          type="text"
                          className="p-20"
                          onBlur={handleBlur}
                          value={values.price || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (/^\d*\.?\d{0,4}$/.test(newValue) || newValue === "") {
                              setFieldValue("price", e.target.value);
                            }
                          }}
                          invalid={Boolean(touched?.price && errors?.price)}
                          placeholder="Price"
                        />
                        {touched?.price && errors?.price && <p className="text-danger">{errors?.price}</p>}
                      </div>
                    </div>
                  ) : null}

                  <CategoryField errors={errors} setFieldValue={setFieldValue} touched={touched} />
                  {
                    values?.metaData.length < 4 ?
                      <>
                        <div className="mt-20 fw-600 d-flex align-items-center gap-2">
                          <p className="c-black d-flex align-items-center cursor-pointer" onClick={handleToggleMetaData}>
                            Add Metadata
                            <FontAwesomeIcon className="ml-10" icon={faPlusCircle} />
                          </p>
                        </div>
                        {
                          touched?.metaData?.[0] ? errors?.metaData?.[0]
                            ?
                            <Alert color="danger" className="mt-10">{errors?.metaData?.[0] ? "Minimum one metadata is required" : null}</Alert>
                            : null : null
                        }
                      </>
                      : <div className="mt-20 fw-600 d-flex align-items-center">
                        <p className="c-black d-flex align-items-center cursor-pointer">
                          Metadata
                        </p>
                      </div>
                  }
                  {
                    values?.metaData?.length === 1 && values?.metaData?.[0].trait_type === "" ? null :
                      <div className="mt-10 d-flex gap-3 flex-wrap">
                        {
                          values?.metaData.map((res, index) => (
                            <div key={`${id}${index}`} className="meta-data-card py-10 px-4 rounded-2 position-relative">
                              <FontAwesomeIcon className="shadow position-absolute cursor-pointer c-green" style={{ top: "-4px", right: "-4px" }} icon={faXmarkCircle} onClick={() => handleDeleteMetaData(index)} />
                              <p className="text-center c-black fw-bold">{res.trait_type}</p>
                              <p className="text-center c-black">{res.value}</p>
                            </div>
                          ))
                        }
                      </div>
                  }
                  <div className="mt-20">
                    <Input
                      disabled
                      placeholder="Wallet Address"
                      value={values?.walletAddress}
                      className="p-20  custom-border" />
                  </div>
                  <div className="d-flex mb-100 mt-30">
                    <button type="submit" onClick={() => handleSubmit()} className="c-bg-green rounded-2 text-white p-20">Mint Digital Owner</button>
                  </div>
                </Col>
              </> : null
          }
        </Row>
      </Container>
      <NFTFooter />
      <MetaDataModal data={values?.metaData} handleToggle={handleToggleMetaData} metaData={metaData} setValue={setFieldValue} />
      <Modal
        isOpen={imgState.isOpen}
        centered
        external={
          <span
            className="c-black fw-600 position-absolute modal-close text-uppercase"
            onClick={() => {
              handleToggleImgState();
            }}>
            Close
          </span>
        }
        className="public-nft-modal-img"
        toggle={() => {
          handleToggleImgState();
        }}>
        <div className="overflow-hidden">
          {imgState.isLoading ? (
            <div className="nft-image-load h-100 position-absolute" style={{ top: 0 }}>
              <Spinner className="text-white" />
            </div>
          ) : null}
          <img
            onError={() => setImgState({ ...imgState, isLoading: false, isError: true })}
            onLoad={() => {
              if (!imgState.isError) {
                setImgState({ ...imgState, isLoading: false });
              }
            }}
            src={imgState?.isError ? images?.nftError : imgState?.imgSrc}
            className="modal-content2"
            alt="nft"
          />
        </div>
      </Modal>
    </>
  )
}

export default NFTForm