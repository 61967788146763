import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QRCodeCanvas } from "qrcode.react";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col } from "reactstrap";
import { IgetNftData } from "../../../apis/nftAPI/GetNFTAPI";
import { RootState } from "../../../store/Store";
import { primaryColor } from "../../../utils/constants/Constants";
import { environment } from "../../../utils/constants/environment";
import { getFormattedDateSec } from "../../../utils/functions/CommonFunctions";
import NFTImage from "./NFTImage";
import NFTQrCodeModal from "./NFTQrCodeModal";

const NFTCard = ({ data }: { data: IgetNftData }) => {
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.user)
	const { isLogin } = useSelector((state: RootState) => state.authSlice)
	const [modal, setModal] = useState({
		isOpen: false,
		id: ""
	});
	const handleToggle = () => {
		setModal({ ...modal, isOpen: !modal.isOpen });
	};

	const handleClick = () => {
		if (isLogin && data?.ownBy === user?.userId) {
			navigate(`/nft${data.nftId ? `/${data.id}` : `/${data.id}`}`, { state: { ownBy: true } })
		} else {
			navigate(`/nft${data.nftId ? `/${data.id}` : `/${data.id}`}`)
		}
	}

	return (
		<Col sm="12" md="6" lg="4" xl="3">
			<div className="w-100 cursor-pointer rounded-10 overflow-hidden bg-white ntf-card mb-30 position-relative" onClick={handleClick}>
				<NFTImage data={data} />
				{
					!data?.isVisible ?
						<span
							className={"gofullpageicon"}
							onClick={() => {
								// handleToggleImgState();
							}}>
							<FontAwesomeIcon className="font-20" icon={faLock} />
						</span> : null
				}
				<div className="p-30 body">
					<h5 className="c-black mb-0 fw-600 text-truncate">{data.title}</h5>
					<div className="row">
						<div className="col-6">
							<p className="c-green fw-600 mt-10 text-truncate">{data.price ? `${Number(data.price).toFixed(2)} ${data?.currency}` : "Not For Sell"} </p>
							<p className="mt-20 text-secondary">Created Date</p>
						</div>
						<div className="col-6 d-flex justify-content-end align-items-center">
							<QRCodeCanvas
								size={65}
								id="qrcode"
								fgColor={primaryColor}
								value={`${String(environment.domainUrl) + "nft/" + data?.id || data?.id}`}
								color={primaryColor}
								onClick={(e) => {
									e.stopPropagation();
									setModal({ id: `${String(environment.domainUrl) + "nft/" + data?.id || data?.id}`, isOpen: true });
								}}
							/>
						</div>
					</div>
					<div className="d-flex mt-10 justify-content-between">
						<p className="c-black mb-0 fw-600 text-truncate">{getFormattedDateSec(data.createdAt)}</p>
						{data.isReserved ? (
							null
							// our discussion i remove this reserve tag, for discussion to team  */}
							// <p style={{ marginRight: "-15px" }} className="text-red text-truncate mb-0 font-12 text-uppercase fw-600 rounded-1">
							// 	Reserved
							// </p>
						) : isLogin ?
							data?.requestedBy?.includes(String(user?.userId)) ?
								<p style={{ marginRight: "-15px" }} className="text-red text-truncate mb-0 font-12 text-uppercase fw-600 rounded-1">
									Requested
								</p> : null : null
						}
					</div>
				</div>
			</div>
			<NFTQrCodeModal handleToggle={handleToggle} modal={modal} />
		</Col>
	);
};

export default memo(NFTCard);
