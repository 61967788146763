import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { QRCodeCanvas } from "qrcode.react";
import { useLocation } from "react-router-dom";
import { Modal, Spinner } from "reactstrap";
import { images } from "../../../assets/images/Images";
import { primaryColor } from "../../../utils/constants/Constants";
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst";
import { environment } from "../../../utils/constants/environment";
import { handleQrCodeDownload, redirectNewTap } from "../../../utils/functions/CommonFunctions";

interface INFTQrCodeModal {
	modal: {
		isOpen: boolean;
		id: string;
	};
	handleToggle: () => void;
	userQrCode?: boolean;
}

const NFTQrCodeModal = ({ modal, handleToggle, userQrCode }: INFTQrCodeModal) => {
	const location = useLocation()
	return (
		<Modal centered size="md" style={{ borderRadius: "50px" }} className="qrcode-nft-modal" isOpen={modal.isOpen} toggle={handleToggle}>
			<FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green position-absolute" style={{ top: "20px", right: "30px" }} />
			<div className="row p-40 mt-30 justify-content-center overflow-hidden">
				<div className="col-8 position-relative d-flex justify-content-center align-items-center">
					<motion.div initial={{ opacity: 1 }} transition={{ delay: 2, duration: 0.5 }} animate={{ opacity: 0 }} className="mb-0 text-center position-absolute d-flex gap-1 align-items-center">
						<p className="fw-600 c-black">
							Generating QR Code with edeXa AI...
						</p>
						<div>
							<Spinner size={"sm"} className="c-black" />
						</div>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, width: 0, height: 0, backgroundSize: "0" }}
						animate={{ backgroundSize: "25px 25px", opacity: 1, width: "auto", height: "auto" }}
						transition={{ delay: 2, duration: 0.5 }}
						className="public-qrcode">
						<QRCodeCanvas size={235} id="qrcode" fgColor={primaryColor} value={modal?.id} color={primaryColor} />
					</motion.div>
				</div>
				{
					location?.pathname === RoutePathConst.private.profile ?
						<motion.div initial={{ opacity: 0 }} transition={{ delay: 2.5, duration: 0.5 }} animate={{ opacity: 1 }} className="d-flex justify-content-center mt-30">
							<div onClick={() => handleQrCodeDownload("qrcode")} className="download-png cursor-pointer d-flex gap-2 align-items-center rounded-5 py-2 px-4">
								<FontAwesomeIcon icon={faDownload} />
								Download Profile QR Code
							</div>
						</motion.div>
						: null
				}
				<div className="d-flex justify-content-center gap-1 align-items-center mt-30">
					<p className="c-black font-16 text-center">Powered by</p>
					<img width={80} src={images.edexaLogo} className="cursor-pointer" onClick={() => redirectNewTap(environment.edexaUrl || "")} alt="logo" />
				</div>
			</div>
		</Modal>
	);
};

export default NFTQrCodeModal;
