/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faCalendarCheck, faChartSimple, faListCheck, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst";
import NFTFooter from "../../nft/components/NFTFooter";
import NFTHeader from "../../nft/components/NFTHeader";

const WelcomePage = () => {
    const fullscreenRef = useRef();
    const navigate = useNavigate()
    return (
        // @ts-ignore
        <div ref={fullscreenRef} className="nft-section">
            <NFTHeader />
            <Container className="welcome-page-container d-flex justify-content-center flex-wrap align-items-center">
                <div className="w-100">
                    <h2 className="c-black fw-900 text-center mb-0">Welcome to TowerConnect</h2>
                    <div className="main-card flex-wrap justify-content-center gap-3">
                        <a className="text-white shadow p-50 rounded-10 cursor-pointer rm-hover"
                            href="com.app.towerconnect://event"
                            style={{ background: "#4d29c0" }}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center mr-10 align-items-center rounded-1" style={{ background: "rgba(255, 255, 255, 0.3)", width: "30px", height: "30px" }}>
                                    <FontAwesomeIcon icon={faCalendarCheck} />
                                </div>
                                <p className="fw-600">Events</p>
                            </div>
                        </a>
                        <a className="text-white shadow p-50 rounded-10 cursor-pointer rm-hover"
                            href="com.app.towerconnect://checkpoint"
                            style={{ background: "#2c50ede6" }}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center mr-10 align-items-center rounded-1" style={{ background: "rgba(255, 255, 255, 0.3)", width: "30px", height: "30px" }}>
                                    <FontAwesomeIcon icon={faListCheck} />
                                </div>
                                <p className="fw-600">Checkpoints</p>
                            </div>
                        </a>
                        <a className="text-white shadow p-50 rounded-10 cursor-pointer rm-hover"
                            href="com.app.towerconnect://smartcity"
                            style={{ background: "#6201edb8" }}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center mr-10 align-items-center rounded-1" style={{ background: "rgba(255, 255, 255, 0.3)", width: "30px", height: "30px" }}>
                                    <FontAwesomeIcon icon={faMapLocationDot} />
                                </div>
                                <p className="fw-600">Smart City</p>
                            </div>
                        </a>
                        <div className="text-white shadow p-50 rounded-10 cursor-pointer rm-hover"
                            onClick={() => navigate(RoutePathConst.public.allNft)}
                            style={{ background: "#008eff" }}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center mr-10 align-items-center rounded-1" style={{ background: "rgba(255, 255, 255, 0.3)", width: "30px", height: "30px" }}>
                                    <FontAwesomeIcon icon={faChartSimple} />
                                </div>
                                <p className="fw-600">Digitalization</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <NFTFooter />
        </div>
    );
};

export default WelcomePage;
