import { memo, useState } from "react";
import { Spinner } from "reactstrap";
import { IgetNftData } from "../../../apis/nftAPI/GetNFTAPI";
import { images } from "../../../assets/images/Images";

const NFTImage = ({ data }: { data: IgetNftData }) => {
	const [imgState, setImgState] = useState({
		isLoad: true,
		isError: false
	});

	const handleLoad = () => {
		if (!imgState.isError) {
			setImgState({ isError: false, isLoad: false });
		}
	};
	const handleError = () => {
		setImgState({ isError: true, isLoad: false });
	};

	return (
		<div className="w-100 position-relative bg-dark overflow-hidden" style={{ height: 305 }}>
			{imgState.isLoad ? (
				<div className="h-100 w-100 position-absolute d-flex justify-content-center align-items-center">
					<Spinner className="text-white" />
				</div>
			) : null}
			<img
				onLoad={handleLoad}
				onError={handleError}
				alt={data?.title}
				src={imgState.isError ? images?.nftError : data?.fileThumbPath || data?.compressedFilePath || data?.filePath}
				style={{ maxHeight: 305 }}
				className="object-fit-cover w-100 h-100"
			/>
		</div>
	);
};

export default memo(NFTImage);
