/* eslint-disable react-hooks/exhaustive-deps */
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Col, Container, Modal, Row, Spinner } from "reactstrap";
import { GetByNFTAPI, IPublicGetByNFTAPIData } from "../../apis/nftAPI/GetByNFTAPI";
import { OwnGetByNFTAPI } from "../../apis/ownNftAPI/OwnGetByNFTAPI";
import { images } from "../../assets/images/Images";
import socket from "../../sockets/socketConfig";
import { RootState } from "../../store/Store";
import "../../style/nft.scss";
import { reverseNFTStatus } from "../../utils/constants/Constants";
import { RoutePathConst } from "../../utils/constants/RoutesPathConst";
import { environment } from "../../utils/constants/environment";
import NFTDetailsToggle from "./components/NFTDetailsToggle";
import NFTFooter from "./components/NFTFooter";
import NFTHeader from "./components/NFTHeader";
import NFTPropertiesToggle from "./components/NFTProperties";
import NFTQrCodeModal from "./components/NFTQrCodeModal";
import NFTReserveToggle from "./components/NFTReserveToggle";
import PublicPrivateNFT from "./components/PublicPrivateNFT";

const NFTDetails = () => {
	const params = useParams();
	const location = useLocation()
	const navigate = useNavigate()

	const { user } = useSelector((state: RootState) => state.user);
	const { isLogin } = useSelector((state: RootState) => state.authSlice);

	const [isLoading, setIsLoading] = useState(true);
	const [nftListData, setNFTListData] = useState<IPublicGetByNFTAPIData>();
	const [imgStateModal, setImgStateModal] = useState({
		isLoad: true,
		isError: false
	});
	const [imgModal, setImgModal] = useState({
		isLoad: true,
		isError: false
	});
	const [status, setStatus] = useState({
		isProperties: false,
		isDetails: true
	});
	const [modal, setModal] = useState({
		isOpen: false,
		id: ""
	});
	const [imgState, setImgState] = useState({
		isOpen: false
	});

	const handleToggle = () => {
		setModal({ isOpen: !modal.isOpen, id: `${String(environment.domainUrl) + "nft/" + nftListData?.id || nftListData?.id}` });
	};

	const handleToggleImgState = () => {
		setImgState({ isOpen: !imgState.isOpen });
	};

	const getByNFTApiData = (id: string) => {
		setIsLoading(true);
		GetByNFTAPI(id)
			.then((res) => {
				if (res.status === 200) {
					setIsLoading(false);
					setNFTListData(res.data);
				}
			})
			.catch(() => {
				setIsLoading(false);
				navigate(RoutePathConst.public.allNft);
			});
	};

	const getByUserNFTApiAction = (id: string) => {
		setIsLoading(true);
		OwnGetByNFTAPI(id)
			.then((res) => {
				if (res.status === 200) {
					setIsLoading(false);
					// @ts-ignore
					setNFTListData(res.data);
				}
			})
			.catch(() => {
				setIsLoading(false);
				navigate(RoutePathConst.public.allNft);
			});
	};

	useEffect(() => {
		const getApiCall = (data: any) => {
			if (params?.id) {
				if (location?.state?.ownBy) {
					getByUserNFTApiAction(params?.id);
				} else {
					getByNFTApiData(params?.id);
				}
			}
		}
		socket.on(`nft_${params?.id || ""}`, getApiCall);
		return () => {
			socket.off(`nft_${params?.id || ""}`, getApiCall)
		}
	}, [params?.id])


	useEffect(() => {

		if (params?.id) {
			if (isLogin && location?.state?.ownBy) {
				getByUserNFTApiAction(params?.id);
			} else {
				getByNFTApiData(params?.id);
			}
		}

	}, [location.state]);


	const handlePrivatePublicStatus = () => {
		if (nftListData?.isReserved && isLogin && nftListData?.owner?.userId === user?.userId) {
			return true
		}
		return false
	}

	const handleNFTRequestAction = () => {
		if (isLogin && nftListData?.price) {
			if (nftListData?.isReserved) {
				if (nftListData?.owner?.userId === user?.userId) {
					return reverseNFTStatus.transferNFT
				}
				return null
			} else {
				if (nftListData?.requestedBy?.includes(String(user?.userId))) {
					return reverseNFTStatus.allReadyRequest
				}
				return reverseNFTStatus.reserveRequest
			}
		}
		return null
	}

	return (
		<div className="nft-section">
			<NFTHeader />
			<Container>
				<Row className="mt-100">
					<Col sm="6">
						<div className="w-100 bg-dark position-relative nft-details-img">
							<span
								className={"gofullpageicon"}
								onClick={() => {
									handleToggleImgState();
								}}>
								<FontAwesomeIcon className="font-20" icon={faExpand} />
							</span>
							{imgStateModal.isLoad ? (
								<div className="w-100 d-flex justify-content-center align-items-center h-100 position-absolute" style={{ top: 0 }}>
									<div>
										<Spinner className="text-white" />
									</div>
								</div>
							) : null}
							{
								!isLoading ?
									<img
										onError={() => setImgStateModal({ isLoad: false, isError: true })}
										onLoad={() => {
											if (!imgStateModal.isError) {
												setImgStateModal({ isLoad: false, isError: false });
											}
										}}
										src={imgStateModal?.isError ? images?.nftError : nftListData?.filePath}
										className="w-100 h-100 object-fit-cover"
										alt="nft"
									/> : null
							}
						</div>
					</Col>
					<Col sm="6">
						{isLoading ? (
							<div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
								<Spinner />
							</div>
						) : (
							<div className="w-100">
								<div className="d-flex public-nft-details-section justify-content-between align-items-center">
									<h4 className="fw-600 mb-0 text-truncate">{"@ TowerConnect"}</h4>
									<div className="d-flex align-items-center">
										{/* our discussion i remove this reserve tag, for discussion to team  */}
										{/* <p className="fw-600 font-12 bg-white text-uppercase px-10 rounded-1 c-green">{nftListData?.isReserved && "Reserved"}</p> */}
										<img className="cursor-pointer" onClick={handleToggle} src={images.qrCode} alt="qrCode" />
									</div>
								</div>
								<h2 className="fw-600 mb-0 c-black mt-10">{nftListData?.title}</h2>
								<p className="c-black mt-10">{nftListData?.description}</p>
								{
									nftListData?.isApproved ? <>
										{
											handlePrivatePublicStatus() ?
												<PublicPrivateNFT nftListData={nftListData} />
												: null
										}
										{
											handleNFTRequestAction() === null ? null :
												<NFTReserveToggle getAction={getByNFTApiData} data={nftListData} status={handleNFTRequestAction()} />
										}
									</> : <Alert color="danger" className=" p-20 rounded-10 mt-20">
										<p className="fw-600 font-20">Important</p>
										<p className="mt-10">	Your Digital Owner will be listed on the Collection once it is <span className="fw-600">approved by TowerConnect</span></p>
									</Alert>
								}
								<NFTPropertiesToggle setStatus={setStatus} status={status} properties={nftListData?.attributes ? nftListData?.attributes : []} />

								{nftListData ? <NFTDetailsToggle setStatus={setStatus} status={status} data={nftListData} /> : null}
							</div>
						)}
					</Col>
				</Row>
			</Container>
			<NFTQrCodeModal handleToggle={handleToggle} modal={modal} />
			<Modal
				isOpen={imgState.isOpen}
				centered
				external={
					<span
						className="text-white fw-600 cursor-pointer position-absolute modal-close text-uppercase"
						onClick={() => {
							handleToggleImgState();
						}}>
						Close
					</span>
				}
				className="public-nft-modal-img"
				toggle={() => {
					handleToggleImgState();
				}}>
				<div className="overflow-hidden">
					{imgModal.isLoad ? (
						<div className="nft-image-load h-100 position-absolute" style={{ top: 0 }}>
							<Spinner className="text-white" />
						</div>
					) : null}
					<img
						onError={() => setImgModal({ isLoad: false, isError: true })}
						onLoad={() => {
							if (!imgModal.isError) {
								setImgModal({ isLoad: false, isError: false });
							}
						}}
						src={imgModal?.isError ? images?.nftError : nftListData?.filePath}
						className="modal-content2"
						alt="nft"
					>
					</img>

				</div>
			</Modal>
			<NFTFooter />
		</div>
	);
};

export default NFTDetails;
