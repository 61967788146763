import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const getNotificationAPI = (body: any): Promise<IGetNotificationAPI> => {
	return HTTPService.get(API_END_POINTS.NOTIFICATION.get, body);
};

export interface IGetNotificationAPIData {
	notificationId: string;
	isRead: boolean;
	title: string;
	message: string;
	dataId: string;
	type: "broadcast" | "system";
	createdAt: string;
}

interface IGetNotificationAPI extends IApiRes {
	data: {
		data: IGetNotificationAPIData[];
		count: number;
	};
}

export const deleteNotificationAPI = (id?: string): Promise<IApiRes> => {
	return HTTPService.delete(API_END_POINTS.NOTIFICATION.delete + id || "", {})
}

export const updateNotificationAPI = (id?: string[]): Promise<IApiRes> => {
	return HTTPService.put(API_END_POINTS.NOTIFICATION.delete, id ?  { id } : {})
}