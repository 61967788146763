import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getLabelsAPI } from "../apis/GetLabelsAPI";
import { setDeviceId } from "../store/DeviceIdSlice";
import { setGlobalSetting } from "../store/GlobalSettingSlice";
import { setLabel } from "../store/LabelSlice";
import { setLoading } from "../store/LoaderSlice";
import { languageType } from "../utils/constants/Constants";
import { getDeviceId } from "../utils/functions/CommonFunctions";

const useDeviceId = () => {
    const dispatch = useDispatch()
    // get current device id
    useEffect(() => {
        getDeviceId()
            // @ts-ignore
            .then((uuid: string) => {
                dispatch(setLoading(true))
                const uuidWithUnderscore = uuid.replaceAll("/", "_")
                getLabelsAPI({ deviceId: uuidWithUnderscore, lang: languageType.english }).then(res => {
                    if (res.status === 200) {
                        dispatch(setLabel(res.data.label))
                        dispatch(setLoading(false))
                        dispatch(setDeviceId(uuidWithUnderscore))
                    }
                }).catch(() => {
                    dispatch(setGlobalSetting({ underMaintenance: true }))
                })
            });
    }, [dispatch]);

    return null
}

export default useDeviceId