/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useId, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { GetNFTAPI, IPublicGetNFTAPIData } from "../../apis/nftAPI/GetNFTAPI";
import useVerifyEmail from "../../hooks/useVerifyEmail";
import "../../style/nft.scss";
import { filterParameter } from "../../utils/functions/CommonFunctions";
import NFTCard from "./components/NFTCard";
import NFTFooter from "./components/NFTFooter";
import NFTHeader from "./components/NFTHeader";
import NFTSkeleton from "./components/NFTSkeleton";
import ScrollTopButton from "./components/ScrollTopButton";
import CategoryListComponent from "./components/category/Category";

const NFTListComponent = () => {
	useVerifyEmail();

	const targetRef = useRef();
	const fullscreenRef = useRef();
	const id = useId()

	const [totalNft, setTotalNft] = useState(0)
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<IPublicGetNFTAPIData>({
		count: 0,
		data: []
	});
	const [pageConfig, setPageConfig] = useState<{
		page: number;
		limit: number;
		filter: string[];
	}>({
		page: 1,
		limit: 8,
		filter: []
	});

	const getNFTAPIData = () => {
		const params: any = filterParameter({
			page: pageConfig.page,
			limit: pageConfig.limit,
			filter: pageConfig.filter.join(',')
		})
		if (!totalNft) {
			setIsLoading(true);
		}
		GetNFTAPI(params)
			.then((res) => {
				if (res.status === 200) {
					if (pageConfig.page === 1) {
						setData(res.data);
						if (!params?.filter) {
							setTotalNft(res.data.count)
						}
					} else {
						// @ts-ignore
						setData((prvState) => {
							return { count: prvState.count, data: [...prvState.data, ...res.data.data] };
						});
					}
					setIsLoading((preState) => false);
				}
			})
			.catch(() => {
				setIsLoading((preState) => false);
			});
	};

	useEffect(() => {
		getNFTAPIData();
	}, [pageConfig.page, pageConfig.filter]);

	const incrementPage = useCallback(() => {
		setPageConfig((preState) => {
			if (Math.ceil(data?.count / pageConfig.limit) > preState.page) {
				return { ...preState, limit: preState.limit, page: preState.page + 1 };
			}
			return preState;
		});
	}, [pageConfig, data]);

	useEffect(() => {
		const options = {
			// @ts-ignore
			root: document.getElementsByClassName("nft-section")[0],
			rootMargin: "0px",
			threshold: 0.1 // A value between 0 and 1, indicating the percentage of the target element that needs to be visible to trigger the callback
		};

		const callback = (entries: any) => {
			entries.forEach((entry: any) => {
				if (entry.isIntersecting) {
					incrementPage();
				}
			});
		};

		// Create the Intersection Observer instance
		const observer = new IntersectionObserver(callback, options);

		// Start observing the target element
		if (targetRef.current) {
			observer.observe(targetRef.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [data]);



	return (
		// @ts-ignore
		<div ref={fullscreenRef} className="nft-section">
			<NFTHeader />
			<Container>
				<Row className="mt-100">
					<Col sm="12">
						<h2 className="c-black fw-900 text-center mb-0">All Digital Owner's</h2>
					</Col>
				</Row>
				<CategoryListComponent totalCount={totalNft} setPageConfig={setPageConfig} pageConfig={pageConfig} />
				{isLoading ? (
					pageConfig.page === 1 ? (
						<Row className="mb-30">
							<NFTSkeleton />
							<NFTSkeleton />
							<NFTSkeleton />
							<NFTSkeleton />
						</Row>
					) : null
				) : null}

				<Row className="mt-30">
					{data?.data?.length === 0 ? (
						isLoading ? null : (
							<Col sm={12} className="mt-100" style={{ height: "350px" }}>
								<div className="text-center h-100">
									<p>No Digital Owners found !</p>
								</div>
							</Col>
						)
					) : (
						data?.data?.map((item, index) => (
							<React.Fragment key={`${id}${item?.id}`}>
								<NFTCard data={item} />
								{index + 1 === data.data.length ? (
									data.count === data.data.length ? null : (
										<Row className="mb-30 mt-30">
											<Col sm="12" className="d-flex justify-content-center">
												{/* @ts-ignore */}
												<div ref={targetRef} className="nft-more-page-loader">
													<Spinner className="text-white" />
												</div>
											</Col>
										</Row>
									)
								) : null}
							</React.Fragment>
						))
					)}
				</Row>
			</Container>
			<NFTFooter />
			<ScrollTopButton />
		</div>
	);
};

export default NFTListComponent;
