import { useEffect, useRef } from "react";

const MapVideo = ({ video, setVideo }: {
    video: {
        load: boolean;
        timer: boolean;
    }, setVideo: React.Dispatch<React.SetStateAction<{
        load: boolean;
        timer: boolean;
    }>>
}) => {
    const videoRef = useRef<any>("https://account-files-bucket.s3.ap-south-1.amazonaws.com/towerconnect/assets/videos/smartcity.mp4");
    useEffect(() => {
        const videoElement: any = document.querySelector('.video-element');
        const handleOrientationChange = () => {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            if (isMobile) {
                return true;
            }
            else {
                return false;
            }
        };
        videoRef.current.src = handleOrientationChange() ? "https://account-files-bucket.s3.ap-south-1.amazonaws.com/towerconnect/assets/videos/mobileViewSmartCity.mp4" : "https://account-files-bucket.s3.ap-south-1.amazonaws.com/towerconnect/assets/videos/smartcity.mp4"

        const handlePlay = () => {

            videoElement.muted = true; // Mute the video

            const playPromise = videoElement.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        // Auto-play started
                    })
                    .catch((error: any) => {
                        setVideo((preState) => {
                            return {
                                ...preState, timer: true
                            }
                        })
                        // Auto-play was prevented
                    });
            }
        };

        handlePlay();
        setTimeout(() => {
            setVideo((preState) => {
                return {
                    ...preState, timer: true
                }
            })
        }, 5000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                (video.load && video.timer) ? null :
                    <div className="map-video video-player">
                        <video className="video-element" autoPlay loop preload="Auto" playsInline ref={videoRef}>
                            <source id="source" src={"https://account-files-bucket.s3.ap-south-1.amazonaws.com/towerconnect/assets/videos/smartcity.mp4"} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
            }
        </>
    )
}

export default MapVideo