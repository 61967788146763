export const environment = {
	domainUrl: process.env.REACT_APP_DOMAIN_URL,
	mobileBaseUrl: process.env.REACT_APP_MOBILE_BASE_URL || "",
	s3Url: process.env.REACT_APP_S3_URL,
	androidUrl: process.env.REACT_APP_ANDROID_URL,
	iPhoneUrl: process.env.REACT_APP_IPHONE_URL,
	bankUrl: process.env.REACT_APP_BANK_URL,
	edexaUrl: process.env.REACT_APP_EDEXA_URL,
	mapBoxToken: process.env.REACT_APP_MAP_BOX,
	googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
	ivKey: process.env.REACT_APP_IV_KEY || "",
	algorithm: process.env.REACT_APP_ALGORITHM || "",
	encKey: process.env.REACT_APP_ENC_KEY || ""
};
