import HTTPService from "../../axios/AxiosService"
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints"
import { IApiRes } from "../ActionType"

export const getEventListAPI = (deviceId: string, pagination: any): Promise<IGetEventListAPI> => {
    return HTTPService.get(API_END_POINTS.event.getList + deviceId, pagination)
}

export interface IGetEventListAPIData {
    address: string;
    createdAt: string;
    eventId: string;
    logo: string;
    name: string;
    startTime: string;
    type: string;
    userEventUserId: any[];
}

interface IGetEventListAPI extends IApiRes {
    data: {
        page: number;
        count: number;
        data: IGetEventListAPIData[]
    }
}