import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const getProfileAPI = (): Promise<IGetProfileAPI> => {
    return HTTPService.get(API_END_POINTS.USER.updateProfile);
};

export interface IGetProfileAPIData {
    createdAt: string;
    email: string;
    name: string;
    userId: string;
    walletAddress: string;
    profileImage: string;
    ownNftCount: number;
}

interface IGetProfileAPI extends IApiRes {
    data: IGetProfileAPIData;
}
