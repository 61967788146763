import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const OwnGetByNFTAPI = (id: string): Promise<IOwnGetByNFTAPI> => {
	return HTTPService.get(API_END_POINTS.OWN.GET_BY_NFT + `?id=${id}`);
};

export interface IOwnGetByNFTAPIData {
	price: number;
	status: number;
	id: string;
	title: string;
	description: string;
	txUrl: string;
	tokenId: number;
	ipfsFileHash: string;
	ipfsJsonHash: string;
	filePath: string;
	compressedFilePath: string;
	fileThumbPath: string;
	gateway: number;
	isVisible: boolean;
	categoryId: string;
	tags: string[];
	attributes: {
		trait_type: string;
		value: string
	}[];
	isApproved: boolean;
	isReserved: boolean;
	ownBy: string;
	fileType: string;
	videoDuration: null;
	userId: string;
	nftId: string;
	currency: string;
	createdAt: string;
	updatedAt: string;
	owner: {
		email: string;
		name: string;
		userId: string;
		id: number;
		walletAddress: string;
	}
}

interface IOwnGetByNFTAPI extends IApiRes {
	data: IOwnGetByNFTAPIData;
}
