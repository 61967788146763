import { faCalendarDays } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Container, Spinner } from "reactstrap"
import { IGetEventListAPIData, getEventListAPI } from "../../apis/eventAPI/GetEventListAPI"
import LoadingComponent from "../../components/LoadingComponent"
import { RootState } from "../../store/Store"
import { RoutePathConst } from "../../utils/constants/RoutesPathConst"
import EventRedirectModal from "../EventRedirect/EventRedirectModal"
import NFTFooter from "../nft/components/NFTFooter"
import NFTHeader from "../nft/components/NFTHeader"
import EventCard from "./components/EventCard"

const Event = () => {
    const targetRef = useRef();
    const location = useLocation()
    const navigate = useNavigate()
    const { deviceId } = useSelector((state: RootState) => state.deviceId)
    const [rediretModal, setRedirectModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<{
        page: number;
        count: number;
        data: IGetEventListAPIData[]
    }>()
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 6
    })

    useEffect(() => {
        if (location?.search.includes("?eventid=") || location?.search.includes("?checkpointid=")) {
            setRedirectModal(true)
        } else {
            setRedirectModal(false)
        }
    }, [location?.search])

    const getEventListAction = () => {
        setIsLoading(true)
        getEventListAPI(deviceId, pagination).then(res => {
            if (res.status === 200) {
                if (pagination.page === 1) {
                    setData(() => res.data)
                } else {
                    // @ts-ignore
                    setData((item) => {
                        // @ts-ignore
                        return { ...item, data: [...item.data, ...res.data.data] }
                    })
                }
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        deviceId && getEventListAction()
    }, [deviceId, pagination])

    const incrementPage = () => {
        setPagination((pre) => { return { ...pre, page: pre.page + 1 } })
    }

    useEffect(() => {
        const options = {
            // @ts-ignore
            root: document.getElementsByClassName("notification-section")[0],
            rootMargin: "0px",
            threshold: 0.1 // A value between 0 and 1, indicating the percentage of the target element that needs to be visible to trigger the callback
        };

        const callback = (entries: any) => {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    incrementPage();
                }
            });
        };

        // Create the Intersection Observer instance
        const observer = new IntersectionObserver(callback, options);

        // Start observing the target element
        if (targetRef.current) {

            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [data]);

    return (
        <>
            {
                rediretModal ?
                    <EventRedirectModal />
                    : null
            }
            <NFTHeader />
            <Container className="mt-100 d-flex flex-column justify-content-between">
                {
                    isLoading ? <LoadingComponent />
                        : <>
                            {
                                data && data?.data?.length > 0 ?
                                    <div>
                                        {
                                            data?.data.map((res, index) => (
                                                <React.Fragment key={index}>
                                                    <EventCard data={res} />
                                                    {index + 1 === data?.data?.length ? (
                                                        data?.count === data?.data?.length ? null : (
                                                            <div className="mb-30 mt-30">
                                                                <div className="d-flex justify-content-center">
                                                                    {/* @ts-ignore  */}
                                                                    <div ref={targetRef} className="nft-more-page-loader">
                                                                        <Spinner className="text-white" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : null}
                                                </React.Fragment>
                                            ))
                                        }
                                        <div className="text-center">
                                            <p className="c-black mt-20 text-center cursor-pointer">Dont see you event listed? Maybe it is private, <span className="fw-600" onClick={() => navigate(RoutePathConst.private.scan)}>Scan QR Code</span> to get access</p>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faCalendarDays} size="6x" />
                                            {/* <img src={images.qrCode} height={100} alt="qr-code" /> */}
                                            <p className="fw-600 font-26 mt-30 c-black text-center">No Events</p>
                                            <p className="c-black mt-20 text-center">There are no events that you have joined in the past</p>
                                        </div>

                                        <div className="text-center">
                                            <p className="c-black mt-20 text-center cursor-pointer">Dont see you event listed? Maybe it is private, <span className="fw-600" onClick={() => navigate(RoutePathConst.private.scan)}>Scan QR Code</span> to get access</p>
                                        </div>
                                    </>
                            }

                        </>
                }
            </Container>
            <NFTFooter />
        </>
    )
}

export default Event