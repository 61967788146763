/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getUnderMaintenanceAPI } from "../apis/underMaintenanceAPI/GetUnderMaintenanceAPI"
import socket from "../sockets/socketConfig"
import { setGlobalSetting } from "../store/GlobalSettingSlice"

const useGlobalSetting = () => {
    const dispatch = useDispatch()

    const globalSettingAction = () => {
        getUnderMaintenanceAPI().then(res => {
            if (res.status === 200) {
                dispatch(setGlobalSetting(res.data))
            }
        })
    }

    useEffect(() => {
        globalSettingAction()

        // Listen for incoming messages from the server
        socket.on("global settings", (data: { underMaintenance: boolean, comingSoon: boolean }) => {
            dispatch(setGlobalSetting(data))
        });

        // Clean up the socket connection when the component unmounts
        return () => {
            socket.off("global settings", () => { });
            socket.disconnect()
        };
    }, [])

    return null
}

export default useGlobalSetting