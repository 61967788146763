import { useState } from "react"
import { useDispatch } from "react-redux"
import ReactSwitch from "react-switch"
import { IPublicGetByNFTAPIData } from "../../../apis/nftAPI/GetByNFTAPI"
import { updateNFTAPI } from "../../../apis/nftAPI/updateNFTAPI"
import ConfirmationModal from "../../../components/ConfirmationModal"
import { setLoading } from "../../../store/LoaderSlice"
import { toastSuccess } from "../../../utils/functions/CommonFunctions"

const PublicPrivateNFT = ({ nftListData }: { nftListData: IPublicGetByNFTAPIData | undefined }) => {
    const dispatch = useDispatch()

    const [check, setCheck] = useState(nftListData?.isVisible || false)
    const [isOpen, setIsopen] = useState({
        open: false,
        label: "",
        key: "",
        checked: ""
    });

    const handleToggle = () => {
        setIsopen({
            open: false,
            label: "",
            key: "",
            checked: ""
        });
    };

    const handleAction = () => {
        actionAPI()
    }

    const actionAPI = () => {
        dispatch(setLoading(true))
        updateNFTAPI({ id: String(nftListData?.id), isVisible: !check }).then(res => {
            if (res.status === 200) {
                setCheck(!check)
                handleToggle();
                toastSuccess(res.message)
                dispatch(setLoading(false))
            }
        })
    }

    return (
        <div className="d-flex mt-20 mb-20 gap-2 justify-content-between align-items-center">
            <div>
                <p className="fw-600 c-black">Make Digital Owner {check ? "Private" : "Public"}</p>
                <p className="c-black">Toggling on this Digital Owner will make it public and visible to anyone. It will not be visible if toggled off</p>
            </div>
            <ReactSwitch height={17} onColor="#fff" offColor="#fff" handleDiameter={15} width={30} uncheckedIcon={false} checkedIcon={false} checked={check} onChange={(e) => {
                setIsopen({
                    open: true,
                    label: `Are you sure want to make Digital Owner ${check ? "Private" : "Public"}? ${check ? "It won't be publicly visible if it is private." : "It will be publicly visible if you set it public."}`,
                    key: "",
                    checked: "PRIVATE"
                });
            }} />
            <ConfirmationModal isOpen={isOpen} toggle={handleToggle} handleAction={handleAction} />
        </div>
    )
}

export default PublicPrivateNFT