import { faAngleLeft, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import * as Yup from "yup";
import { resendInvitationAPI } from "../../apis/user/UserResendInvitationAPI";
import { setLoading } from "../../store/LoaderSlice";
import { RoutePathConst } from "../../utils/constants/RoutesPathConst";
import { handleFormikTrim, toastSuccess } from "../../utils/functions/CommonFunctions";
import CompanyInfo from "./components/CompanyInfo";
import "./login.scss";

const ResendEmail = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Email is invalid").required("Email is required"),
    }),
    onSubmit: (value) => {
      dispatch(setLoading(true))
      resendInvitationAPI(value).then(res => {
        if (res.status === 200) {
          toastSuccess(res.message)
          navigate(RoutePathConst.public.login)
          dispatch(setLoading(false))
        }
      })
    }
  })

  const { errors, handleSubmit, values, handleBlur, setFieldValue, touched } = formik
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key.toLowerCase() === "enter" && handleSubmit();
  }

  return (
    <div className="responsive-login w-100 d-flex flex-column flex-xl-row justify-content-xl-around justify-content-center gap-2 flex-wrap align-items-center">
      <CompanyInfo />
      <div className="p-40 bg-white rounded-10 shadow login">
        <p className="font-26">Resend Email</p>
        <p className="c-gray mt-1">Enter your email and we'll send you a link to verify your email.</p>

        <div className="mt-30">
          <InputGroup>
            <Input
              invalid={Boolean(touched?.email && errors?.email)}
              onChange={(e) => handleFormikTrim(e.target.name, e.target.value, setFieldValue)} value={values.email} name="email" id="email" onBlur={handleBlur} placeholder="Email"
              onKeyDown={onKeyPress}
            />
            <InputGroupText
              className={touched?.email && errors?.email ? "input-group-wrapper pass-input" : "input-group-wrapper"}
            >
              <InputGroupText style={{ border: 0 }}><FontAwesomeIcon icon={faEnvelope} /></InputGroupText>
            </InputGroupText>
          </InputGroup>
          {touched?.email && errors?.email && <span className="text-danger">{errors.email}</span>}
        </div>

        <button className="custom-primary mt-20 w-100 fw-600" onClick={() => handleSubmit()}>
          Submit
        </button>
        <div className="d-flex mt-30 justify-content-center align-items-center">
          <p className="c-gray cursor-pointer" onClick={() => navigate(RoutePathConst.public.login)}>
            <FontAwesomeIcon icon={faAngleLeft} />{" "}
            Back to Login</p>
        </div>
      </div>
    </div>
  )
}

export default ResendEmail
