import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const loginAPI = (body: any): Promise<ILoginAPI> => {
	return HTTPService.post(API_END_POINTS.USER.login, { ...body });
};

export interface ILoginAPIData {
	token: string;
	user: {
		email: string;
		name: string;
		userId: string;
		createdAt: string;
		loginType: string;
		walletAddress: string;
		isNewUser: boolean
	},
	refreshToken: string;
}

interface ILoginAPI extends IApiRes {
	data: ILoginAPIData;
}