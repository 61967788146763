import appleStore from "./app-store.png";
import edexaLogo from "./edexaLogo.svg";
import uploadImage from "./folder-amico.svg";
import folder from "./folder.png";
import googleStore from "./google-app.png";
import logo from "./logo.png";
import logoBRI from "./logoBRI.svg";
import checkpoint from "./map/ic_checkpoint_marker.png";
import event from "./map/ic_event_marker.png";
import marker from "./map/ic_marker.png";
import org from "./map/ic_marker_org.png";
import nft_marker from "./map/ic_nft_marker.png";
import nft from "./nft.jpg";
import nftError from "./nftError.png";
import pdf from "./pdf.svg";
import photo from "./photo.png";
import polygon from "./polygon.png";
import qrCode from "./qrcode.svg";
import underMaintenance from "./under-maintanance.png";
import video from "./video.png";

export const images = {
	logo,
	folder,
	logoBRI,
	video,
	photo,
	nft,
	pdf,
	edexaLogo,
	polygon,
	qrCode,
	nftError,
	underMaintenance,
	mobileStore:{
		appleStore ,
		googleStore
	},
	map: {
		checkpoint,
		event,
		marker,
		nft_marker,
		org
	},
	uploadImage,
	defaultUserImg: "https://edexa-portal-beta.s3.ap-south-1.amazonaws.com/users/defaultImg.png"
};
