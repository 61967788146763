import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";

export const getCheckpointAPI = (params: IGetCheckpointAPIParams): Promise<IGetCheckpointAPI> => {
    return HTTPService.get(API_END_POINTS.checkpoint.get, params)
}

interface IGetCheckpointAPIParams {
    deviceId: string;
    checkPointId: string;
}

export interface IGetCheckpointAPIData {
    logo: string,
    id: number,
    checkPointId: string,
    name: string,
    description: string,
    blckChainId: string,
    txId: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string,
    createdBy: {
        companyName: string,
        profilePic: string,
        userId: string
    }
    questions: [
        {
            logo: string,
            id: number,
            eventId: null | string,
            qrCodeId: null | string,
            quesId: string,
            text: string,
            checkPointId: string,
            isActive: boolean,
            createdAt: string,
            updatedAt: string,
            deletedAt: null
        }
    ]
}

interface IGetCheckpointAPI {
    status: number,
    message: string,
    data: IGetCheckpointAPIData
}