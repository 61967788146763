import { createSlice } from "@reduxjs/toolkit";
import { sessionStorageKeys } from "../utils/constants/Constants";
import { getDecryptedSessionStorage, setEncryptedSessionStorage } from "../utils/functions/CommonFunctions";

const initialState = {
	// @ts-ignore
	underMaintenance: getDecryptedSessionStorage(sessionStorageKeys.globalSettings)?.underMaintenance || false
};

const globalSettingSlice = createSlice({
	name: "globalSetting",
	initialState,
	reducers: {
		setGlobalSetting: (state, action) => {
			state.underMaintenance = action.payload.underMaintenance;
			setEncryptedSessionStorage(sessionStorageKeys.globalSettings, action.payload)
		}
	}
});

export const { setGlobalSetting } = globalSettingSlice.actions;

export default globalSettingSlice.reducer;
