import { createSlice } from "@reduxjs/toolkit";
import { cookieKeys } from "../utils/constants/Constants";
import { getDecryyptedCookie, removedCookie } from "../utils/functions/CommonFunctions";

const handleCookie = () => {
	const data = getDecryyptedCookie(cookieKeys.cookieUser)
	if (data?.token) {
		return true
	}
	return false
}

const initialState = {
	isLogin: handleCookie()
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setLogin: (state, action) => {
			state.isLogin = action.payload;
			if (!action.payload) {
				removedCookie(cookieKeys.cookieUser)
			}
		}
	}
});

export const { setLogin } = authSlice.actions;

export default authSlice.reducer;
