import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const UserForgotPasswordAPI = (body: IUserForgotPasswordAPI): Promise<IApiRes> => {
	return HTTPService.post(API_END_POINTS.USER_APP.FORGET_PASSWORD, body);
};

interface IUserForgotPasswordAPI {
	newPassword: string;
	confirmPassword: string;
	resetToken: string;
}
