import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";

export const createReviewAPI = (params: any): Promise<ICreateReviewAPI> => {
    return HTTPService.post(API_END_POINTS.review.create, params)
}

interface IGetCheckpointAPIParams {
    deviceId: string;
    checkPointId: string;
}

interface ICreateReviewAPIData {
    status: number,
    message: string,
    data: {
        checkPointId: string,
        blckChainId: string,
        questions: [
            {
                text: string,
                quesId: string,
                checkPointId: string,
                qrCodeId: null | string,
                eventId: null | string,
                reviews: [
                    {
                        images: [] | string[],
                        status: number,
                        createdAt: string,
                        updatedAt: string,
                        deletedAt: null | string,
                        id: number,
                        reviewId: string,
                        comment: string,
                        deviceId: string,
                        qrCodeId: null | string,
                        checkPointId: string,
                        quesId: string,
                        eventId: null | string,
                        txId: string,
                        location: {
                            latitude: number,
                            longitude: number
                        }
                    }
                ]
            }
        ]
    }
}


interface ICreateReviewAPI {
    status: number,
    message: string,
    data: ICreateReviewAPIData
}
