/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleOAuthProvider } from "@react-oauth/google";
import useDeviceId from "./hooks/useDeviceId";
import useGlobalSetting from "./hooks/useGlobalSetting";
import Routers from "./routers/Router.config";
import { environment } from "./utils/constants/environment";

function App() {
  useDeviceId()
  useGlobalSetting()

  return (
    <GoogleOAuthProvider clientId={environment.googleClientId} >
      <Routers />
    </GoogleOAuthProvider>);
}

export default App;
