import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const GetCategoryAPI = (): Promise<IGetCategoryAPI> => {
	return HTTPService.get(API_END_POINTS.CATEGORY.GET);
};

export interface IGetCategoryAPIRes {
	categoryId: string;
	id: string;
	name: string;
	nftCount: number;
}

interface IGetCategoryAPI extends IApiRes {
	data: {
		count: number;
		data: IGetCategoryAPIRes[];
	}
}