import { Spinner } from "reactstrap"

const LoadingComponent = () => {
    return (
        <div className="vh-60 d-flex justify-content-center align-items-center">
            <div>
                <p className="c-black fw-bold">Loading...
                    <Spinner className="ml-10" size={"sm"} />
                </p>
            </div>
        </div>
    )
}

export default LoadingComponent