import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserQRCodeReader } from '@zxing/browser';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { moduleType, qrCodeURL } from '../../utils/constants/Constants';
import { toastError } from '../../utils/functions/CommonFunctions';

const QRCodeDecoder = () => {
    const navigate = useNavigate()

    const handleDrop = async (acceptedFiles: any) => {
        const file = acceptedFiles[0];
        const codeReader = new BrowserQRCodeReader();

        try {
            const result: any = await codeReader.decodeFromImageUrl(URL.createObjectURL(file))
            if (String(result.text).includes(qrCodeURL.checkpoint)) {
                navigate(`/checkpoint/${result.text.split(qrCodeURL.checkpoint)[1]}`, { state: { qrCodeType: moduleType.checkpoint } })
            } else if (String(result.text).includes(qrCodeURL.publicEvent)) {
                navigate(`/checkpoint/${result.text.split("qrcodeid=")[1]}`, { state: { qrCodeType: moduleType.event } })
            } else if (String(result.text).includes(qrCodeURL.privateEvent)) {
                navigate("/event/" + result.text.split("?eventid=")[1])
            } else if (String(result.text).includes(qrCodeURL.nft)) {
                navigate(`/nft/${String(result.text).split(qrCodeURL.nft)[1]}`)
            } else {
                toastError("QR Code is invalid!")
            }
        } catch (error) {
            toastError("No QR Code Found");
        }
    };

    const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
    });

    return (
        <div {...getRootProps()} className="text-center c-black d-flex flex-column mt-20 p-40 rounded-2" style={{ border: "1px solid gray" }}>
            <FontAwesomeIcon icon={faImage} size='3x' className='c-black mb-20' />
            <input {...getInputProps()} />
            {!isDragActive && 'Click here to select QR-Code'}
            {isDragActive && !isDragReject && "Drop it like it's hot!"}
            {isDragReject && "File type not accepted, sorry!"}
        </div>
    );
};

export default QRCodeDecoder;
