/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "mapbox-gl/dist/mapbox-gl.css";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useMemo, useRef, useState } from "react";
import Map, { FullscreenControl, MapRef, Marker, NavigationControl, Popup } from "react-map-gl";
import { Spinner } from "reactstrap";
import { GetSmartCityMapAPI, IGetSmartCityMapAPIData } from "../../apis/SmartCityMapAPI/SmartCityMapAPI";
import { images } from "../../assets/images/Images";
import "../../style/smart-city-map.scss";
import { primaryColor, towerConnectType } from "../../utils/constants/Constants";
import { environment } from "../../utils/constants/environment";
import { redirectNewTap } from "../../utils/functions/CommonFunctions";
import NFTQrCodeModal from "../nft/components/NFTQrCodeModal";
import MapStyle from "./components/MapStyle";
import MapVideo from "./components/MapVideo";
import OrgDetailsModal from "./components/OrgDetailsModal";
import PaymentQrCodeModal from "./components/PaymentQrCodeModal";
import SearchMarker from "./components/SearchMarker";

const SmartCityMap = () => {
	const mapRef = useRef<MapRef>();

	const [data, setData] = useState<IGetSmartCityMapAPIData[]>([]);
	const [modify, setModify] = useState<IGetSmartCityMapAPIData[]>([]);
	const [mapStyle, setMapStyle] = useState("clkjag37x00cc01qyfrlccmc9")
	const [selectedMarker, setSelectedMarker] = useState<IGetSmartCityMapAPIData | null>(null);
	const [viewState, setViewState] = useState({
		latitude: 47.085188,
		longitude: 9.542851,
		zoom: 12,
		pitch: 60,
		bearing: -30
	})
	const [animation, setAnimation] = useState({
		lat: 47.085188,
		long: 9.542851,
	})
	const [video, setVideo] = useState({
		load: false,
		timer: false
	})
	const [modal, setModal] = useState({
		isOpen: false,
		id: ""
	});
	const [payMentModal, setPayMentModal] = useState({
		isOpen: false,
		id: ""
	});
	const [orgModal, setOrgModal] = useState<{ isOpen: boolean, data: IGetSmartCityMapAPIData | null }>({
		isOpen: false,
		data: null
	});
	const [img, setImg] = useState({
		isLoading: true,
		isError: false
	})

	const handleAnimation = (longitude: number, latitude: number) => mapRef.current?.flyTo({ center: [longitude, latitude], duration: 5000, pitch: 60, bearing: 0 });

	const generateOffset = () => {
		// Generate a random number between -0.0001 and 0.0001 for latitude and longitude
		const offset = (Math.random() - 0.5) * 0.00004;
		// const offset = 0.00004;
		return offset;
	};

	useEffect(() => {
		GetSmartCityMapAPI().then((res) => {
			if (res.status === 200) {
				setData(res.data.data);

				const sameValues: any = [];
				const modifiedData = res.data.data.map(item => {
					if (sameValues.length === 0 || !sameValues.includes(`${item?.latitude}_${item?.longitude}`)) {
						sameValues.push(`${item?.latitude}_${item?.longitude}`);
						return item;
					} else {
						const latOffset = generateOffset();
						const longOffset = generateOffset();

						item.latitude = String(Number(item?.latitude) + latOffset)
						item.longitude = String(Number(item?.longitude) + longOffset)
						return item;
					}
				});
				setModify(modifiedData)
			}
		});
	}, []);

	useEffect(() => {
		const listener = (e: any) => {
			if (e.key === "Escape") {
				setSelectedMarker(null);
				mapRef.current?.flyTo({ duration: 100 });
			}
		};
		window.addEventListener("keydown", listener);
		return () => {
			window.removeEventListener("keydown", listener);
		};
	}, [selectedMarker]);

	useEffect(() => {
		if (video.load && video.timer) {
			handleAnimation(animation.long, animation.lat)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [video])

	const handleToggle = () => setModal({ ...modal, isOpen: !modal.isOpen });
	const handlePaymentToggle = () => setPayMentModal({ ...payMentModal, isOpen: false });
	const handleOrgModalToggle = () => setOrgModal({ ...orgModal, isOpen: !orgModal.isOpen });
	const handleImgLoad = () => setImg({ ...img, isLoading: false })
	const handleImgError = () => setImg({ isError: true, isLoading: false })

	const closePopup = () => {
		setImg({
			isError: false,
			isLoading: true
		})
		setSelectedMarker(null);
		mapRef.current?.flyTo({ duration: 100, });
	};

	const onSelectCity = (longitude: any, latitude: any) => {
		mapRef.current?.flyTo({
			center: [longitude, latitude],
			duration: 5000,
			bearing: 180 + Math.random() * 180,
		});
	};

	const handleLoad = () => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const latitude = Number(params.get('lat'));
		const longitude = Number(params.get('long'));
		if (search) {
			setAnimation({
				lat: latitude,
				long: longitude
			})
			setVideo((preState) => {
				return {
					...preState, load: true
				}
			})
		} else {
			if (data?.length !== 0) {
				// const latitude = Number(data?.[0]?.latitude);
				// const longitude = Number(data?.[0]?.longitude);
				setAnimation({
					lat: 47.085188,
					long: 9.542851
				})
				setVideo((preState) => {
					return {
						...preState, load: true
					}
				})
			} else {
				setAnimation({
					lat: 47.085188,
					long: 9.542851
				})
				setVideo((preState) => {
					return {
						...preState, load: true
					}
				})
			}
		}
	};
	const handleMove = useMemo(() => (e: any) => setViewState(e.viewState), [setViewState]);
	// ...

	// In your component

	return (
		<>
			<MapStyle setMapStyle={setMapStyle} />
			<MapVideo video={video} setVideo={setVideo} />
			{
				data.length > 0 ?
					<SearchMarker selectedMarker={selectedMarker} data={data} onSelectCity={onSelectCity} setSelectedMarker={setSelectedMarker} /> : null
			}
			<Map
				//@ts-ignore
				ref={mapRef}
				{...viewState}
				onMove={handleMove}
				onLoad={handleLoad}
				minZoom={11}
				mapboxAccessToken={environment.mapBoxToken}
				// @ts-ignore
				mapLib={import("!mapbox-gl")}
				style={{ width: "100vw", height: "100vh" }}
				mapStyle={`mapbox://styles/towerconnect-li/${mapStyle || "clkjag37x00cc01qyfrlccmc9"}`}>
				<FullscreenControl position="bottom-right" />
				<NavigationControl position="bottom-right" />
				{data.length === 0
					? null
					: modify?.map((res) => {
						return (
							<Marker
								key={res.id}
								longitude={Number(res.longitude)}
								latitude={Number(res.latitude)}
								onClick={() => {
									// if(res.type === "org"){
									// 	onSelectCity(res.longitude, res.latitude);
									// 	setOrgModal({ data: res, isOpen: true })
									// 	closePopup()
									// }else{
									onSelectCity(res.longitude, res.latitude);
									setSelectedMarker(res);
									// }
								}}>
								{res.type === towerConnectType.event ? <img src={images.map.event} alt="event" /> : null}
								{res.type === towerConnectType.checkPoint ? <img src={images.map.checkpoint} alt="checkpoint" /> : null}
								{res.type === towerConnectType.nft ? <img src={images.map.nft_marker} alt="nft" /> : null}
								{res.type === towerConnectType.org ? <img src={images.map.org} alt="org" /> : null}
							</Marker>
						);
					})}

				{selectedMarker && (
					<Popup
						className="demo"
						anchor="top"
						latitude={Number(selectedMarker.latitude)}
						longitude={Number(selectedMarker.longitude)}
						style={{ width: "600px", padding: 0 }}
						onClose={closePopup}
						closeOnClick={false}
					>
						<div className="d-flex justify-content-center flex-column">
							<div style={{ height: "140px" }} className="position-relative">
								<div onClick={closePopup} className="position-absolute bg-white d-flex justify-content-center align-items-center" style={{ width: "25px", height: "25px", borderRadius: "50%", top: 10, right: 10 }}>
									<FontAwesomeIcon onClick={closePopup} icon={faXmark} className="fw-600" style={{ fontSize: "15px" }} />
								</div>
								{
									img.isLoading ?
										<div className="w-100 bg-dark h-100 position-absolute d-flex justify-content-center align-items-center">
											<Spinner className="text-white" />
										</div> : null
								}
								{selectedMarker?.type === towerConnectType.org ? (
									<img onLoad={handleImgLoad} onError={handleImgError} src={selectedMarker?.marker?.bannerImage} className="w-100 h-100 object-fit-cover" alt={selectedMarker?.marker?.name} />
								) : (
									<img onLoad={handleImgLoad} onError={handleImgError} src={img?.isError ? images?.logo : selectedMarker?.marker?.image} className="w-100 h-100 object-fit-cover" alt={selectedMarker?.marker?.name} />
								)}
								{
									selectedMarker?.type === towerConnectType.event ?
										<div style={{ right: 10, bottom: 10 }} className="d-flex cursor-pointer rounded-10 position-absolute justify-content-end align-items-center">
											<div className="bg-white rounded-1" style={{ padding: "8px 8px 4px 8px" }}>
												<QRCodeCanvas
													size={60}
													id="qrcode"
													fgColor={primaryColor}
													value={`${String(environment.domainUrl) + "event?eventid=" + selectedMarker.dataId}`}
													color={primaryColor}
													onClick={(e) => {
														e.stopPropagation();
														setModal({ id: `${String(environment.domainUrl) + "event?eventid=" + selectedMarker.dataId}`, isOpen: true });
													}}
												/>
											</div>
										</div>
										: null}
								{selectedMarker?.type === towerConnectType.org ?
									<div>
										<div style={{ right: 10, bottom: 10, left: 10 }} className="position-absolute">
											<div style={{ left: 10, bottom: -28 }} className="position-absolute">
												<img src={selectedMarker?.marker?.image} alt={selectedMarker?.marker?.name} className='w-80px h-80px mh-80px mw-80px overflow-hidden rounded-10 object-fit-cover' />
											</div>
											<div className="bg-white rounded-1 position-absolute cursor-pointer" style={{ padding: "8px 8px 4px 8px", bottom: '0px', right: '10px' }}>
												<QRCodeCanvas
													size={60}
													id="qrcode"
													fgColor={primaryColor}
													value={`${String(environment.domainUrl) + "org/" + selectedMarker.dataId}`}
													color={primaryColor}
													onClick={(e) => {
														e.stopPropagation();
														setModal({ id: `${String(environment.domainUrl) + "org/" + selectedMarker.dataId}`, isOpen: true });
													}}
												/>
											</div>
										</div>
									</div>
									: null}
							</div>
							<div className="p-20">
								<p className="fw-600 font-20 font-ubuntu c-black text-center" title={selectedMarker?.marker?.name?.length < 20 ? "" : selectedMarker?.marker?.name}>
									{selectedMarker?.marker?.name?.length < 20 ? selectedMarker?.marker?.name : selectedMarker?.marker?.name?.slice(0, 20) + "..."}
								</p>
								<p className="font-12 c-gray font-ubuntu text-center" id={"TooltipExample"} title={selectedMarker?.marker?.description?.length < 60 ? "" : selectedMarker?.marker?.description}>
									{selectedMarker?.marker?.description?.length < 60 ? selectedMarker?.marker?.description : selectedMarker?.marker?.description?.slice(0, 90) + "..."}
								</p>
								{selectedMarker?.type === towerConnectType.event && selectedMarker?.marker?.organization ?
									<div className='d-flex mt-10 align-items-center'>
										<img src={selectedMarker?.marker?.organization?.profilePic} alt={selectedMarker?.marker?.organization?.companyName} className="w-50px h-50px mh-50px mw-50px overflow-hidden rounded-10 cursor-pointer object-fit-cover" />
										<div className='mt-10 ml-10'>
											<p
												data-toggle={selectedMarker?.marker?.organization?.companyName && selectedMarker?.marker?.organization?.companyName.length > 32 ? "tooltip" : undefined}
												data-placement="bottom"
												title={selectedMarker?.marker?.organization?.companyName}
												className='fw-600 font-ubuntu font-14'
											>
												{selectedMarker?.marker?.organization?.companyName ? selectedMarker?.marker?.organization?.companyName.length > 32 ? `${selectedMarker?.marker?.organization?.companyName.slice(0, 32)}...` : selectedMarker?.marker?.organization?.companyName : "TowerConnect"}
											</p>
											<p className="mt-1 font-ubuntu">
												This event is hosted by the {selectedMarker?.marker?.organization?.companyName === null && selectedMarker?.marker?.organization?.bannerImage?.length === 0 ? "TowerConnect" : "organization"}.
											</p>
										</div>
									</div>
									: null}
								{selectedMarker?.type === towerConnectType.checkPoint && selectedMarker?.marker?.organization ?
									<div className='d-flex mt-10'>
										<img src={selectedMarker?.marker?.organization?.profilePic} alt={selectedMarker?.marker?.organization?.companyName} className="w-50px h-50px mw-50px mh-50px rounded-10 cursor-pointer object-fit-cover" />
										<div className='mt-10 ml-10 align-items-center'>
											<p className='fw-600 font-ubuntu font-14'>
												{selectedMarker?.marker?.organization?.companyName ? selectedMarker?.marker?.organization?.companyName : "TowerConnect"}
											</p>
											<p className="mt-1 font-ubuntu font-10">
												This checkpoint is created by the {selectedMarker?.marker?.organization?.companyName ? "Organization" : "TowerConnect"}
											</p>
										</div>
									</div>
									: null}
								{selectedMarker?.type !== towerConnectType.org && selectedMarker?.marker?.blckChainId ?
									<>
										<p className="mt-10 font-ubuntu fw-600">
											Blockchain ID
										</p>
										<p className=" font-ubuntu c-gray text-break">
											{(selectedMarker?.marker?.blckChainId)}
										</p>

									</> : null
								}
								{/* {selectedMarker?.type === towerConnectType.event ?
									<button className="block mt-10 font-ubuntu font-14 cursor-pointer fw-bold w-100 h-40 bg_white border-grey rounded-10" onClick={() => redirectNewTap("/event/" + selectedMarker?.dataId)}>
										Learn More
									</button>
									: null} */}
								{selectedMarker?.type === towerConnectType.checkPoint ?
									<p className="mt-1 font-ubuntu c-green text-center">
										Go to that location to discover this CheckPoint
									</p>
									: null}
								{selectedMarker?.type === towerConnectType.org ?
									<>
										<p className="mt-10 font-ubuntu fw-600">
											Blockchain ID
										</p>
										<p className="cursor-pointer mt-1 font-ubuntu c-green" onClick={() => redirectNewTap(selectedMarker?.marker?.blckChainId)}>
											View on edeXa Business Blockchain
										</p>
										{/* <button className="block mt-10 font-ubuntu font-14 cursor-pointer fw-bold w-100 h-40 bg_white border-grey rounded-10" onClick={() => redirectNewTap("/nft/" + selectedMarker?.marker?.id)}>
											Learn More
										</button> */}
									</>
									: null}
								{selectedMarker?.type === towerConnectType.org ?
									<>
										{/* <button type="submit" onClick={(e) => {
											e.stopPropagation();
											setPayMentModal({ id: `${String(environment.domainUrl) + "pay/X Company"}`, isOpen: true });
										}} className="custom-primary w-100 mt-10">Make Payment</button> */}
										<button type="submit" onClick={(e) => {
											e.stopPropagation();
											closePopup()
											setOrgModal({ data: selectedMarker, isOpen: true })
										}} className="custom-primary-outline w-100 mt-10">Learn More</button>
									</> : null}
								{selectedMarker?.type === towerConnectType.nft ? <div className="d-flex">
									<p className="block mt-10 font-ubuntu c-green font-14 cursor-pointer fw-bold" onClick={() => redirectNewTap("/nft/" + selectedMarker?.marker?.id)}>
										Learn More
									</p> </div> : null}
								<div className="d-flex gap-1 mt-10 justify-content-center align-items-center">
									<p className="c-black font-ubuntu">Powered by</p>
									<img src={images.edexaLogo} alt="edexaLogo" className="cursor-pointer" onClick={() => redirectNewTap(environment.edexaUrl || "")} />
								</div>
							</div>
						</div>
					</Popup>
				)}
			</Map >
			<NFTQrCodeModal handleToggle={handleToggle} modal={modal} />
			<PaymentQrCodeModal handleToggle={handlePaymentToggle} modal={payMentModal} />
			<OrgDetailsModal handleToggle={handleOrgModalToggle} modal={orgModal} />
		</>
	);
};

export default SmartCityMap;
