/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { faBell, faFire, faVolumeHigh, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useId, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Dropdown, DropdownMenu, DropdownToggle, Spinner } from "reactstrap"
import { IGetNotificationAPIData, deleteNotificationAPI, getNotificationAPI, updateNotificationAPI } from "../../../../apis/notificationAPI/GetNotificationAPI"
import socket from "../../../../sockets/socketConfig"
import { setNewNotification } from "../../../../store/NotificationSlice"
import { RootState } from "../../../../store/Store"
import TimeStamp from "./TimeStamp"

const NotificationModal = () => {
  const id = useId()
  const targetRef = useRef();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isNewNotification } = useSelector((state: RootState) => state.notification)
  const { user } = useSelector((state: RootState) => state.user)
  const { isLogin } = useSelector((state: RootState) => state.authSlice)

  const [markAsRead, setMarkAsRead] = useState(false)
  const [pagination, setPagination] = useState({ limit: 6, page: 1 })
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [data, setData] = useState<{ data: IGetNotificationAPIData[], count: number }>({ data: [], count: 0 })

  const notificationToggle = () => {
    setNotificationDropDown((prevState) => !prevState)
  };

  useEffect(() => {
    if (notificationDropDown) {
      dispatch(setNewNotification(false))
    }
  }, [isNewNotification, notificationDropDown])

  const getNotificationAction = () => {
    getNotificationAPI(pagination).then(res => {
      if (res.status === 200) {
        if (pagination.page === 1) {
          setData(() => res.data)
        } else {
          setData((item) => {
            return { ...item, data: [...item.data, ...res.data.data] }
          })
        }
        let count = 0;
        res.data.data.map(item => {
          if (!item.isRead) {
            count = count + 1;
          }
        })
        if (count > 2) {
          setMarkAsRead(true)
        }
      }
    })
  }

  const handleSocket = (data: any) => {
    setData(() => { return { data: [], count: 0 } })
    dispatch(setNewNotification(true))
    setPagination(() => { return { page: 1, limit: 6 } })
  }

  useEffect(() => {
    socket.on("notification_" + user?.userId, handleSocket)
    return () => {
      socket.off("notification_" + user?.userId, () => { })
    }
  }, [])

  useEffect(() => {
    if (isLogin) {
      getNotificationAction()
    }
  }, [pagination])

  const deleteNotificationAction = (id?: string) => {
    if (id) {
      setData(res => {
        const tempData = [...res.data]
        const newData = tempData.filter(item => item?.notificationId !== id)
        return { count: res.count - 1, data: newData }
      })
    } else {
      setData({ count: 0, data: [] })
    }
    deleteNotificationAPI(id || "")
  }

  const updateNotificationAction = (index: number, id: string) => {
    const newData = [...data.data]
    newData[index].isRead = true;
    setData({ ...data, data: newData })
    updateNotificationAPI([id])
  }

  const readAllNotificationAction = () => {
    const idArray: string[] = []
    const indexArray: number[] = []
    data.data.map((res, index) => {
      if (!res.isRead) {
        indexArray.push(index)
        idArray.push(res.notificationId)
      }
    })
    if (indexArray.length !== 0) {
      const newData = [...data.data]
      indexArray.map(res => {
        newData[res].isRead = true
      })
      setData({ ...data, data: newData })
    }
    setMarkAsRead(false)
    updateNotificationAPI(idArray)
  }

  const incrementPage = () => {
    setPagination((pre) => { return { ...pre, page: pre.page + 1 } })
  }

  useEffect(() => {
    const options = {
      // @ts-ignore
      root: document.getElementsByClassName("notification-section")[0],
      rootMargin: "0px",
      threshold: 0.1 // A value between 0 and 1, indicating the percentage of the target element that needs to be visible to trigger the callback
    };

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          incrementPage();
        }
      });
    };

    // Create the Intersection Observer instance
    const observer = new IntersectionObserver(callback, options);

    // Start observing the target element
    if (targetRef.current) {

      observer.observe(targetRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [notificationDropDown, data]);

  const handleRedirect = (index: number, data: IGetNotificationAPIData) => {
    if (data?.type === "system") {
      if(data?.title.includes("NFT received successfully")){
        navigate(`/nft/${data.dataId}`, { state: { ownBy: true } })
      }else{
        navigate(`/nft/${data.dataId}`)
      }
      
      updateNotificationAction(index, data.notificationId)
      notificationToggle()
    }
  }

  return (
    <Dropdown
      isOpen={notificationDropDown} toggle={notificationToggle}
      className="d-flex align-items-center justify-content-center"
    >
      <DropdownToggle className="c-bg-green p-0 d-flex align-items-center position-relative cursor-pointer justify-content-center c-border-green">
        {
          isNewNotification ?
            <div className="notification-bell"></div>
            : null
        }
        <FontAwesomeIcon style={{ fontSize: "22px" }} className={`${notificationDropDown ? "text-white" : "c-black"} cursor-pointer`} icon={faBell} />
      </DropdownToggle>
      <DropdownMenu className="rounded-10 dropdown-menu-right mt-10 p-0 border-white shadow overflow-hidden">
        <div className="p-20">
          <div className="d-flex justify-content-between align-items-center">
            <p className="c-black mb-20 fw-600 font-20">Notification's</p>
            {
              markAsRead ?
                <p className="c-green fw-600 font-14 cursor-pointer" onClick={readAllNotificationAction}>All mark as read</p>
                : null
            }
          </div>
          <hr style={{ color: "#dee2e6" }} className="m-0" />
          <div className="p-20 notification-section" style={{ maxHeight: "70vh", overflowX: "auto" }}>
            {
              data?.data?.length === 0 ?
                <div>
                  <p className="text-center c-gray">You do not have any new notification</p>
                </div>
                : data?.data?.map((res, index) => {
                  return (
                    <React.Fragment key={`${id}${res?.notificationId}`}>
                      <div className={`${res.isRead ? "bg-body-tertiary" : "bg-body-secondary"} ${res.type === "broadcast" ? "" : "cursor-pointer"} transiton-02s rounded-1 p-10`} onClick={(e) => handleRedirect(index, res)} >
                        <div className="d-flex position-relative gap-3">
                          <div className={`${res.isRead ? "c-bg-green" : "bg-success"} transiton-02s flex-shrink-0 d-flex justify-content-center align-items-center`} style={{ width: "40px", height: "40px", borderRadius: "50%" }}>
                            <FontAwesomeIcon className="text-white font-20" icon={res.type === "broadcast" ? faVolumeHigh : faFire} />
                          </div>

                          <div className="flex-grow-1">
                            <p className="c-black fw-600 font-16">{res.title}</p>
                            <p className="c-gray font-14 mt-1">{res.message}</p>
                            <div className="d-flex mt-10 justify-content-between align-items-center fw-600 align-items-center font-12">
                              <TimeStamp timer={res.createdAt} />
                              {
                                res.isRead ? null :
                                  <p className="text-primary" onClick={(e) => {
                                    e.stopPropagation()
                                    updateNotificationAction(index, res.notificationId)
                                  }}>Mark as read</p>
                              }
                            </div>
                          </div>
                          <FontAwesomeIcon icon={faXmark} className="text-danger position-absolute cursor-pointer" onClick={(e) => {
                            e.stopPropagation()
                            deleteNotificationAction(res.notificationId)
                          }} style={{ top: "0", right: "0" }} />
                        </div>
                      </div>
                      {
                        data?.data?.length === (index + 1) ? null :
                          <hr style={{ color: "#dee2e6" }} className="" />
                      }
                      {index + 1 === data.data.length ? (
                        data.count === data.data.length ? null : (
                          <div className="mb-30 mt-30">
                            <div className="d-flex justify-content-center">
                              {/* @ts-ignore  */}
                              <div ref={targetRef} className="nft-more-page-loader">
                                <Spinner className="text-white" />
                              </div>
                            </div>
                          </div>
                        )
                      ) : null}
                    </React.Fragment>
                  )

                })
            }
          </div>
          {
            data?.data?.length === 0 ? null : <>
              <hr style={{ color: "#dee2e6" }} className="m-0" />
              <div className="d-flex pb-10 mt-20 justify-content-between align-items-center">
                <p className="c-black fw-600"> {data?.count} notification{data.count > 1 ? "s" : ""}</p>
                <div className="d-flex gap-3">
                  <button className="custom-primary-outline" onClick={() => deleteNotificationAction()}>Clear all</button>
                </div>
              </div>
            </>
          }
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default NotificationModal