/* eslint-disable react-hooks/exhaustive-deps */
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Modal, ModalBody } from "reactstrap"
import { images } from "../../assets/images/Images"
import { RoutePathConst } from "../../utils/constants/RoutesPathConst"
import { environment } from "../../utils/constants/environment"
import { redirectNewTap, toastSuccess } from "../../utils/functions/CommonFunctions"

const EventRedirectModal = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [modal, setModal] = useState({
        isOpen: false
    })
    
    useEffect(() => {
        if (location?.search.includes("?eventid=") || location?.search.includes("?checkpointid=")) {
            setModal({ ...modal, isOpen: true })
        } else {
            if(!location?.pathname.includes("/event")){
                navigate(RoutePathConst.public.allNft)
            }
        }
    }, [location?.search])
    
    const handleToggle = () => {
        setModal({ ...modal, isOpen: false })
        navigate(RoutePathConst.public.allNft)
    }

    return (
        <Modal centered isOpen={modal.isOpen} toggle={handleToggle}>
            <ModalBody className="position-relative p-30">
                <FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green position-absolute" style={{ top: "10px", right: "20px" }} />
                <div className="d-flex justify-content-center">
                    <img src={images.logo} className="cursor-pointer w-sm-auto w-100" alt="logo" onClick={() => navigate(RoutePathConst.public.allNft)} />
                </div>
                <div className="d-flex flex-sm-row flex-column justify-content-center align-items-center gap-4 mt-30">
                    <img src={images.mobileStore.googleStore} alt="playStore" className="cursor-pointer" onClick={() => redirectNewTap("https://play.google.com/store/apps/details?id=com.towerconnect.li")} />
                    <img src={images.mobileStore.appleStore} alt="appleStore" className="cursor-pointer" onClick={() => toastSuccess("Coming Soon")} />
                </div>
                <p className="color-light-grey text-center mt-10">Go to the {location?.pathname.includes("event") ? "EventRedirectModal":"CheckPoint"}  by scanning QR Code with <span className="c-green fw-600">TowerConnect APP</span></p>
                <div className="mt-30 d-flex justify-content-center align-items-center gap-1">
                    <p className="c-black fw-600">Powered by</p>
                    <img src={images.edexaLogo} alt="edxalogo" className="cursor-pointer" onClick={() => redirectNewTap(environment?.edexaUrl || "")} />
                </div>
            </ModalBody>
        </Modal>
    )
}

export default EventRedirectModal
