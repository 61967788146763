import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faBars, faCalendarCheck, faChartSimple, faChevronDown, faEarth, faListCheck, faLock, faPlus, faRightFromBracket, faStore, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { UserLogoutAPI } from "../../../apis/authAPI/userLogoutAPI";
import { images } from "../../../assets/images/Images";
import CustomTooltip from "../../../components/tooltip/ToolTip";
import { setLogin } from "../../../store/AuthSlice";
import { setLoading } from "../../../store/LoaderSlice";
import { RootState } from "../../../store/Store";
import "../../../style/header.scss";
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst";
import { redirectNewTap, toastSuccess } from "../../../utils/functions/CommonFunctions";
import NotificationModal from "./notification/NotificationModal";

const NFTHeader = () => {
	const location = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { user } = useSelector((state: RootState) => state.user)
	const { isLogin } = useSelector((state: RootState) => state.authSlice)

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [userDropDown, setUserDropDown] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const userToggle = () => setUserDropDown((prevState) => !prevState);
	const logoutAction = () => {
		dispatch(setLoading(true))
		navigate(RoutePathConst.public.allNft)
		UserLogoutAPI().then(res => {
			if (res.status === 201 || res.status === 200) {
				dispatch(setLogin(false))
				dispatch(setLoading(false))
				toastSuccess(res.message)
			}
		}).catch(() => {
			toastSuccess("User log-out successfully")
			dispatch(setLogin(false))
		})
	}

	return (
		<div className="position-fixed d-flex justify-content-between align-items-center nft-header" style={{ height: "57px" }}>
			<img src={images.logo} width={169} className="cursor-pointer" alt="logo" onClick={() => navigate(RoutePathConst.public.allNft)} />
			<div className="d-sm-flex d-none align-items-center gap-4">
				<p className={`header-link ${location.pathname === RoutePathConst.public.allNft ? "text-white" : location.pathname === "/" ? "text-white" : "c-black"}`} onClick={() => navigate(RoutePathConst.public.allNft)}>
					All Digital Owner's
				</p>
				<p className="header-link" onClick={() => redirectNewTap(RoutePathConst.public.smartCity)}>
					Smart City
				</p>
				{
					isLogin ?
						<p className={`header-link ${location.pathname === RoutePathConst.private.createNFT ? "text-white" : ""}`} onClick={() => navigate(RoutePathConst.private.createNFT)}>
							Create Digital Owner
						</p> : null
				}
				{
					isLogin ?
						<>
							<NotificationModal />
							<Dropdown
								isOpen={userDropDown} toggle={userToggle}
								className="d-flex align-items-center justify-content-center"
							>
								<DropdownToggle className="c-bg-green c-border-green p-0">
									<div className={`d-flex align-items-center cursor-pointer header-link justify-content-center ${userDropDown ? "text-white" : ""}`}>
										<CustomTooltip direction="bottom" className="mr-10 fw-normal" content="Profile Menu">
											{
												user?.profileImage ?
													String(user?.profileImage).includes("defaultImg.png") ?
														<FontAwesomeIcon className="cursor-pointer font-20" icon={faUserCircle} />
														:
														<img src={user?.profileImage} className="profile-img-header" width={40} height={20} alt="profile-img" /> :
													<FontAwesomeIcon className="cursor-pointer font-20" icon={faUserCircle} />
											}
										</CustomTooltip>
										<FontAwesomeIcon className="cursor-pointer" icon={faChevronDown} />
									</div>
								</DropdownToggle>
								<DropdownMenu className="rounded-1 mt-10 shadow p-0 border-white overflow-hidden" style={{ width: "250px" }}>
									<p className={`${location?.pathname === RoutePathConst.private.profile ? "c-green" : ""} header-drop-down`} onClick={() => {
										userToggle()
										navigate(RoutePathConst.private.profile)
									}}>
										<FontAwesomeIcon icon={faUser} className="mr-20" />
										Profile
									</p>
									<p className={`${location?.pathname === RoutePathConst.private.ownNft ? "c-green" : ""} header-drop-down`} onClick={() => {
										userToggle()
										navigate(RoutePathConst.private.ownNft)
									}}>
										<FontAwesomeIcon icon={faChartSimple} className="mr-20" />
										My Digital Owner's
									</p>
									<p className="header-drop-down border-0" onClick={() => {
										userToggle()
										logoutAction()
									}}>
										<FontAwesomeIcon icon={faRightFromBracket} className="mr-20" />
										Logout
									</p>
								</DropdownMenu>
							</Dropdown>
						</>
						:
						<p className="header-link" onClick={() => {
							navigate(RoutePathConst.public.login)
						}}>
							Login/Register
						</p>
				}
				{/* <img className="cursor-pointer" onClick={() => navigate("/qr-code-scanner")} src={images.qrCode} alt="qrCode" /> */}

			</div>
			<div className="d-flex d-sm-none justify-content-center align-items-center gap-3">
				<img className="cursor-pointer" onClick={() => navigate(RoutePathConst.private.scan)} src={images.qrCode} alt="qrCode" />
				{isLogin && (
					<NotificationModal />
				)}
				<div className="d-sm-none">
					<Dropdown
						isOpen={dropdownOpen} toggle={toggle}
						className="d-flex align-items-center justify-content-center mr-20"
					>
						{/* // adding this for noww only  */}
						<DropdownToggle className="c-bg-green c-border-green p-0">
							<FontAwesomeIcon className="color-white font-26 mt-1" icon={faBars} />
						</DropdownToggle>
						<DropdownMenu className="rounded-1 mt-10 shadow p-0 border-white overflow-hidden" style={{ width: "200px" }}>
							<p className={`${location?.pathname === RoutePathConst.public.allNft ? "c-green" : ""} header-drop-down`} onClick={() => {
								toggle()
								navigate(RoutePathConst.public.allNft)
							}}>
								<FontAwesomeIcon icon={faStore} className="mr-20" />
								All Digital Owner's
							</p>
							<p className={`${location?.pathname === RoutePathConst.private.event ? "c-green" : ""} header-drop-down`} onClick={() => {
								toggle()
								navigate(RoutePathConst.private.event)
							}}>
								<FontAwesomeIcon icon={faCalendarCheck} className="mr-20" />
								Event
							</p>
							<p className={`${location?.pathname === RoutePathConst.private.checkpoint ? "c-green" : ""} header-drop-down`} onClick={() => {
								toggle()
								navigate(RoutePathConst.private.checkpoint)
							}}>
								<FontAwesomeIcon icon={faListCheck} className="mr-20" />
								CheckPoint
							</p>
							<p className={`header-drop-down`} onClick={() => {
								toggle()
								redirectNewTap(RoutePathConst.public.smartCity)
							}}>
								<FontAwesomeIcon icon={faEarth} className="mr-20" />
								Smart City
							</p>
							{
								isLogin ? <>
									<p className={`${location?.pathname === RoutePathConst.private.createNFT ? "c-green" : ""} header-drop-down`} onClick={() => {
										toggle()
										navigate(RoutePathConst.private.createNFT)
									}}>
										<FontAwesomeIcon icon={faPlus} className="mr-20" />
										Create Digital Owner
									</p>
									<p className={`${location?.pathname === RoutePathConst.private.ownNft ? "c-green" : ""} header-drop-down`} onClick={() => {
										toggle()
										navigate(RoutePathConst.private.ownNft)
									}}>
										<FontAwesomeIcon icon={faChartSimple} className="mr-20" />
										My Digital Owner's
									</p>
									<p className={`${location?.pathname === RoutePathConst.private.profile ? "c-green" : ""} header-drop-down`} onClick={() => {
										toggle()
										navigate(RoutePathConst.private.profile)
									}}>
										<FontAwesomeIcon icon={faUser} className="mr-20" />
										Profile
									</p>
									<p className="header-drop-down border-0" onClick={() => {
										toggle()
										logoutAction()
									}}>
										<FontAwesomeIcon icon={faRightFromBracket} className="mr-20" />
										Logout
									</p>
								</> :
									<p className={`header-drop-down`} onClick={() => {
										toggle()
										navigate(RoutePathConst.public.login)
									}}>
										<FontAwesomeIcon icon={faLock} className="mr-20" />
										Login/Register
									</p>
							}
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};

export default NFTHeader;
