import { useEffect, useState } from "react"
import ReactSelect from "react-select"
import { FormGroup } from "reactstrap"
import { GetCategoryAPI } from "../../../apis/categoryAPI/GetCategoryAPI"

interface ICategoryField {
    touched: any, errors: any, setFieldValue: any
}

const CategoryField = ({ touched, errors, setFieldValue }: ICategoryField) => {
    const [data, setData] = useState<{ value: string, label: string } | null>(null)
    const [options, setOptions] = useState<{ value: string, label: string }[]>([])
    const getCategoryAction = () => {
        GetCategoryAPI().then(res => {
            if (res.status === 200) {
                const newOption = res.data.data.map(item => {
                    return {
                        value: item?.categoryId,
                        label: item?.name
                    }
                })
                setOptions(newOption)
            }
        })
    }
    useEffect(() => {
        getCategoryAction()
    }, [])

    return (
        <div className="mt-20">
            <FormGroup className='max-category-menu'>
                <ReactSelect className={`cursor-select ${touched?.categoryId ? (errors?.categoryId ? "error-select" : "") : ""}`}
                    options={options} value={data} onChange={(e) => {
                        setData(e)
                        setFieldValue("categoryId", e?.value)
                    }}
                    placeholder={"Select Category"}
                />
                {touched?.categoryId && errors?.categoryId && <span className="text-danger">{errors.categoryId}</span>}
            </FormGroup>
        </div>
    )
}

export default CategoryField