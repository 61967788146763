import HTTPService from "../axios/AxiosService"
import { API_END_POINTS } from "../utils/constants/ApiEndPoints"
import { languageType } from "../utils/constants/Constants"
import { IApiRes } from "./ActionType"

export const getLabelsAPI = (data: IGetLabelsAPIParams): Promise<IGetLabelsAPI> => {
    return HTTPService.get(API_END_POINTS.labels, data)
}

interface IGetLabelsAPIParams {
    deviceId: string;
    lang: languageType;
}

interface IGetLabelsAPI extends IApiRes {
    data: {
        label: any
    }
}