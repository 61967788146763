import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Modal, ModalBody } from "reactstrap"
import { IGetReviewAPIResData } from "../../../apis/reviewAPI/GetReviewAPI"
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst"
import ReportCheckpointCard from "./ReportCheckpointCard"
import ReportSubmitModal from "./ReportSubmitModal"

const ReportCheckpoint = ({ data, setData }: { data: IGetReviewAPIResData[], setData: React.Dispatch<React.SetStateAction<IGetReviewAPIResData[]>> }) => {
  const navigate = useNavigate()

  const [modal, setModal] = useState({
    isOpen: false
  })
  const [thankYou, setThankYou] = useState({
    isOpen: false
  })
  const handleToggle = ({ status }: { status?: "success" }) => {
    setModal({ isOpen: false })
    if (status === "success") {
      setThankYou({ isOpen: true })
    }
  }

  const handleThankToggle = () => {
    setThankYou({ isOpen: false })
      setData && setData([])
  }

  return (
    <>
      <Container className="mt-100">
        <p className="text-center c-black">Send a report containing all scans of checkpoints.</p>
        {
          data?.map((res, index) => (
            <ReportCheckpointCard data={res} lastIndex={index + 1 === data?.length} index={index + 1} key={index} />
          ))
        }
        <div className="d-flex flex-column gap-3 mt-100 mb-100">
          <button className="custom-primary-outline w-100" onClick={() => {
            navigate(RoutePathConst.private.scan)
          }}>Scan Next</button>
          <button className="custom-primary w-100" onClick={() => setModal({ isOpen: true })}>Submit Report</button>
        </div>
      </Container>
      {/* <Modal isOpen={modal.isOpen} centered>
        <ModalBody className="p-4 position-relative">
          <FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green r-25 position-absolute" />
          <p className="c-black font-20 fw-600">Submit Report</p>
          <div className="mt-20">
            <Label className="c-black">Name</Label>
            <Input placeholder="Name" />
          </div>
          <div className="mt-20">
            <Label className="c-black">Email</Label>
            <Input placeholder="Email" />
          </div>
          <button className="custom-primary mt-20" onClick={handleToggle}>Submit Report</button>
        </ModalBody>
      </Modal> */}

      <ReportSubmitModal modal={modal} data={data} handleToggle={handleToggle} />

      <Modal isOpen={thankYou.isOpen} centered>
        <ModalBody className="p-4 text-center position-relative">
          <FontAwesomeIcon icon={faXmark} onClick={handleThankToggle} className="cursor-pointer c-green r-25 position-absolute" />
          <h1 className="c-green fw-600">Thank You</h1>
          <p className="c-black font-20 mt-20 fw-600">Thank you for being part of CheckPoints on Blockchain</p>
          <p className="text-danger font-20 mt-20 fw-600">Your CheckPoints score is {data?.length}</p>
          <p className="c-gray mt-20 ">Your completed CheckPoints were sent to your e-mail address. For more info Admin will get back to you.</p>
          <button className="custom-primary-outline mt-20 w-100" onClick={handleThankToggle}>Go Back</button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ReportCheckpoint 