import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { RootState } from "../store/Store";

interface IConfirmationModal {
	isOpen: {
		open: boolean;
		label: string;
		key: string;
		checked: boolean | string;
	};
	toggle: () => void;
	handleAction: (label: string, checked: string | boolean) => void;
}

const ConfirmationModal: FC<IConfirmationModal> = ({ isOpen, toggle, handleAction }) => {
	const { isLoading } = useSelector((state: RootState) => state.loader);

	const handleChange = () => {
		handleAction(isOpen.key, isOpen.checked);
	};
	const handleToggle = () => {
		if (!isLoading) {
			toggle();
		}
	};

	return (
		<Modal isOpen={isOpen.open} toggle={handleToggle} centered>
			<ModalBody className="p-4 position-relative">
				<FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green r-25 position-absolute" />
				<p className="fw-600 font-20 color-black1 mb-0">Confirmation</p>
				<p className="color-light-grey mb-0 mt-10" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: isOpen?.label }}>
				</p>
				<div className="d-flex justify-content-end gap-3 mt-20">
					<button disabled={isLoading} className="custom-primary-outline" onClick={toggle}>
						Cancel
					</button>
					<button disabled={isLoading} className="custom-primary" onClick={handleChange}>
						{isLoading ? (
							<div className="d-flex justify-content-center align-items-center">
								<span className=" fw-bold">Loading...</span>
								<div>
									<Spinner className="ml-10" size={"sm"} />
								</div>
							</div>
						) : (
							"Confirm"
						)}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default ConfirmationModal;
