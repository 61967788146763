import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";

export const getQRCodeAPI = (params: IGetQRCodeAPIParams): Promise<IGetQRCodeAPI> => {
    return HTTPService.get(API_END_POINTS.qrCode.getCommentData, params)
}

interface IGetQRCodeAPIParams {
    deviceId: string;
    qrCodeId: string;
}

export interface IGetQRCodeAPIData {
    eventId: string;
    qrCodeId: string;
    name: string,
    description: string,
    isActive: boolean,
    createdBy: {
        companyName: string,
        profilePic: string,
        userId: string
    }
    createdAt: string,
    updatedAt: string,
    questions: [
        {
            logo: string,
            eventId: string,
            qrCodeId: string;
            quesId: string;
            text: string;
            checkPointId: null | string;
            txId: string;
            isActive: boolean,
            createdAt: string,
            updatedAt: string,
            reviews: any[]
        }
    ],
    event: {
        logo: string,
        eventId: string,
        createdBy: string;
        name: string;
        description: string;
        address: string;
        type: string;
        blckChainId: string;
        txId: string;
        isActive: boolean,
        startTime: string;
        endTime: string;
        createdAt: string,
        updatedAt: string,
    }
}

interface IGetQRCodeAPI {
    status: number,
    message: string,
    data: IGetQRCodeAPIData
}