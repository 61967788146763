import CryptoJS, { AES, SHA512, enc } from "crypto-js";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { toast } from "react-hot-toast";
import { cookieExpiresInDays, cookieKeys, dateFormat, dateFormatMonths, dateFormatSec, primaryColor, qrCodeExpiresTime, toasterPosition } from "../constants/Constants";
import { environment } from "../constants/environment";
import { getAudioFingerPrint } from "./getAudioFingerPrint";

export const toastSuccess = (message: string) => {
	toast.remove();
	toast.success(message, {
		position: toasterPosition,
		className: "toast-success",
		style: {
			color: "#000",
			minWidth: 150,
			padding: 10,
			fontWeight: 500,
			marginBottom: 60,
			border: `1px solid ${primaryColor}`
		},
		iconTheme: { primary: primaryColor, secondary: "#fff" },
		duration: 5000
	});
};

export const toastError = (message: string) => {
	toast.remove();
	toast.error(message, {
		position: toasterPosition,
		style: {
			color: "#000",
			fontWeight: 500,
			padding: 10,
			marginBottom: 60,
			border: "1px solid #ff0000"
		}
	});
};

/**
 * This function accepts any input type and returns an encrypted string.
 *
 * @param {any} data
 * @returns {string}
 *
 * @isTestWrittenForThisFunction `true`
 */
export const encryptData = <T extends string | object | boolean | Array<string> | Array<{ [key: string]: string | object }>>(data: T) => {
	return AES.encrypt(JSON.stringify(data), cookieKeys.cryptoSecretKey);
};

/**
 * This function wil decrypt an encrypted string {the encrypted string suppose to be an object}
 *
 * @param {string} data
 * @returns {json | string}
 *
 * @isTestWrittenForThisFunction `true`
 */
export const decryptData = (data: string) => {
	const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
	if (bytes.toString()) {
		return JSON.parse(bytes.toString(enc.Utf8));
	}
	return "";
};

/**
 * It will set and encrypted cookie to localhost and lateron we can use this cookie by decrypting it.
 * @param  {string} key
 * @param {any} data
 *
 * @isTestWrittenForThisFunction `true`
 */
export const setEncryptedCookie = <T extends string | object | boolean | Array<string> | Array<{ [key: string]: string | object }>>(key: string, data: T) => {
	if (data && key) {
		const encryptedString = encryptData(data);
		const keyName = cookieKeys.cookieInitial + "-" + key.trim();
		const date = new Date();
		const expiryTime = new Date(date.setTime(date.getTime() + cookieExpiresInDays * 24 * 60 * 60 * 1000)).toUTCString();
		document.cookie = `${keyName}=${encryptedString};expires=${expiryTime};domain=${window.location.hostname.replace("towerconnect", "")};secure;path=/;`;
	}
};

/**
 * This function will take cookie name as input and returns its decrypted value
 *
 * @param {string} key the name of the cookie
 * @returns {string} decrypted cookie string
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getDecryyptedCookie = (key: string) => {
	if (key) {
		const keyName = cookieKeys.cookieInitial + "-" + key.trim();
		const cookieData = getCookie(keyName);
		if (cookieData) {
			return decryptData(cookieData);
		}
	}
};

/**
 * It will take a key as Input and returns the cookie value of that key
 * @param {string} cookieName
 * @returns {string} cookie
 *
 * @isTestWrittenForThisFunction `false`
 */
export const getCookie = (cookieName: string) => {
	const name = cookieName + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	if (decodedCookie) {
		const ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i].trim();
			while (c.charAt(0) === "") {
				c = c.substring(1);
			}
			if (+c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
	}
	return "";
};

/**
 * It will set cookie for localhost use, without any encryption - it will set as it is.
 * We are using this function for localHostLogin [useLocalhostLogin] custom hook.
 *
 * @param {string} key
 * @param {any} data
 *
 * @isTestWrittenForThisFunction `false`
 */
export const setcheckpointCookieForLocalhost = <T extends string | object | boolean | Array<string> | Array<{ [key: string]: string | object }>>(key: string, data: T) => {
	if (data && key) {
		const keyName = cookieKeys.cookieInitial + "-" + key.trim();
		const date = new Date();
		const expiryTime = new Date(date.setTime(date.getTime() + cookieExpiresInDays * 24 * 60 * 60 * 1000)).toUTCString();
		document.cookie = `${keyName}=${data};expires=${expiryTime};domain=${window.location.hostname.replace("towerconnect", "")};secure;path=/;`;
	}
};

/**
 * This function takes a key as input and remove that key's cookie from storage.
 * @param {string} key name of the cookie
 *
 * @isTestWrittenForThisFunction `false`
 */
export const removedCookie = (key: string) => {
	if (key) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const keyName = cookieKeys.cookieInitial + "-" + key.trim();
		document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=${window.location.hostname.replace("towerconnect", "")};path=/;`;
		// document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=localhost;path=/;`;
	}
};

/**
 *
 * @param {isRedirect} boolean if true user will be redirect to login page
 * @returns Either redirect to login page or return null
 *
 * @isTestWrittenForThisFunction `false`
 */
export const handleLogout = () => {
	sessionStorage.clear();
	localStorage.clear();
	return null;
};

export const handleS3ImageURL = (imageName: string) => {
	const imageUrl = environment.s3Url;
	return `${imageUrl}${imageName}`;
};

export const getFormattedDate = (date: string) => {
	return date ? moment(date).format(dateFormat) : "-";
};

export const getFormattedDateSec = (date: string) => {
	return moment(date).format(dateFormatSec);
};
export const getFormattedDateMonths = (date: string) => {
	return moment(date).format(dateFormatMonths);
};

export const handleFormikTrim = (name: string, value: string, setValue: any) => {
	if (value.trim()) {
		setValue(name, value);
	} else {
		setValue(name, value.trim());
	}
};

export const handleQrCodeDownload = (id: string) => {
	const canvas: any = document.getElementById(id);
	// Set the padding values
	const padding = 20; // Replace with your desired padding value

	// Calculate the new dimensions with padding
	const newWidth = canvas.width + 2 * padding;
	const newHeight = canvas.height + 2 * padding;

	// Create a new canvas with the updated dimensions
	const paddedCanvas = document.createElement("canvas");
	paddedCanvas.width = newWidth;
	paddedCanvas.height = newHeight;

	// Get the 2D context of the padded canvas
	const paddedContext: any = paddedCanvas.getContext("2d");

	// Set the padding color (replace with desired color)
	paddedContext.fillStyle = "#ffffff";
	paddedContext.fillRect(0, 0, newWidth, newHeight);

	// Draw the original canvas onto the padded canvas with padding
	paddedContext.drawImage(canvas, padding, padding);

	// Convert the padded canvas to a data URL with an octet-stream MIME type
	const dataURL: any = paddedCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

	const downloadLink = document.createElement("a");
	downloadLink.href = dataURL;
	downloadLink.download = "qrcode.png";
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export const handleCopy = (title: string, text: string) => {
	navigator.clipboard.writeText(text);
	toastSuccess(title + " copied to clipboard");
};

function toDegreesMinutesAndSeconds(coordinate: any) {
	const absolute = Math.abs(coordinate);
	const degrees = Math.floor(absolute);
	const minutesNotTruncated = (absolute - degrees) * 60;
	const minutes = Math.floor(minutesNotTruncated);
	const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

	return degrees + "° " + minutes + "' " + seconds + '" ';
}

export function convertDMS(lat: any, lng: any) {
	const latitude = toDegreesMinutesAndSeconds(lat);
	const latitudeCardinal = lat >= 0 ? "N" : "S";

	const longitude = toDegreesMinutesAndSeconds(lng);
	const longitudeCardinal = lng >= 0 ? "E" : "W";

	return latitude + " " + latitudeCardinal + ", " + longitude + " " + longitudeCardinal;
}

/**
 * This function will set the encrypted data to localstorage.
 *
 * @param {string} key  name of the item to be set in localstorage
 * @param {string} data data to be set in localstorage
 *
 * @isTestWrittenForThisFunction `true`
 */
export const setEncryptedLocalStorage = <T extends string | object | boolean | Array<string> | Array<{ [key: string]: string | object }>>(key: string, data: T) => {
	if (data && key) {
		const encryptedString = encryptData(data);
		const keyName = cookieKeys.cookieInitial + "-" + key.trim();
		window.localStorage.setItem(keyName, encryptedString.toString());
	}
};

/**
 * This function will get the item from the browser local storage decrypt it and then returns it.
 *
 * @param {string} key - name of the localstorage item key
 * @returns {string} value of the input key
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getDecryptedLocalStorage = (key: string) => {
	if (key) {
		const keyName = cookieKeys.cookieInitial + "-" + key.trim();
		const localStorageData = window.localStorage.getItem(keyName);
		if (localStorageData) {
			return decryptData(localStorageData);
		}
	}
};
/**
 * Set the encrypted data to session storage.
 *
 * @param {string} key - The name of the item to be set in session storage.
 * @param {string | object | boolean | Array<string> | Array<{ [key: string]: string | object }>} data - The data to be set in session storage.
 *
 * @isTestWrittenForThisFunction `true`
 */
export const setEncryptedSessionStorage = <T extends string | object | boolean | Array<string> | Array<{ [key: string]: string | object }>>(key: string, data: T) => {
	if (!data || !key) {
		// If data or key is missing or falsy, do nothing.
		return;
	}

	try {
		const encryptedString = encryptData(data);
		const sessionKeyName = `${cookieKeys.cookieInitial}-${key.trim()}`;
		window.sessionStorage.setItem(sessionKeyName, encryptedString.toString());
	} catch (error) {
		console.error(`Error setting encrypted session storage for key '${key}':`, error);
		// You can handle the error or log it as needed.
	}
};


/**
 * Get and decrypt a value from session storage by its key.
 *
 * @param {string} key - The key of the session storage item.
	* @returns {string | null} - The decrypted value or null if not found.
	*/
export const getDecryptedSessionStorage = (key: string): string | null => {
	if (!key) return null;

	try {
		const sessionStorageKey = `${cookieKeys.cookieInitial}-${key.trim()}`;
		const sessionStorageData = window.sessionStorage.getItem(sessionStorageKey);

		if (sessionStorageData) {
			return decryptData(sessionStorageData);
		}
	} catch (error) {
		console.error("Error getting and decrypting session storage:", error);
	}

	return null; // Return null if the key is not found or an error occurs.
};


export const redirectNewTap = (url: string) => {
	window.open(url, "_blank");
};


interface MyObject {
	[key: string]: any;
}
export const filterParameter = (data: MyObject) => {
	const newData: MyObject = {};
	Object.keys(data).forEach((key) => {
		const value = data[key];
		if (value !== undefined && value !== null && value !== "") {
			newData[key] = value;
		}
	});
	return newData;
};

/**
 * This functions working
 * @Param {null}
	* @return {Promise} - resolve(string)
	*/
export const getDeviceId = () => {
	/***
	* @I userMediaFingerPrint
	* @II FingerPrintJs
	* @III canvasFingerPrint
	* @IV userAgentFingerPrint + canvasFingerPrint
	* @V WebRTC_FingerPrinting
	* @VI audioFingerprinting
	* @VII audio and canvas fingerprint
	*/
	/**
	 * @method 1
	 * this method will get the user's device informations and generate a unique value
	 * @drawback
	 * 1. This function will return same value if another user has same config machine.
	 * 2. If user changes browser plugins(extensions) then the value will also be changed.
	 * 3. If user changes his monitor then this method will also give different result.
	 * 4. mimeTypes is also depricated and will be removed from browsers in future
	 *  @conclusion - this function should never be used
	 */

	/*
	  let navigator_info = window.navigator;
	  let screen_info = window.screen;
	  let uid: any = navigator_info.mimeTypes.length;
	  uid += navigator_info.userAgent.replace(/\D+/g, "");
	  uid += navigator_info.plugins.length;
	  uid += screen_info.height || "";
	  uid += screen_info.width || "";
	  return (uid += screen_info.pixelDepth || "");
	*/

	/**
	 * It will generate unique value for each device always {particular browser}
	* @method 2
	*/
	/**
	 * This function will return a promise which result a string output
	 * @fingerPrintJs - using fingerPrintJs library
	 * @Param {null}
	* @return {Promise}
	*/
	// return FingerprintJs.load()
	//   .then((response) => response.get())
	//   .then((result) => result.visitorId);

	/**
	 * @method 3
	 * @canvasFingerPrint
	 * @Param {null}
	* @return {deviceId} - hashed value with sha512
	*
	* This method usages canvas based fingerprinting -
	* with this approach the statistics show that if you put this information together, your browser fingerprint will only match 1 in 286,777 others.
	* The alternate solution of this approach to return always unique value are
	* @first
	*  1. get the unique username of current user
	*  2. encrypt this value with the user as key
	*  3. return this value.
	* usagecase - usernames will be unique for each user and so our hashed value will be unique for each user.
	* For this approach to work we will need this function to take an string argument which will be the username value of the current logged-in user.
	* @second
	*  1. Combine method 1 and method 3 formulae
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const canvasFingerPrint = (function () {
		try {
			let canvas = document.createElement("canvas");
			let ctx: any = canvas.getContext("2d");
			let txt = "edeXa9accounts..$#edeXa((^@gdsSRTdfyt!~cz";
			ctx.textBaseline = "top";
			ctx.font = "16px 'Arial'";
			ctx.textBaseline = "alphabetic";
			ctx.rotate(0.05);
			ctx.fillStyle = "#f60";
			ctx.fillRect(125, 1, 62, 20);
			ctx.fillStyle = "#069";
			ctx.fillText(txt, 2, 15);
			ctx.fillStyle = "rgba(102, 200, 0, 0.7)";
			ctx.fillText(txt, 4, 17);
			ctx.shadowBlur = 10;
			ctx.shadowColor = "blue";
			ctx.fillRect(-20, 10, 234, 5);
			let string = canvas.toDataURL();
			let hash = 0;
			if (string.length === 0) return "nothing!";
			for (let i = 0; i < string.length; i++) {
				hash = (hash << 5) - hash + string.charCodeAt(i);
				// 1. leftshift operation
				// 2. 0 + char =
				// 3. 0char - this will be converted to binary
				// 4. return to step 1
				hash = hash & hash;
				// bitwise and operation 1(1X1 = 0)
				//  the bit in the resulting binary representation is 1 (1 × 1 = 1); otherwise, the result is 0 (1 × 0 = 0 and 0 × 0 = 0)
				/**
				 * example -
					  0101 (decimal 5)
					AND 0011 (decimal 3)
					  = 0001 (decimal 1)
				 */
				// ref - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_AND
			}
			const hashedDeviceId = SHA512(hash?.toString()).toString(enc.Base64);
			// Replace special characters like "/" with a safe character, e.g., "_"
			const sanitizedDeviceId = String(hashedDeviceId);
			return sanitizedDeviceId
			// return hashedDeviceId; //example - JxXleEyKh11uO0sQZoAF2ICnheXJiR0DVPAvp78qI/9ocQwE8hf8hiQu1EhK+v2L7GnePijKiu6Ygfhu/TY3uA==
		} catch (error) {
			/**
			 * Catch Errors here
			 */
			// fall back to default method if any error occurs
			let navigator_info = window.navigator;
			let screen_info = window.screen;
			let uid: any = navigator_info.mimeTypes.length;
			uid += navigator_info.userAgent.replace(/\D+/g, "");
			uid += navigator_info.plugins.length;
			uid += screen_info.height || "";
			uid += screen_info.width || "";
			return SHA512((uid += screen_info.pixelDepth || "")).toString(enc.Base64);
		}
	})();
	// return canvasFingerPrint;

	/**
	 * @method 4
	 * @userAgentFingerPrint + @canvasFingerPrint
	 * @Param {null}
	* @return {deviceId} - string
	* chances of uniqueness is increased by 1
	* This method is the combination fo the method 1 and method 2
	* usage case - canvas fingerprint + usernavigator value
	* drawback - if two peoples are uging same useragent then there is no meaning of using thsi method
	*/
	// const userNavigator = window.navigator.userAgent.replace(/\D+/g, "");
	// return SHA256(currentUserDeviceId + userNavigator).toString(enc.Base64);


	/**
	 * @method 6
	 * @audioFingerprinting
	 * @param {null}
	* @return {Promise} - number
	*
	* @pitfalls - https://fingerprintjs.com/blog/audio-fingerprinting/
	* except tor browser and brave browser it will work like a charm. Brave and tor are privacy concious browsers so they slightly modify the audio signals.
	*/
	const audioFingerPrint = new Promise((resolve, reject) => {
		getAudioFingerPrint.run(function (fingerprint: any) {
			resolve(fingerprint);
		});
	});

	/**
	 * @method 7 - @audioFingerPrinting + @canvasFingerPrinting
	 * @Accuracy - high
	 *
	 * @param {null}
	* @return {Promise} - result sha512 hash5Kb+kh34lyLdojGH54E1B4RInTdpp9pwmKJgUJ8T7WgDuk13gAatlJ9DhWCAhejG5xgJnbj2KjQTr9PnwdFU1Q==
	*/
	const AudioCanvasFingerPrint = new Promise((resolve, reject) => {
		audioFingerPrint.then((audioChannelResult) => {
			// resolve promise with sha512 hashing
			resolve(SHA512(canvasFingerPrint + audioChannelResult).toString(enc.Base64));
		});
	});
	return AudioCanvasFingerPrint;
};


export const encryptString = (string: string) => {
	// let algo = environment.algorithm;
	const key = CryptoJS.enc.Utf8.parse(environment.encKey); // Convert your key to a CryptoJS key
	const iv = CryptoJS.enc.Utf8.parse(environment.ivKey); // Convert your IV to a CryptoJS IV
	try {
		const encrypted = CryptoJS.AES.encrypt(string, key, { iv, mode: CryptoJS.mode.CTR });
		return encrypted.toString();
	} catch (error) {
		throw error;
	}
};

export const copyToClipboard = (key: string, text: string) => {
	navigator.clipboard.writeText(text)
		.then(() => {
			toastSuccess(key + " copied to clipboard")
			// You can show a success message or perform other actions here
		})
		.catch(error => {
			console.error('Failed to copy text:', error);
			// You can show an error message or perform other actions here
		});
};

/**
 * This function will take a file as input and returns its base64 version string.
 *
 * @param {File} file the file which need to be converted to base64
	* @returns {string} `base64 string`
	*
	* @isTestWrittenForThisFunction `true`
	*/
export const getBase64 = (file: File) => {
	if (file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				return resolve(reader.result);
			};
			reader.onerror = function () {
				reject(false);
			};
		});
	} else {
		return new Promise((resolve) => resolve(""));
	}
};

export const getFormattedDateDaysTime = (date: string) => {
	const now: Date = new Date(date);

	// Format the date in "Day, Month Date, Year" format
	const options: Intl.DateTimeFormatOptions = { weekday: 'long', hour: '2-digit', minute: '2-digit', hour12: true };
	const formattedDate: string = now.toLocaleDateString('en-US', options);
	return formattedDate.replace(" ", ", ")
}


export const getLoginWithQrCodeJSON = (deviceId: any, expires?: boolean) => {
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
	return {
		deviceId: deviceId,
		// @ts-ignore
		deviceName: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
		expirationTime: expires ? new Date() : momentTimeZone.tz(new Date(new Date().getTime() + qrCodeExpiresTime), timeZone).format("YYYY-MM-DD HH:mm:ss")
	}
}