import { faCaretDown, faCheckCircle, faCopy, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useId, useState } from "react";
import { Modal, ModalBody, Table, Tooltip } from "reactstrap";
import { IPublicGetByNFTAPIData } from "../../../apis/nftAPI/GetByNFTAPI";
import { images } from "../../../assets/images/Images";
import { getFormattedDateSec, redirectNewTap, toastSuccess } from "../../../utils/functions/CommonFunctions";

interface INFTDetailsToggle {
	status: {
		isProperties: boolean;
		isDetails: boolean;
	};
	setStatus: React.Dispatch<
		React.SetStateAction<{
			isProperties: boolean;
			isDetails: boolean;
		}>
	>;
	data: IPublicGetByNFTAPIData;
}

const NFTDetailsToggle = ({ setStatus, status, data }: INFTDetailsToggle) => {
	const keyId = useId()

	const [url, setUrl] = useState("");
	const [toTooltipOpen, setToTooltipOpen] = useState([]);
	const [fromTooltipOpen, setFromTooltipOpen] = useState([]);
	const [copyMintAddress, setCopyMintAddress] = useState(false);
	const [ipfsModal, setIpfsModal] = useState({
		isOpen: false
	});
	const [modal, setModal] = useState({
		isOpen: false
	});

	// Function to toggle the 'from' tooltip for a specific index
	const toggleFromTooltip = (index: number) => {
		const newFromTooltipOpen = [...fromTooltipOpen];
		// @ts-ignore
		newFromTooltipOpen[index] = !newFromTooltipOpen[index];
		setFromTooltipOpen(newFromTooltipOpen);
	};

	// Function to toggle the 'to' tooltip for a specific index
	const toggleToTooltip = (index: number) => {
		const newToTooltipOpen = [...toTooltipOpen];
		// @ts-ignore
		newToTooltipOpen[index] = !newToTooltipOpen[index];
		setToTooltipOpen(newToTooltipOpen);
	};

	const handleToggle = () => {
		setModal({ isOpen: !modal.isOpen });
	};

	const handleCopyAddress = (text?: string) => {
		if (text) {
			window.navigator.clipboard.writeText(text).then(() => {
				setCopyMintAddress(true);
				toastSuccess("Wallet address copied to clipboard");
				setTimeout(() => {
					setCopyMintAddress(false);
				}, 2000);
			});
		}
	};


	const handleIpfsToggle = (url: string) => {
		if (url) {
			setUrl(url);
			setIpfsModal({ isOpen: !ipfsModal.isOpen });
		} else {
			setIpfsModal({ isOpen: !ipfsModal.isOpen });
		}
	};

	const handleClick = () => {
		window.open(`https://ipfs.io/ipfs/${url}`, "_blank");
		setIpfsModal({ isOpen: !ipfsModal.isOpen });
	};

	return (
		<div className="w-100 rounded-10 mt-10 bg-white p-20">
			<div
				className="d-flex cursor-pointer justify-content-between align-items-center"
				onClick={() => {
					setStatus({ ...status, isProperties: false, isDetails: !status.isDetails });
				}}>
				<h5 className="fw-600 mb-0 c-black">Details</h5>
				<FontAwesomeIcon style={{ transition: "all 0.4s", transform: `rotate(${status.isDetails ? "180deg" : "0deg"})` }} icon={faCaretDown} />
			</div>
			<AnimatePresence>
				<motion.div
					className="overflow-hidden"
					initial={{ height: 0, opacity: 0, marginTop: 0 }}
					animate={{
						height: !status.isDetails ? 0 : "auto",
						opacity: !status.isDetails ? 0 : 1,
						marginTop: !status.isDetails ? 0 : 10
					}}
					exit={{ height: 0, opacity: 0, marginTop: 0 }}>
					<div className="w-100 nft-toggle-public d-flex justify-content-between align-items-center">
						<p className="w-25 c-gray">Minted by</p>
						<div className="w-75">
							<p className="c-green fw-600">@ TowerConnect</p>
						</div>
					</div>
					{data?.owner !== null && data?.isReserved && (
						<div className="w-100 nft-toggle-public mt-15 d-flex justify-content-between align-items-center">
							<p className="w-25 c-gray">Owned by</p>
							<div className="w-75">
								<p className="c-black fw-600">{data?.owner?.name ? data?.owner?.name : data?.owner?.email.split("@")[0]}</p>
							</div>
						</div>
					)}
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">Transaction URL</p>
						<div className="w-75">
							<a href={data?.txUrl} target="_blank" className="c-green fw-600 text-truncate" rel="noreferrer">
								View on edeXa Business Blockchain
							</a>
						</div>
					</div>
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">Price</p>
						<div className="w-75">
							<p className="c-green fw-600 text-truncate">{data?.price ? Number(data?.price).toFixed(2) + " " + data?.currency : "Not For Sell"}</p>
						</div>
					</div>
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">Timestamp</p>
						<div className="w-75">
							<p className="c-black fw-600 text-truncate">{getFormattedDateSec(data?.createdAt)}</p>
						</div>
					</div>
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">IPFS File</p>
						<div className="w-75">
							<span className="c-green fw-600 cursor-pointer" onClick={() => handleIpfsToggle(data?.ipfsFileHash)}>
								View on IPFS
							</span>
						</div>
					</div>
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">IPFS MetaData</p>
						<div className="w-75">
							<span className="c-green fw-600 text-truncate cursor-pointer" onClick={() => handleIpfsToggle(data?.ipfsJsonHash)}>
								View metadata
							</span>
						</div>
					</div>
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">{data?.isReserved ? "Wallet Address" : "Mint Address"}</p>
						<div className="w-75 d-flex justify-content-between align-items-center">
							<p className="c-black fw-600 text-truncate">{data?.owner?.walletAddress}</p>
							<FontAwesomeIcon icon={copyMintAddress ? faCheckCircle : faCopy} className="cursor-pointer c-green" onClick={() => handleCopyAddress(data?.owner?.walletAddress)} />
						</div>
					</div>
					<div className="w-100 nft-toggle-public mt-15 d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">Minted on</p>
						<div className="w-75">
							<img id="scrollable-content" src={images.edexaLogo} alt="edeXa logo" />
						</div>
					</div>
					<div className="w-100 mt-15 nft-toggle-public d-flex gap-lg-0 gap-4 justify-content-between align-items-center">
						<p className="w-25 c-gray">History</p>
						<div className="w-75 d-flex">
							<p className="c-green cursor-pointer fw-600 text-truncate" onClick={() => {
								if (data?.history === undefined) {
									toastSuccess("Coming Soon")
								} else {
									handleToggle()
								}
							}}>
								View History
							</p>
						</div>
					</div>
				</motion.div>
			</AnimatePresence>
			{
				data?.history === undefined ? null :
					<Modal size="xl" isOpen={modal.isOpen} toggle={handleToggle} centered>
						<ModalBody className="position-relative">
							<FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green r-25 position-absolute" />
							<h4 className="c-black fw-600">Digital Owner History</h4>

							<Table responsive className="mt-20">
								<thead>
									<tr>
										<th className="text-uppercase font-12 c-gray">Status</th>
										<th className="text-uppercase font-12 c-gray">Price</th>
										<th className="text-uppercase font-12 c-gray">From</th>
										<th className="text-uppercase font-12 c-gray">TO</th>
										<th className="text-uppercase font-12 c-gray">Transaction URL</th>
										<th className="text-uppercase font-12 c-gray">DATE</th>
									</tr>
								</thead>
								<tbody>
									{data?.history === null
										? null
										: data?.history?.length !== 0
											? data?.history
												.map((item, index) => (
													<tr key={`${keyId}${item?.toUser?.email}`}>
														<td className="p-10 text-truncate font-14">
															<span className="text-bg-info fw-600 rounded-1 p-2 text-white">{item?.event === "minted" ? "Minted By" : "Transferred"}</span>
														</td>
														<td className="p-10 text-truncate font-14">{item?.price ? `${Number(item?.price).toFixed(2)} ${item?.currency}` : "Not For Sell"}</td>
														<td className="p-10 text-truncate font-14" style={{ maxWidth: "100px" }}>
															<span id={String("from" + index)}>{item?.fromUser !== null ? item?.fromUser?.name : "-"}</span>
															{
																item?.fromUser?.walletAddress &&
																<Tooltip placement="top" isOpen={fromTooltipOpen[index]} target={String("from" + index)} toggle={() => toggleFromTooltip(index)}>
																	{`${item?.fromUser?.walletAddress.slice(0, 5)}...${item?.fromUser?.walletAddress.slice(-4)}`}
																</Tooltip>
															}
														</td>
														<td className="p-10 text-truncate font-14" style={{ maxWidth: "100px" }}>
															<span id={String("to" + index)}>{item?.toUser?.name ? item?.toUser?.name : item?.toUser?.email?.split("@")[0]}</span>
															{
																item?.toUser?.walletAddress &&
																<Tooltip placement="top" isOpen={toTooltipOpen[index]} target={String("to" + index)} toggle={() => toggleToTooltip(index)}>
																	{`${item?.toUser?.walletAddress.slice(0, 5)}...${item?.toUser?.walletAddress.slice(-4)}`}
																</Tooltip>
															}
														</td>
														<td className="p-10 text-truncate font-14">
															<span className="c-green cursor-pointer text-truncate" onClick={() => redirectNewTap(item?.txUrl)}>
																View on edeXa Business Blockchain
															</span>
														</td>
														<td className="p-10 text-truncate font-14">{getFormattedDateSec(item?.createdAt)}</td>
													</tr>
												))
											: null}
								</tbody>
							</Table>
						</ModalBody>
					</Modal>
			}

			<Modal isOpen={ipfsModal.isOpen} centered>
				<ModalBody className="p-4 position-relative">
					<FontAwesomeIcon icon={faXmark} onClick={() => handleIpfsToggle("")} className="cursor-pointer c-green r-25 position-absolute" />
					<h2 className="fw-600 font-18 color-black1 mb-0">Confirmation</h2>
					<p className="color-light-grey mb-0 mt-10" style={{ textAlign: "justify" }}>
						<b className="c-black">Note</b>: By clicking this feature, the page may take some time to reload and load the content. Kindly be patient while we prepare everything for you. Are you sure
						you want to proceed ?
					</p>
					<div className="d-flex gap-4 mt-20">
						<button className="w-100 custom-primary-outline" onClick={() => handleIpfsToggle("")}>
							No
						</button>
						<button className="w-100 custom-primary" onClick={handleClick}>
							Yes
						</button>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default NFTDetailsToggle;
