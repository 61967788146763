import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import "./full-screen.scss";

const FullScreenMessage = () => {
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		const handleOrientationChange = () => {
			const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			if (isMobile) {
				if (window.orientation === 90 || window.orientation === -90) {
					setShowMessage(true);
				}else{
					setShowMessage(false);
				}
			}
		};
		window.addEventListener('orientationchange', handleOrientationChange);
		handleOrientationChange();
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
		};
	}, []);

	useEffect(() => {
		const handler = setTimeout(() => {
			setShowMessage(false);
		}, 5000);
		return () => clearTimeout(handler);
	}, [showMessage])

	return (
		showMessage && (
			<div className="fullscreen-message">
				<div className="text-center w-90">
					<h3 className="mb-20">Please rotate your device back to a vertical position for an optimal viewing experience in <span className="fw-600">TowerConnect</span></h3>
					<motion.div initial={{ rotate: 90, opacity: 0, x: -10 }} className="position-relative" style={{ top: 35 }} transition={{ delay: 2, duration: 1 }} animate={{ opacity: 0.5 }}>
						<FontAwesomeIcon size="8x" icon={faMobile} className="position-absolute" />
					</motion.div>
					<motion.div initial={{ rotate: 90 }} className="position-relative" transition={{ delay: 1, duration: 1 }} animate={{ rotate: 0 }}>
						<FontAwesomeIcon size="8x" icon={faMobile} />
					</motion.div>
					<p className="mt-20">For an Enhanced Experience, Rotate Your Device to Portrait Mode 📱</p>
				</div>
			</div>
		)
	);
};

export default FullScreenMessage;
