import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, Spinner } from "reactstrap";
import { GetEventValidateHashAPI, GetValidateHashAPI, IGetValidateHashAPIRes } from "../../apis/validateHash/GetValidateHashAPI";
import "../../style/validate-hash.scss";
import { moduleType } from "../../utils/constants/Constants";
import { RoutePathConst } from "../../utils/constants/RoutesPathConst";
import { convertDMS, getFormattedDateSec, toastError } from "../../utils/functions/CommonFunctions";
import NFTFooter from "../nft/components/NFTFooter";
import LogoSection from "./components/LogoSection";

const ValidateHash = () => {
	const navigate = useNavigate();
	const location = useLocation()

	const [isLoading, setIsLoading] = useState(false);
	const [verifyError, setVerifyError] = useState(false);
	const [validateHashData, setValidateHashData] = useState<IGetValidateHashAPIRes>();
	const [validateHash, setValidateHash] = useState({
		isVerifyHash: false,
		isVerifyHashList: false,
		hashValue: ""
	});

	const getValidateAction = (validateHashId: string) => {
		setIsLoading(true);
		GetValidateHashAPI(validateHashId)
			.then((res) => {
				if (res.status === 200) {
					setValidateHashData(res.data);
					setVerifyError(false);
					setValidateHash({ ...validateHash, isVerifyHashList: false, hashValue: validateHashId, isVerifyHash: true });
					setIsLoading(false);
				} else if (res.status === 404 || res.status === 400) {
					setIsLoading(false);
					setValidateHash({ ...validateHash, isVerifyHash: false });
					setVerifyError(true);
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const handleClick = (validateHashId: string) => {
		if (validateHashId) {
			getValidateAction(validateHashId);
		} else {
			toastError("Enter blockchain hash");
		}
	};
	const handleKey = (e: any) => {
		if (e.keyCode === 13) {
			handleClick(validateHash.hashValue);
		}
	};

	const getEventValidateAction = (validateHashId: string) => {
		setIsLoading(true);
		GetEventValidateHashAPI(validateHashId)
			.then((res) => {
				if (res.status === 200) {
					setValidateHashData(res.data);
					setVerifyError(false);
					setValidateHash({ ...validateHash, isVerifyHashList: false, hashValue: validateHashId, isVerifyHash: true });
					setIsLoading(false);
				} else if (res.status === 404 || res.status === 400) {
					setIsLoading(false);
					setValidateHash({ ...validateHash, isVerifyHash: false });
					setVerifyError(true);
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (location.search.split("?validateHash=")[1]) {
			if (location.state?.hashType === moduleType.event) {
				setValidateHash({ ...validateHash, hashValue: location.search.split("?validateHash=")[1] })
				getEventValidateAction(location.search.split("?validateHash=")[1])
			} else {
				setValidateHash({ ...validateHash, hashValue: location.search.split("?validateHash=")[1] })
				getValidateAction(location.search.split("?validateHash=")[1]);
			}
		}

	}, [location?.search])

	return (
		<>
			<div className="p-validate">
				<LogoSection />
				<div className="p-validate-card blockchain-card">
					<div className="w-75 p-sm-0 p-4">
						<div className="bg-white rounded-10 p-30">
							<h5 className="fw-600">Validate hash</h5>
							<div className="d-flex gap-2 p-validate-title">
								<Input
									className="input-disabled text-truncate"
									onKeyDown={handleKey}
									disabled={validateHash.isVerifyHash}
									value={validateHash.hashValue}
									onChange={(e) => setValidateHash({ ...validateHash, hashValue: e.target.value })}
									placeholder="Enter blockchain hash "
								/>
								<button
									disabled={validateHash.isVerifyHash}
									type="submit"
									className="custom-primary d-flex justify-content-center align-items-center prevent-select fw-bold"
									onClick={() => handleClick(validateHash.hashValue)}>
									Verify {isLoading ? <div><Spinner size={"sm"} className="ml-10 loading-spinner" /></div> : null}
								</button>
							</div>
						</div>
						{verifyError ? (
							<div className="bg-white rounded-10 mt-30 p-30">
								<h5 className="fw-600 text-danger">The blockchain hash is incorrect</h5>
								<p className="c-gray font-16">Please check and enter the correct details</p>
							</div>
						) : null}
						{validateHash.isVerifyHash ? (
							validateHashData ? (
								<div className="position-relative mt-30 bg-white rounded-10 p-30">
									<div className="d-flex blockchain-card">
										<div className="w-75">
											<h5 className="fw-600">Blockchain details verified</h5>
											<div className="d-flex mt-20 justify-content-between">
												<div className="w-100">
													<p className="c-gray">Name</p>
													<p className="fw-600">{validateHashData?.question?.text || validateHashData?.name}</p>
												</div>
											</div>
											<div className="d-flex mt-10 flex-sm-row flex-column justify-content-between">
												{validateHashData?.checkPoint?.checkPointId ? <>
													<div className="w-100 mt-sm-0 mt-3">
														<p className="c-gray">Attachment</p>
														<p className="fw-600">{validateHashData?.images?.length > 0 ? "Yes" : "No"}</p>
													</div>
												</> : <>
													<div className="w-100 mt-sm-0 mt-3">
														<p className="c-gray">Location</p>
														<p className="fw-600">{validateHashData?.address}</p>
													</div>
												</>}
												<div className="w-100 mt-sm-0 mt-3">
													<p className="c-gray">Date & Time</p>
													<p className="fw-600">{getFormattedDateSec(validateHashData?.createdAt)}</p>
												</div>
											</div>
											{validateHashData?.location ? (
												validateHashData?.location.latitude === "0.0" && validateHashData?.location.longitude === "0.0" ? null : (
													<div className="d-flex flex-sm-row flex-column  mt-20 justify-content-between">
														<div className="w-100">
															<p className="c-gray">Coordinates(DMS)</p>
														</div>
														<div className="w-100">
															<p
																className="fw-600 c-green cursor-pointer"
																onClick={() => window.open(`https://maps.google.com/?q=${validateHashData?.location?.latitude},${validateHashData?.location?.longitude}`, "_blank")}>
																{convertDMS(validateHashData?.location?.latitude, validateHashData?.location?.longitude)}
															</p>
														</div>
													</div>
												)
											) : null}
										</div>
										<div className="w-25 d-flex justify-content-center align-items-center p-10">
											<motion.img
												transition={{ delay: 0.3, duration: 0.4, type: "spring" }}
												initial={{ opacity: 0, scale: 2 }}
												animate={{ opacity: 1, scale: 0.8, rotate: 360 }}
												style={{ left: "-90px", top: "90px" }}
												src="https://account-files-bucket.s3.ap-south-1.amazonaws.com/bmessage/assets/images/verified.png"
												alt="verify"
											/>
										</div>
									</div>
									<button
										type="submit"
										onClick={() => {
											navigate(RoutePathConst.public.validateHash);
											setValidateHash({ ...validateHash, isVerifyHash: false, hashValue: "" });
										}}
										className="w-100 mt-20 custom-primary prevent-select fw-bold">
										Verify another blockchain hash
									</button>
								</div>
							) : null
						) : null}
					</div>
				</div>
				<footer className={"d-flex footer-validate justify-content-center w-100"}>
					<NFTFooter />
				</footer>
			</div>
		</>
	);
};

export default ValidateHash;
