import HTTPService from "../../axios/AxiosService"
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints"
import { IApiRes } from "../ActionType"

export const getEventByIdAPI = (data: any): Promise<IGetEventByIdAPI> => {
    return HTTPService.get(API_END_POINTS.event.getById, data)
}

interface IReviews {
    comment: string;
    createdAt: string;
    images: any[];
    location: {
        latitude: string;
        longitude: string;
    };
    qrCode: {
        name: string;
        qrCodeId: string;
    };
    question: {
        quesId: string;
        text: string;
    };
    reviewId: string;
    txId: string
}

export interface IGetEventByIdAPIData {
    logo: string,
    eventId: string,
    name: string,
    description: string,
    address: string,
    blckChainId: string,
    type: string,
    isActive: boolean,
    startTime: string,
    endTime: string,
    createdAt: string,
    updatedAt: string,
    reviews: IReviews[],
    isJoined: boolean,
    createdBy: {
        companyName: string,
        profilePic: string,
        userId: string,
    }
}

interface IGetEventByIdAPI extends IApiRes {
    data: IGetEventByIdAPIData
}