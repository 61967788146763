/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faAngleLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import * as Yup from "yup";
import { UserForgotPasswordAPI } from "../../apis/authAPI/userForgotPasswordAPI";
import { setLoading } from "../../store/LoaderSlice";
import "../../style/forgot-password.scss";
import { onlyNewPassword } from "../../utils/constants/Constants";
import { RoutePathConst } from "../../utils/constants/RoutesPathConst";
import { toastSuccess } from "../../utils/functions/CommonFunctions";
import CompanyInfo from "./components/CompanyInfo";

const ResetPassword = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [passwordIcon, setShowPassword] = useState({
		password: false,
		confirmPassword: false
	});

	// @ts-ignore
	const togglePassword = (key: string) => setShowPassword({ ...passwordIcon, [key]: !passwordIcon?.[key] });

	const formik = useFormik({
		initialValues: {
			password: "",
			confirmPassword: ""
		},
		validationSchema: Yup.object().shape({
			password: Yup.string()
				.required("New password is required")
				.matches(onlyNewPassword, "Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character"),
			confirmPassword: Yup.string()
				.required("Confirm password is required")
				.oneOf([Yup.ref("password"), ""], "New password and confirm password mismatched")
		}),
		onSubmit: (value) => {
			dispatch(setLoading(true));
			UserForgotPasswordAPI({
				newPassword: value.password,
				confirmPassword: value.confirmPassword,
				resetToken: params?.resetToken || ""
			}).then((res) => {
				if (res.status === 200) {
					navigate(RoutePathConst.public.allNft);
					toastSuccess(res.message);
					dispatch(setLoading(false));
				}
			});
		}
	});
	const { handleBlur, handleChange, handleSubmit, errors, touched } = formik;
	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		e.key.toLowerCase() === "enter" && handleSubmit();
	}

	return (
		<div className="container-fluid login-section">
			<CompanyInfo />
			<div className="rounded-10 bg-white p-40 login-form">
				{/* <img src={images.logo} alt="logo" className="logo" width={"100%"} /> */}
				<div className="login-inner-sec">
					<div className="">
						<p className="c-black font-26">{location.pathname.includes("reset") ? "Reset" : "Set"} Password</p>
						<p className="c-gray"> Please Enter Your Password</p>
						<div className="mt-30">
							<InputGroup>
								<Input
									type={passwordIcon.password ? "text" : "password"}
									id="password"
									placeholder="New Password"
									name="password"
									onChange={handleChange}
									onBlur={handleBlur}
									onKeyDown={onKeyPress}
									invalid={Boolean(touched?.password && errors?.password)}
								/>
								<InputGroupText
									className={touched?.password && errors?.password ? "cursor-pointer input-group-wrapper pass-input" : "input-group-wrapper cursor-pointer"}
									onClick={() => togglePassword("password")}>
									<InputGroupText style={{ border: 0 }}>{passwordIcon.password ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</InputGroupText>
								</InputGroupText>
							</InputGroup>
							{touched?.password && errors?.password && <span className="text-danger">{errors.password}</span>}
						</div>
						<div className="mt-20">
							<InputGroup>
								<Input
									type={passwordIcon.confirmPassword ? "text" : "password"}
									placeholder="Confirm Password"
									name="confirmPassword"
									id="confirmPassword"
									onChange={handleChange}
									onBlur={handleBlur}
									onKeyDown={onKeyPress}
									invalid={Boolean(touched?.confirmPassword && errors?.confirmPassword)}
								/>
								<InputGroupText
									className={touched?.confirmPassword && errors?.confirmPassword ? "cursor-pointer input-group-wrapper pass-input" : "input-group-wrapper cursor-pointer"}
									onClick={() => togglePassword("confirmPassword")}>
									<InputGroupText style={{ border: 0 }}>{passwordIcon.confirmPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</InputGroupText>
								</InputGroupText>
							</InputGroup>
							{touched?.confirmPassword && errors?.confirmPassword && <span className="text-danger">{errors.confirmPassword}</span>}
						</div>
						<button type="submit" className="mt-20 fw-bold w-100 custom-primary height-46" onClick={() => handleSubmit()}>
							{location.pathname.includes("reset") ? "Reset" : "Set"} Password
						</button>
						<div className="d-flex mt-30 justify-content-center align-items-center">
							<p className="c-gray cursor-pointer" onClick={() => navigate(RoutePathConst.public.login)}>
								<FontAwesomeIcon icon={faAngleLeft} />{" "}
								Back to Login</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
