/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { GetCategoryAPI, IGetCategoryAPIRes } from '../../../../apis/categoryAPI/GetCategoryAPI';
import CategorySection from './components/CategorySection';

const Card = ({ res, setPageConfig, pageConfig }: {
    res: IGetCategoryAPIRes, setPageConfig: React.Dispatch<React.SetStateAction<{
        page: number;
        limit: number;
        filter: string[];
    }>>;
    pageConfig: {
        page: number;
        limit: number;
        filter: string[];
    }
}) => {
    const [active, setActive] = useState(false)
    const handleClick = () => {
        if (active) {
            setPageConfig((preState) => {
                const newFilter = preState.filter.filter(item => item !== res.categoryId)
                return {
                    ...preState,
                    page: 1,
                    filter: newFilter
                }
            })
        } else {
            setPageConfig((preState) => {
                const newFilter = [...preState.filter]
                newFilter.push(res.categoryId)
                return {
                    ...preState,
                    page: 1,
                    filter: newFilter
                }
            })
        }
        setActive(preState => !preState)
    }
    useEffect(() => {
        const find = pageConfig.filter.find(item => item === res.categoryId)
        if (find === undefined) {
            setActive(false)
        } else {
            setActive(true)
        }
    }, [pageConfig.filter])

    return <div className={`nft-filter-card fw-600 cursor-pointer prevent-select ${active ? "nft-filter-card-active" : ""}`} onClick={handleClick}>{res?.name}
        {res.nftCount === 0 ? null : ` (${res.nftCount > 9 ? res.nftCount : "0" + res.nftCount} )`}
    </div>
}

const CategoryListComponent = ({ setPageConfig, pageConfig, totalCount }: {
    setPageConfig: React.Dispatch<React.SetStateAction<{
        page: number;
        limit: number;
        filter: string[];
    }>>,
    pageConfig: {
        page: number;
        limit: number;
        filter: string[];
    };
    totalCount: number;
}) => {
    const cardCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<{ count: number; data: IGetCategoryAPIRes[] }>({
        count: 0,
        data: []
    })
    const getCategoryAction = () => {
        setIsLoading(true)
        GetCategoryAPI().then(res => {
            if (res.status === 200) {
                setData(res.data)
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getCategoryAction()
    }, [])


    if (isLoading) {
        return (<Row className="mt-30">
            <Col sm="12">
                <div className="d-flex public-nft-filter justify-content-xl-between flex-wrap gap-3">
                    {
                        cardCount.map((res) => (
                            <div key={res} className={`card is-loading w-115px`} style={{ height: "48px" }}>
                                <p className='image h-100 rounded-0'></p>
                            </div>
                        ))
                    }
                </div>
            </Col>
        </Row>)
    }

    return (
        <Row className="mt-30">
            <Col sm="12" className='d-flex justify-content-between align-items-baseline'>
                {
                    totalCount !== 0 ?
                        <>
                            <div className="d-flex public-nft-filter flex-wrap gap-3">
                                <div className={`nft-filter-card prevent-select fw-600 ${pageConfig.filter?.length < 1 ? "nft-filter-card-active" : "cursor-pointer"} `} onClick={() => {
                                    if (pageConfig.filter.length >= 1) {
                                        setPageConfig((preState) => {
                                            return { ...preState, filter: [], page: 1 }
                                        })
                                    }
                                }}>All
                                    {totalCount === 0 ? null : ` (${totalCount > 9 ? totalCount : "0" + totalCount} )`}
                                </div>
                                {
                                    data?.data?.filter(res => res.nftCount > 0).map((res, index) => {
                                        if (index < 7 && res.nftCount !== 0) {
                                            return <Card key={res.categoryId} res={res} setPageConfig={setPageConfig} pageConfig={pageConfig} />
                                        }
                                        return null
                                    })
                                }
                            </div>
                            {
                                data.data.length > 1 ?
                                    <div className={`p-10 rounded-1 c-black bg-white prevent-select fw-600 ${active ? "c-green" : ""} cursor-pointer`} onClick={() => setActive((preState) => !preState)}>
                                        More
                                    </div> : null
                            }
                        </>
                        : null
                }
            </Col>
            <CategorySection active={active} setActive={setActive} data={data.data} setPageConfig={setPageConfig} pageConfig={pageConfig} />
        </Row>
    )
}

export default CategoryListComponent