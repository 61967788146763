import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";

export const createReportAPI = (params: any): Promise<ICreateReportAPI> => {
    return HTTPService.post(API_END_POINTS.report.create, params)
}

interface IGetCheckpointAPIParams {
    deviceId: string;
    checkPointId: string;
}

interface ICreateReportAPI {
    status: number,
    message: string,
    data: any
}
