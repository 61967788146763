import { IGetReviewAPIResData } from "../../../apis/reviewAPI/GetReviewAPI"
import ImageComponent from "../../../components/ImageComponent"
import { getFormattedDateSec, redirectNewTap } from "../../../utils/functions/CommonFunctions"

const ReportCheckpointCard = ({ index, data,lastIndex }: { index: number, data: IGetReviewAPIResData | any, lastIndex: boolean }) => {
    return (
        <div className="mt-20 d-flex gap-3">
            <div>
                <div className="c-bg-green d-flex justify-content-center align-items-center" style={{ borderRadius: "50%", width: "40px", height: "40px" }}>
                    <h4 className="mb-0 fw-600 text-white">{index}</h4>
                </div>
                <div style={{ border: lastIndex ? "none" : "1px dashed #3fb6ff", width: "0px" }} className="mx-auto h-100"></div>
            </div>
            <div>

                <p className="fw-600 font-26 c-black">{data?.qrCode?.name}</p>
                <div className="mt-10">
                    <p className="fw-600 c-black">QR Name</p>
                    <p className=" c-black">{data?.question?.text}</p>
                </div>
                <div className="mt-10">
                    <p className="fw-600 c-black">Comment</p>
                    <p className=" c-black">{data?.comment}</p>
                </div>

                <div className="mt-10">
                    <p className="fw-600 c-black">Hash blockchain link</p>
                    <p className="text-danger cursor-pointer text-decoration-underline" onClick={() => redirectNewTap("/validate-hash?validateHash=" + data?.txId)}>Verified by edeXa blockchain</p>
                </div>
                {
                    data?.images?.length > 0 ?
                        <div className="mt-10">
                            <p className="fw-600 c-black">Attached image</p>
                            <ImageComponent src={data?.images} className="w-100" />
                            {/* <img src={images.nft} className="w-100 rounded-1 mt-10" alt="nft"></img> */}
                        </div> : null
                }

                <div className="mt-10">
                    <p className="fw-600 c-black">Time & Date</p>
                    <p className=" c-black">
                        {getFormattedDateSec(data?.createdAt)}
                        {/* 27/06/2023 02:49:01 PM */}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ReportCheckpointCard