import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const forgotPasswordAPI = (body: { email: string } | any): Promise<IUserForgotPasswordAPI> => {
    return HTTPService.post(API_END_POINTS.USER.forgotPassword, body);
};


interface IUserForgotPasswordAPI extends IApiRes {
}
