import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const GetNFTAPI = (body: IPublicGetNFTAPI): Promise<IPublicGetNFTAPI> => {
	return HTTPService.get(API_END_POINTS.NFT.GET_NFT, body);
};

interface IPublicGetNFTAPI {
	filter?: string;
	search?: string;
	limit?: number;
	page?: number;
}

export interface IgetNftData {
	compressedFilePath: string;
	createdAt: string;
	createdBy: {
		email: string;
		firstName: string;
		lastName: string;
	};
	currency: string;
	filePath: string;
	fileThumbPath: string;
	id: number;
	isReserved: boolean;
	isVisible: boolean;
	nftId: null | number | string;
	ownBy: string;
	price: null | string;
	title: string;
	requestedBy?: string[]
}

export interface IPublicGetNFTAPIData {
	count: number;
	data: IgetNftData[];
}

interface IPublicGetNFTAPI extends IApiRes {
	data: IPublicGetNFTAPIData;
}
