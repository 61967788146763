import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";

interface INFTPropertiesToggle {
	status: {
		isProperties: boolean;
		isDetails: boolean;
	};
	setStatus: React.Dispatch<
		React.SetStateAction<{
			isProperties: boolean;
			isDetails: boolean;
		}>
	>;
	properties: {
		trait_type: string;
		value: string;
	}[];
}

const NFTPropertiesToggle = ({ setStatus, status, properties }: INFTPropertiesToggle) => {
	return (
		<div className="w-100 rounded-10 mt-10 bg-white p-20">
			<div onClick={() => setStatus({ ...status, isDetails: false, isProperties: !status.isProperties })} className="d-flex justify-content-between align-items-center cursor-pointer">
				<h5 className="fw-600 mb-0 c-black">Properties</h5>
				<FontAwesomeIcon icon={faCaretDown} style={{ transition: "all 0.3s", transform: `rotate(${status.isProperties ? "180deg" : "0deg"})` }} />
			</div>
			<AnimatePresence>
				<motion.div
					initial={{ height: 0, opacity: 0, marginTop: 0 }}
					animate={{
						height: !status.isProperties ? 0 : "auto",
						opacity: !status.isProperties ? 0 : 1,
						marginTop: !status.isProperties ? 0 : 20
					}}
					exit={{ height: 0, opacity: 0, marginTop: 0 }}
					className="row overflow-hidden gap-lg-0 gap-3">
					{properties?.map((attribute, index) => (
						<div className="col-12 col-md-4" key={index}>
							<div className="p-10 metadata-card w-100 rounded-1 mt-10">
								<p className="text-center c-black fw-600 text-truncate">{attribute?.trait_type}</p>
								<p className="text-center text-secondary text-truncate">{attribute?.value}</p>
							</div>
						</div>
					))}
				</motion.div>
			</AnimatePresence>
		</div>
	);
};

export default NFTPropertiesToggle;
