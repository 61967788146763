import { useEffect, useState } from "react"
import { Input } from "reactstrap"
import { IGetSmartCityMapAPIData } from "../../../apis/SmartCityMapAPI/SmartCityMapAPI"
import useDebouncedEffect from "../../../hooks/useDebouncedEffect"
import { debounceTimeInMilliseconds } from "../../../utils/constants/Constants"

const SearchMarker = ({ data, selectedMarker, onSelectCity, setSelectedMarker }: { selectedMarker: any, data: IGetSmartCityMapAPIData[], onSelectCity: any, setSelectedMarker: any }) => {
    const [search, setSearch] = useState("");
    const [tempData, setTempData] = useState<any>();

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.trim()) {
            setSearch(e.target.value)
        } else {
            setSearch("")
        }
    }

    useEffect(() => {
        if (selectedMarker !== tempData) {
            setSearch("")
        }
    }, [selectedMarker])

    const handleSearchAction = (name: string) => {
        const lowerCasedSearch = name?.toLocaleLowerCase();

        // First, check for markers that start with the search term
        const startsWithResult = data.find((res) => {
            const lowerCasedName = res.marker?.name?.toLocaleLowerCase();
            return lowerCasedName.startsWith(lowerCasedSearch);
        });

        if (startsWithResult) {
            setTempData(startsWithResult);
            onSelectCity(startsWithResult?.longitude, startsWithResult?.latitude);
            setSelectedMarker(startsWithResult);
            return; // Return if a starts with match is found
        }

        // If no starts with match, check for markers that include the search term
        const includesResult = data.find((res) => {
            const lowerCasedName = res.marker?.name?.toLocaleLowerCase();
            return lowerCasedName.includes(lowerCasedSearch);
        });

        if (includesResult) {
            setTempData(includesResult);
            onSelectCity(includesResult?.longitude, includesResult?.latitude);
            setSelectedMarker(includesResult);
        }
    }

    useDebouncedEffect(
        () => {
            if (search) {
                handleSearchAction(search);
            }
        },
        debounceTimeInMilliseconds,
        [search]
    );

    // Listen for the "/" key press to focus the search input field
    useEffect(() => {
        const handleKeyPress = (e: any) => {
            if (e.key === "/") {
                e.preventDefault(); // Prevent the default behavior (forward slash in the input field)
                const inputElement = document.querySelector(".search-marker input") as HTMLInputElement | null;
                if (inputElement) {
                    inputElement.focus();
                }
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    return (
        <div className="search-marker">
            <Input onChange={handleSearch} type="search" value={search} placeholder="Search By Name" />
        </div>
    )
}

export default SearchMarker