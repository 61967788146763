import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { Input, Spinner } from "reactstrap";
import { GetValidateHashAPI } from "../../../apis/validateHash/GetValidateHashAPI";
import { convertDMS, getFormattedDateSec, handleCopy, toastError } from "../../../utils/functions/CommonFunctions";

interface IValidateHashCard {
	item: any;
	index: number;
}

const ValidateHashCard: FC<IValidateHashCard> = ({ item, index }) => {
	const [data, setData] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [blockChainData, setBlockChainData] = useState(true);

	const getValidateAction = (validateHashId: string) => {
		setIsLoading(true);
		GetValidateHashAPI(validateHashId)
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setIsLoading(false);
				} else if (res.status === 404) {
					setIsLoading(false);
					toastError(res.message);
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};
	const handleClick = (validateHashId: string) => {
		if (validateHashId) {
			getValidateAction(validateHashId);
		} else {
			toastError("Please enter your hash");
		}
	};
	return (
		<motion.div className={`bg-white ${index > 0 ? "mt-20" : ""} prevent-select rounded-10 p-30`}>
			<h6 className="fw-600 mb-20">
				Q.{index + 1} {item?.question?.text}
			</h6>
			<div className="d-flex gap-2 p-validate-title" onClick={() => handleCopy("Validate hash", item?.txId)}>
				<Input disabled className="input-disabled cursor-pointer text-truncate" defaultValue={item?.txId} placeholder="Enter your hash" />
				{!data ? (
					<button
						type="submit"
						disabled={isLoading}
						className="custom-primary prevent-select fw-bold"
						onClick={(e) => {
							e.stopPropagation();
							handleClick(item.txId);
						}}>
						Verify
					</button>
				) : null}
			</div>
			{isLoading ? (
				<div className="d-flex justify-content-center align-items-center mt-30">
					<span className="mr-10 fw-600">Loading...</span>
					<Spinner size={"sm"} />
				</div>
			) : data ? (
				<>
					<div className="mt-20 blockchain-card-main" style={{ background: "#e7f0fd", padding: "20px", borderRadius: "10px" }}>
						<div className="d-flex justify-content-between align-items-center">
							<h6 className="fw-600 mb-0">Blockchain details verified</h6>
							<motion.div animate={{ rotate: !blockChainData ? 90 : 270 }}>
								<FontAwesomeIcon className="cursor-pointer" icon={faChevronRight} onClick={() => setBlockChainData(!blockChainData)} />
							</motion.div>
						</div>
						{blockChainData ? (
							<motion.div transition={{ delay: 0.2 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="d-flex mt-20 prevent-select blockchain-card">
								<div className="w-75">
									<div className="d-flex justify-content-between">
										<div className="w-100">
											<p className="c-gray">CheckPoint Name</p>
											<p className="fw-600">{data?.question?.text}</p>
										</div>
									</div>
									<div className="d-flex mt-10 justify-content-between">
										<div className="w-100">
											<p className="c-gray">Attachment</p>
											<p className="fw-600">{data?.images?.length > 0 ? "Yes" : "No"}</p>
										</div>
										<div className="w-100">
											<p className="c-gray">Date & Time</p>
											<p className="fw-600">{getFormattedDateSec(data?.createdAt)}</p>
										</div>
									</div>
									{data?.location ? (
										data?.location?.latitude === "0.0" ? null : (
											<div className="d-sm-flex flex-column mt-10 justify-content-between">
												<div className="w-100">
													<p className="c-gray">Coordinates(DMS)</p>
												</div>
												<div className="w-100">
													<p className="fw-600 c-green cursor-pointer" onClick={() => window.open(`https://maps.google.com/?q=${item?.location?.latitude},${item?.location?.longitude}`, "_blank")}>
														{convertDMS(data?.location?.latitude, data?.location?.longitude)}
													</p>
												</div>
											</div>
										)
									) : null}
								</div>
								<div className="w-25 d-flex justify-content-center align-items-center">
									<img style={{ left: "-90px", top: "90px" }} src="https://account-files-bucket.s3.ap-south-1.amazonaws.com/bmessage/assets/images/verified.png" alt="verify" />
								</div>
							</motion.div>
						) : null}
					</div>
				</>
			) : null}
		</motion.div>
	);
};

export default ValidateHashCard;
