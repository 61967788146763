import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const UserValidateAPI = (userId: string): Promise<IUserValidateAPI> => {
	return HTTPService.post(API_END_POINTS.USER.userValidate, { userId });
};

export interface IUserValidateAPIData {
	walletAddress: string,
	userId: string,
	email: string,
	name: string
}

interface IUserValidateAPI extends IApiRes {
	data: IUserValidateAPIData
}