import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import FullScreenMessage from "../components/fullScreen/FullScreenMessage";
import useAuthCookieWatcher from "../hooks/useCookieWatcher";
import { setLogin } from "../store/AuthSlice";
import { RootState } from "../store/Store";
import { cookieKeys } from "../utils/constants/Constants";
import { getDecryyptedCookie } from "../utils/functions/CommonFunctions";
import { PrivateRouteComponent } from "./PrivateRouter";
import { PublicRouteComponent } from "./PublicRouter";
import { UnderMaintenanceRouteComponent } from "./UnderMaintenanceRouter";

const Routers = () => {
	const dispatch = useDispatch()
	const { underMaintenance } = useSelector((state: RootState) => state.globalSetting)
	const { isLogin } = useSelector((state: RootState) => state.authSlice)

	const { exist } = useAuthCookieWatcher(`${cookieKeys.cookieInitial}-${cookieKeys.cookieUser}`, 1000);
	useEffect(() => {
		// if the user is logged in but the auth cookie dosen't exist that means he is logged out from some other subdomains so logout him from current app too.
		if (exist) {
			if (!isLogin) {
				const user = getDecryyptedCookie(cookieKeys.cookieUser)
				user?.token && dispatch(setLogin(true));
			}
		} else {
			if (isLogin) {
				dispatch(setLogin(false));
			}
		}
		// eslint-disable-next-line
	}, [exist]);



	return (
		<>
			<Toaster />
			<Loader />
			{
				underMaintenance ? <UnderMaintenanceRouteComponent /> :
					<>
						<FullScreenMessage />
						{
							isLogin ? <PrivateRouteComponent /> : <PublicRouteComponent />
						}
					</>
			}
		</>
	);
};

export default Routers;
