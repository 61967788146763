import { createSlice } from "@reduxjs/toolkit";
import { languageType } from "../utils/constants/Constants";

const initialState = {
	label: {},
	languageType: languageType.english
};

const labelSlice = createSlice({
	name: "label",
	initialState,
	reducers: {
		setLabel: (state, action) => {
			state.label = action.payload;
		},
		setLanguageType: (state, action) => {
			state.languageType = action.payload
		}
	}
});

export const { setLabel, setLanguageType } = labelSlice.actions;

export default labelSlice.reducer;
