import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import { IGetReviewAPIResData, getReviewAPI } from "../../apis/reviewAPI/GetReviewAPI"
import { images } from "../../assets/images/Images"
import LoadingComponent from "../../components/LoadingComponent"
import { RootState } from "../../store/Store"
import { moduleType } from "../../utils/constants/Constants"
import { RoutePathConst } from "../../utils/constants/RoutesPathConst"
import NFTFooter from "../nft/components/NFTFooter"
import NFTHeader from "../nft/components/NFTHeader"
import ReportCheckpoint from "./reportCheckpoint/ReportCheckpoint"

const Checkpoint = () => {
    const navigate = useNavigate()

    const { deviceId } = useSelector((state: RootState) => state.deviceId)
    const [data, setData] = useState<IGetReviewAPIResData[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const getReviewAction = () => {
        setIsLoading(true)
        getReviewAPI({ deviceId, type: moduleType.checkpoint }).then(res => {
            if (res.status === 200) {
                setData(res.data)
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (deviceId) {
            getReviewAction()
        }
    }, [deviceId])

    return (
        <>
            <NFTHeader />
            {
                isLoading ? <LoadingComponent /> :
                    data?.length > 0 ? <>
                        <ReportCheckpoint data={data} setData={setData} />
                    </> :
                        <Container className="mt-100 vh-60 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <img src={images.qrCode} height={100} alt="qr-code" />
                                <p className="fw-600 font-26 mt-30 c-black text-center">Discover CheckPoint</p>
                                <p className="c-black mt-20 text-center">You Haven't scanned any CheckPoints yet. If you want to submit your answers, <span className="fw-600 cursor-pointer" onClick={() => navigate(RoutePathConst.private.scan)}>Start Scanning</span></p>
                            </div>
                        </Container>
            }
            <NFTFooter />
        </>
    )
}

export default Checkpoint