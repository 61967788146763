export const RoutePathConst = {
	public: {
		allNft: "/all-nfts",
		welcomePageIos: "/welcome",
		nftDetails: "/nft/:id",
		smartCity: "/smart-city",
		qrCode: "/qrcode",
		validateHash: "/validate-hash",
		validateHashId: "/validate-hash/:id",
		verifyEmail: "/user-verify/:inviteToken",
		userResetPassword: "/user/reset-password/:resetToken",
		underMaintenance: "/under-maintenance",
		// new change
		login: "/login",
		register: "/register",
		LoginQRCode: "/login/qr-code",
		forgotPassword: "/forgot-password",
		resendEmail: "/resend-email"
	},
	private: {
		// new change
		ownNft: "/my-nfts",
		createNFT: "/create-nft",
		profile: "/profile",
		notification: "/notification",
		// Checkpoint
		checkpoint: "/checkpoint",
		checkpointId: "/checkpoint/:checkpointId",
		addComment: "/add-comment",
		scan: "/scan",
		// Event
		event: "/event",
		eventId: "/event/:eventId"
	}
};
