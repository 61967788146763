import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const getUnderMaintenanceAPI = (): Promise<IGetUnderMaintenanceAPI> => {
	return HTTPService.get(API_END_POINTS.UNDER_MAINTENANCE.GET);
};

export interface IGetUnderMaintenanceAPIData {
	underMaintenance: boolean
}

interface IGetUnderMaintenanceAPI extends IApiRes {
	data: IGetUnderMaintenanceAPIData;
}
