/* eslint-disable react-hooks/exhaustive-deps */
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { IGetCategoryAPIRes } from "../../../../../apis/categoryAPI/GetCategoryAPI";
import CategoryInputCheck from "./CategoryInputCheck";

interface ICategorySection {
    active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    data: IGetCategoryAPIRes[];
    setPageConfig: React.Dispatch<React.SetStateAction<{
        page: number;
        limit: number;
        filter: string[];
    }>>,
    pageConfig: {
        page: number;
        limit: number;
        filter: string[];
    };
}

const CategorySection = ({ active, setActive, data, pageConfig, setPageConfig }: ICategorySection) => {
    const [alphabetsFilter, setAlphabetsFilter] = useState<{ name: string, value: IGetCategoryAPIRes[] }[]>([])

    const handleAlphabetsFilter = () => {
        const categoriesByFirstCharacter: any = {};
        data.forEach(category => {
            const firstCharacter = category.name.charAt(0).toUpperCase();
            if (!categoriesByFirstCharacter[firstCharacter]) {
                categoriesByFirstCharacter[firstCharacter] = [];
            }
            categoriesByFirstCharacter[firstCharacter].push(category);
        });
        const mainArray: { name: string, value: IGetCategoryAPIRes[] }[] = [];
        Object.keys(categoriesByFirstCharacter).map(res => (
            mainArray.push({ name: res, value: categoriesByFirstCharacter[res] })
        ))
        setAlphabetsFilter(mainArray);
    }


    useEffect(() => {
        handleAlphabetsFilter()
    }, [data])

    const handleFilter = (res: IGetCategoryAPIRes) => {
        if (pageConfig?.filter?.includes(res.categoryId)) {
            setPageConfig((preState) => {
                const newArray = preState.filter.filter(item => {
                    return item !== res.categoryId
                })
                return { ...preState, page:1, filter: [...newArray] }
            })
        } else {
            setPageConfig((preState) => {
                const newFiter = [...preState.filter]
                newFiter.push(res.categoryId)
                return { ...preState, page:1, filter: [...newFiter] }
            })
        }
    }

    return (
        <AnimatePresence>
            {
                active ?
                    <motion.div initial={{ height: "0px", visibility: "hidden" }} animate={{ height: "auto", visibility: "visible" }} exit={{ height: "0px", visibility: "hidden" }} className="overflow-hidden">
                        <Col sm="12" className="mt-30">
                            <div className="w-100 rounded-10 bg-white overflow-hidden position-relative">
                                <FontAwesomeIcon className="c-green cursor-pointer position-absolute" style={{ right: "15px", top: "15px" }} icon={faXmark} onClick={() => setActive((preState) => !preState)} />
                                <div style={{ maxHeight: "360px" }} className="pt-10 prevent-select overflow-auto pb-20 pr-20 pl-20">
                                    <p className="c-black font-20 fw-600">Category Filter</p>
                                    {
                                        <div className="row gap-3 mt-10">
                                            <div className="col-12 col-sm-3">
                                                <div className="d-flex mb-10 justify-content-between">
                                                    <p className="c-green fw-600 ">#</p>
                                                    {
                                                        pageConfig.filter.length > 0 ?
                                                            <p className="c-green fw-600 cursor-pointer" onClick={() => {
                                                                setPageConfig((preState) => {
                                                                    return { ...preState, filter: [] }
                                                                })
                                                            }}>Clear All</p>
                                                            : null
                                                    }
                                                </div>
                                                <div className="d-flex flex-column align-items-start gap-2">
                                                    {
                                                        data?.map(res => (
                                                            <CategoryInputCheck key={res.categoryId} data={res} onClick={handleFilter} filter={pageConfig} />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-9 row">
                                                {
                                                    alphabetsFilter.map(res => (
                                                        <div key={res.name} className="col-12 col-sm-5 col-md-4 col-lg-3">
                                                            <p className="c-green fw-600 mb-10">{res.name}</p>
                                                            <div className="d-flex flex-column align-items-start gap-2">
                                                                {res.value.map(item => (
                                                                    <CategoryInputCheck key={item.id} data={item} onClick={handleFilter} filter={pageConfig} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Col>
                    </motion.div>
                    : null
            }
        </AnimatePresence>
    )
}

export default CategorySection