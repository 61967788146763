import { useEffect } from "react"
import { Container } from "reactstrap"
import { images } from "../../assets/images/Images"
import "../../style/under-maintenance.scss"
import { environment } from "../../utils/constants/environment"
import { redirectNewTap } from "../../utils/functions/CommonFunctions"

const UnderMaintenance = () => {
    useEffect(() => {
        document.title = "Under Maintenance | TowerConnect"
        return () => { document.title = "TowerConnect" }
    }, [])
    return (
        <Container >
            <div className="under-maintenance">
                <img src={images.underMaintenance} alt="under-maintenance" />
                <h2 className="mt-20 text-center c-black fw-600">We are under maintenance</h2>
                <p className="c-black font-20 text-center">Will be back soon!</p>
            </div>
            <img src={images.logo} width={200} className="c-black position-absolute fw-600 text-center" style={{ top: 0, left: "0" }} alt="edexa" />
            <p onClick={() => redirectNewTap(environment.edexaUrl || "")} className="c-black cursor-pointer position-absolute text-center" style={{ bottom: "10px", right: "10px" }}>Powered by <img src={images.edexaLogo} alt="edexa" width={100} /></p>
        </Container>
    )
}

export default UnderMaintenance