import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isNewNotification: false
};

const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setNewNotification: (state, action) => {
			state.isNewNotification = action.payload;
		}
	}
});

export const { setNewNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
