import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ComingSoonState {
	isOpen: boolean;
	label: string;
}

const initialState: ComingSoonState = {
	isOpen: false,
	label: ""
};

const comingSoonSlice = createSlice({
	name: "comingSoon",
	initialState,
	reducers: {
		setComingSoonModal: (state, action: PayloadAction<{ isOpen: boolean; label: string }>) => {
			state.isOpen = action.payload.isOpen;
			state.label = action.payload.label;
		}
	}
});

export const { setComingSoonModal } = comingSoonSlice.actions;

export default comingSoonSlice.reducer;
