import { motion } from "framer-motion";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import socket from "../../../sockets/socketConfig";
import { setLogin } from "../../../store/AuthSlice";
import { setLoading } from "../../../store/LoaderSlice";
import { setUserLoginData } from "../../../store/UserSlice";
import { cookieKeys, primaryColor } from "../../../utils/constants/Constants";
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst";
import { getLoginWithQrCodeJSON, setEncryptedCookie, toastSuccess } from "../../../utils/functions/CommonFunctions";

const LoginQRCodeComponent = ({ deviceId }: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [expiry, setExpiry] = useState(false)
  const [loginQRCodeData, setLoginQRCodeData] = useState(getLoginWithQrCodeJSON(deviceId))


  const handleLoginAction = (data: any) => {
    if (data?.isCancelled) {
      setExpiry(true)
    } else {
      toastSuccess("User logged in successfully")
      dispatch(setLogin(true))
      dispatch(setUserLoginData(data.user))
      dispatch(setLoading(false))
      const demo = {
        token: data.token,
        user: data.user,
        refreshToken: data.refreshToken
      }
      setEncryptedCookie(cookieKeys.cookieUser, demo)
    }
  }

  useEffect(() => {
    if (deviceId) {
      socket.on(`qr_code_${deviceId}`, handleLoginAction)
      setLoginQRCodeData(getLoginWithQrCodeJSON(deviceId))
    }
    return () => {
      socket.off(`qr_code_${deviceId}`, handleLoginAction)
    }
  }, [deviceId])

  useEffect(() => {
    const interval = setInterval(() => {
      setExpiry(true)
    }, 120000)
    return () => {
      clearInterval(interval)
    }
  }, [!expiry])

  const handleRefresh = () => {
    setLoginQRCodeData(getLoginWithQrCodeJSON(deviceId))
    setExpiry(false)
  }


  return (
    <div>
      <p className="font-26 text-center fw-600">Login with QR Code</p>
      <p className="c-gray mt-1 text-center">Continue with TowerConnect</p>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} className="bg-white mt-20 rounded-1 p-20 d-flex justify-content-center align-items-center position-relative" style={{ height: "350px", width: "100%", border: "1px solid #dadce0" }}>
        {
          expiry ?
            <div className="refresh-action w-100 h-100 d-flex justify-content-center align-items-center">
              <button className="custom-primary" onClick={handleRefresh}>Refresh</button>
            </div>
            : null
        }
        <QRCodeCanvas
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          id="qrcode"
          fgColor={primaryColor}
          value={`${window.btoa(JSON.stringify(loginQRCodeData))}`}
          color={primaryColor}
        />
      </motion.div>
      {/* <p className="c-gray mt-20 text-center">Scan from <span className="c-green fw-600 cursor-pointer">Accounts mobile apps</span> to log in instantly. <span className="c-green fw-600 cursor-pointer">More</span></p> */}
      <p className="c-gray mt-20 text-center">Login with <span className="c-green fw-600 cursor-pointer" onClick={() => navigate(RoutePathConst.public.login)}>Credentials</span></p>
    </div>
  )
}

export default LoginQRCodeComponent