import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const updateProfileAPI = (body: { name: string, walletAddress: string } | any): Promise<IUpdateProfileAPI> => {
    return HTTPService.patch(API_END_POINTS.USER.updateProfile, body);
};

interface IUpdateProfileAPIData {
    createdAt: string;
    email: string;
    name: string;
    userId: string;
    walletAddress: string;
}

interface IUpdateProfileAPI extends IApiRes {
    data: IUpdateProfileAPIData[];
}
