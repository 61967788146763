import { faLongArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Container, Modal, ModalBody } from "reactstrap"
import { IGetCheckpointAPIData, getCheckpointAPI } from "../../../apis/checkpointAPI/CheckpointAPI"
import { IGetQRCodeAPIData, getQRCodeAPI } from "../../../apis/qrCodeAPI/QRCodeAPI"
import LoadingComponent from "../../../components/LoadingComponent"
import CustomTooltip from "../../../components/tooltip/ToolTip"
import { RootState } from "../../../store/Store"
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst"
import { toastSuccess } from "../../../utils/functions/CommonFunctions"
import NFTFooter from "../../nft/components/NFTFooter"
import NFTHeader from "../../nft/components/NFTHeader"
import AddComment from "../comment/AddComment"

const CheckpointDetails = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const { deviceId } = useSelector((state: RootState) => state.deviceId)

    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState({
        key: "",
        isOpen: false,
        dataId: ""
    });
    const [data, setData] = useState<IGetCheckpointAPIData | IGetQRCodeAPIData>()

    useEffect(() => {
        if (params?.checkpointId && deviceId) {
            if (location?.state?.qrCodeType === "event") {
                setIsLoading(true)
                getQRCodeAPI({ deviceId: deviceId, qrCodeId: params?.checkpointId }).then(res => {
                    if (res.status === 200) {
                        setData(res.data);
                        setIsLoading(false);
                    }
                }).catch((err) => {
                    if (err.status === 403) {
                        setModal({ isOpen: true, key: "event", dataId: err?.data?.data?.dataId });
                    } else if (err.data.status === 409) {
                        toastSuccess(err.data.message)
                        navigate(RoutePathConst.private.event)
                    } else {
                        setIsLoading(false)
                        navigate(RoutePathConst.private.scan)
                    }
                })
            } else {
                setIsLoading(true)
                getCheckpointAPI({ deviceId: deviceId, checkPointId: params?.checkpointId }).then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }).catch((err) => {
                    if (err.status === 403) {
                        setModal({ isOpen: true, key: "checkPoint", dataId: "" });
                    } else if (err.data.status === 409) {
                        toastSuccess(err.data.message)
                        navigate(RoutePathConst.private.checkpoint)
                    } else {
                        setIsLoading(false)
                        navigate(RoutePathConst.private.scan)
                    }
                })
            }
        }
    }, [params?.checkpointId, deviceId])

    const handleOk = (key: string, dataId: string) => {
        setModal({ isOpen: false, key: "", dataId: "" });
        if (key === "checkPoint") {
            navigate(RoutePathConst.private.checkpoint);
        } else {
            navigate(`${RoutePathConst.private.event}/${dataId}`);
        }
    }

    return (
        <>
            <div>
                <NFTHeader />
                {
                    isLoading ?
                        <LoadingComponent />
                        :
                        data ? <>
                            <Container className="mt-100 d-flex justify-content-between align-items-center">
                                <CustomTooltip content="Back" direction="top">
                                    <FontAwesomeIcon className="d-flex" icon={faLongArrowLeft} onClick={() => navigate(RoutePathConst.private.scan)} />
                                </CustomTooltip>
                                <h2 className="c-black mb-0 fw-600">Add comment</h2>
                                <div></div>
                            </Container>
                            <AddComment data={data} deviceId={deviceId} />
                        </> : null
                }
                <NFTFooter />
                <Modal isOpen={modal.isOpen} toggle={() => setModal({ isOpen: false, key: "", dataId: "" })} centered>
                    <ModalBody className="p-4 position-relative">
                        <FontAwesomeIcon icon={faXmark} onClick={() => setModal({ isOpen: false, key: "", dataId: "" })} className="cursor-pointer c-green r-25 position-absolute" />
                        <p className="color-light-grey mt-10">
                            Before moving forward, kindly submit the review from your previous organization
                        </p>
                        <div className="d-flex justify-content-end mt-20">
                            <button className="custom-primary-outline" onClick={() => handleOk(modal.key, modal.dataId)}>
                                OK
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default CheckpointDetails