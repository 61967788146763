import axios, { AxiosResponse } from "axios";
import { setLogin } from "../store/AuthSlice";
import { setGlobalSetting } from "../store/GlobalSettingSlice";
import { setLoading } from "../store/LoaderSlice";
import store from "../store/Store";
import { API_END_POINTS } from "../utils/constants/ApiEndPoints";
import { cookieKeys } from "../utils/constants/Constants";
import { StatusCode } from "../utils/constants/StatusCode";
import { environment } from "../utils/constants/environment";
import { getDecryyptedCookie, removedCookie, toastError } from "../utils/functions/CommonFunctions";

const handleBaseUrl = () => {
	const url = environment.mobileBaseUrl;
	return url;
};

export const axiosInstance = axios.create({
	baseURL: handleBaseUrl()
});

const errorInterceptor = (errorResponse: AxiosResponse) => {
	if (errorResponse) {
		const { status, message } = errorResponse.data;
		if (status === StatusCode.unauthorized || status === StatusCode.not_acceptable) {
			store.dispatch(setLogin(false));
			removedCookie(cookieKeys.cookieUser)
		} else if (status === StatusCode.maintenance) {
			store.dispatch(setGlobalSetting({ underMaintenance: true }))
		}
		else {
			// this added on user validate time api call
			if (String(errorResponse?.request?.responseURL).includes(API_END_POINTS.USER.userValidate) && status === 404 && message === "User does not exist") {
			} else if (!errorResponse.data.message.includes("Before moving forward" || "Bevor Sie fortfahren")) {
				toastError(errorResponse.data.message);
			}
		}
	}
	store.dispatch(setLoading(false));
};

function checkInternetConnectivity() {
	return navigator.onLine;
}

axiosInstance.interceptors.request.use(
	(req) => {
		if (!checkInternetConnectivity()) {
			toastError("No Internet Connection");
			store.dispatch(setLoading(false));
			return Promise.reject(new Error("No Internet Connection"));
		}
		const cookie = getDecryyptedCookie(cookieKeys.cookieUser);
		if (cookie && cookie.token) {
			req.headers.Authorization = `Bearer ${cookie.token}`;
		}
		return req;
	},
	(err) => {
		return Promise.reject(err);
	}
);

axiosInstance.interceptors.response.use(
	(req) => {
		return req;
	},
	(err) => {
		errorInterceptor(err.response);
		return Promise.reject(err);
	}
);

export default class HTTPService {
	static get<T = never, R = AxiosResponse<T>>(url: string, params?: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.get(url, { params })
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static put<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.put(url, body)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static patch<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.patch(url, body)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static post<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.post(url, body)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static delete<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.delete(url, { data: body })
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static deleteWithParams<T = never, R = AxiosResponse<T>>(url: string, params: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.delete(url, params)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static userInfo<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.get(url, { data: body })
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}

	static authenticateUserBstamp<T = never, R = AxiosResponse<T>>(url: string, body: any, config: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.post(url, body, config)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}
	static demo<T = never, R = AxiosResponse<T>>(url: string, body: any, config: any): Promise<R> {
		return new Promise((resolve, reject) => {
			axiosInstance
				.post(url, body, config)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response || error));
		});
	}
}
