import { motion } from "framer-motion";
import { images } from "../../../assets/images/Images";

const LogoSection = () => {
	const handleClick = (url: string) => {
		window.open(url, "_blank");
	};
	return (
		<div className="p-validate-card">
			<div className="text-center">
				<div className="position-relative">
					<motion.img transition={{ type: "spring", delay: 0.1, duration: 1 }} initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} src={images.logo} width={350} alt="logo" />
					<motion.hr transition={{ type: "spring", delay: 0.4, duration: 1 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="fw-600 w-100" style={{ borderWidth: "2px" }} />
				</div>
				<motion.h4 transition={{ type: "spring", delay: 0.5, duration: 0.5 }} initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} className="text-center">
					Say <b>HELLO</b> to BLOCKCHAIN and
				</motion.h4>
				<motion.h4 transition={{ type: "spring", delay: 1, duration: 0.5 }} initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }}>
					<b>MAKE</b> your EXPERIENCES with innovative tech
				</motion.h4>
				<motion.div
					transition={{ type: "spring", delay: 1.5, duration: 0.5 }}
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					className="d-flex justify-content-center align-items-center gap-1">
					<p className="text-center fw-600">Powered by</p>
					<img
						alt="edexa"
						className="cursor-pointer"
						onClick={() => handleClick("https://edexa.network/")}
						src={"https://account-files-bucket.s3.ap-south-1.amazonaws.com/accounts/assets/images/edexa-blue.svg"}
					/>
				</motion.div>
			</div>
		</div>
	);
};

export default LogoSection;
