import { faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"

const ScrollTopButton = () => {
    const [active, setActive] = useState(false)

    const handleScroll = () => {
        const element =  document.getElementsByClassName("nft-section")[0]
        if (element.clientHeight + element.scrollTop > element.clientHeight + 200) {
            setActive(true);
        } else {
            setActive(false);
        }
    };

    useEffect(() => {
        const element: any = document.querySelector('.nft-section');
        element.addEventListener('scroll', handleScroll);
        return () => {
            element.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollTop = () => {
        const ndtSection = document.getElementsByClassName("nft-section")[0];
        ndtSection.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <AnimatePresence>
            {
                active ? <motion.div initial={{ y: 100 }} 
                whileHover={{ scale: 1.1 }}
                animate={{ y: 0 }} exit={{ y: 100 }} onClick={handleScrollTop} className="top-redirect">
                    <FontAwesomeIcon icon={faAngleUp} />
                </motion.div > : null
            }
        </AnimatePresence>
    )
}

export default ScrollTopButton