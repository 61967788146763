
export const cookieExpiresInDays = 1;
export const qrCodeExpiresTime = 120000;
export const primaryColor = "#3FB6FF";
export const primaryColor2 = "#00b6fb4d";
export const toasterPosition = "bottom-left";
export const debounceTimeInMilliseconds = 500;
export const dateFormat = "DD-MM-YYYY | h:mm A";
export const dateFormatSec = "DD-MM-YYYY | h:mm:ss A";
export const dateFormatMonths = 'D MMMM, YYYY';
export const onlyNewPassword =
	// eslint-disable-next-line
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16}$)/;
export const powerByText = "Powered by edeXa Business Blockchain © " + new Date().getFullYear();
export const cookieKeys = {
	cryptoSecretKey: "TowerConnectUser",
	cookieInitial: "TowerConnect",
	cookieUser: "CookieUser",
	token: "Token"
};
export const localStorageKeys = {
	underMaintenance: "UnderMaintenance"
};
export const sessionStorageKeys = {
	globalSettings: "GlobalSettings"
};
export const extensionLists = {
	video: ["m4v", "avi", "mpg", "mp4", "webm"],
	image: ["jpg", "gif", "bmp", "png"],
	document: ["pdf", "doc", "docx"]
};
export const ReactSelectCustomstyle = {
	dropdownIndicator: (base: any) => ({
		...base,
		color: "#4c4f53", // Custom colour
		cursor: "pointer"
	}),
	control: (base: any) => ({
		...base,
		borderColor: "#dadce0 !important",
		minHeight: 50,
		// This line disable the blue border
		boxShadow: "none",
		"&:hover": {
			borderColor: "#dadce0 !important"
		}
	})
};

export enum towerConnectType {
	event = "event",
	nft = "nft",
	qrCode = "qrcode",
	checkPoint = "checkpoint",
	org="org"
}

export enum authType {
	login = "Login",
	register = "Register",
	loginQRCode = "QRCode"
}

export enum reverseNFTStatus {
	allReadyRequest = "allReadyRequest",
	reserveRequest = "reserveRequest",
	transferNFT = "transferNFT"
}
export enum NFTCreateStatus {
	create = "create",
	inprogress = "inprogress",
	minted = "minted"
}

export enum qrCodeURL {
	checkpoint = "checkpointid=",
	publicEvent = "qrcodeid=",
	privateEvent = "event?eventid=",
	nft = "/nft/"
}

export enum moduleType {
	checkpoint = "checkpoint",
	event = "event",
	nft = "nft",
	profile = "profile",
}

export enum languageType {
	english = "en",
	german = "de"
}