import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const updateNFTAPI = (body: IUpdateNFTAPIParams): Promise<IUpdateNFTAPI> => {
	return HTTPService.put(API_END_POINTS.NFT.UPDATE_NFT + body.id, { isVisible: body.isVisible });
};

interface IUpdateNFTAPIParams {
	id?: string;
	isVisible: boolean;
}

interface IUpdateNFTAPI extends IApiRes {
}
