import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Container, Input, Label } from "reactstrap"
import * as Yup from "yup"
import { IGetCheckpointAPIData } from "../../../apis/checkpointAPI/CheckpointAPI"
import { IGetQRCodeAPIData } from "../../../apis/qrCodeAPI/QRCodeAPI"
import { createReviewAPI } from "../../../apis/reviewAPI/ReviewAPI"
import ImageComponent from "../../../components/ImageComponent"
import { setLoading } from "../../../store/LoaderSlice"
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst"
import { getBase64, handleFormikTrim, toastError, toastSuccess } from "../../../utils/functions/CommonFunctions"

const AddComment = ({ data, deviceId }: { data: IGetCheckpointAPIData | IGetQRCodeAPIData, deviceId: string }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  })
  const [imgState, setImgState] = useState({
    imgSrc: "",
    isOpen: false,
    isError: false,
    isLoading: true
  })

  const commentFormik = useFormik({
    initialValues: {
      image: "",
      comment: ""
    },
    validationSchema: Yup.object().shape({
      image: Yup.mixed(),
      comment: Yup.string().required("Comment is required"),
    }),
    onSubmit: (value) => {
      const demo = {
        comment: value.comment,
        deviceId,
        // qrCodeId: "",
        // eventId: "",
        quesId: data?.questions[0]?.quesId,
        // @ts-ignore
        checkPointId: data?.checkPointId,
        images: [
          values?.image
        ],
        latitude: location?.latitude,
        longitude: location?.longitude
      }
      const formData = new FormData();
      formData.append("comment", demo.comment!)
      formData.append("deviceId", demo.deviceId!)
      formData.append("quesId", demo.quesId!)
      if (demo?.images[0]) {
        formData.append("images", demo.images[0]!)
      }
      // @ts-ignore
      if (data?.checkPointId) {
        formData.append("checkPointId", demo.checkPointId!)
      }

      // @ts-ignore
      if (data?.questions[0]?.eventId) {
        formData.append("eventId", data?.questions[0]?.eventId!)
        formData.append("qrCodeId", data?.questions[0]?.qrCodeId!)
      }
      if (location.latitude !== 0 && location.longitude !== 0) {
        formData.append("latitude", String(demo.latitude!))
        formData.append("longitude", String(demo.longitude!))
      }

      dispatch(setLoading(true))
      createReviewAPI(formData).then(res => {
        if (res.status === 200) {
          dispatch(setLoading(false))
          toastSuccess(res.message)
          // @ts-ignore
          if (data?.eventId) {
            // @ts-ignore
            navigate("/event/" + data?.eventId)
          } else {
            navigate(RoutePathConst.private.checkpoint)
          }
        }
      })
    }
  })

  const { values, errors, setFieldValue, handleSubmit, handleBlur, touched } = commentFormik

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, [])


  function success(position: any) {
    setLocation({
      latitude: position?.coords?.latitude,
      longitude: position?.coords?.longitude
    })
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target;
    const file = inputElement?.files?.[0];
    if (file) {
      const fileType = file.type;
      const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"];
      if (validImageTypes.includes(fileType)) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = function () {
          const width = img.width;
          const height = img.height;
          if (width < 336 || height < 280) {
            toastError("Image dimensions must be at least 336x280 pixels!");
            return;
          } else {
            if (file.size > 20000000) {
              toastError("File size should not exceed more than 20MB");
            } else {
              getBase64(file).then((res) => {
                if (res) {
                  setFieldValue("image", file);
                  setImgState({
                    ...imgState,
                    // @ts-ignore
                    imgSrc: res
                  });
                }
              });
            }
          }
        };
      } else {
        toastError("Please select image only");
      }
      inputElement.value = '';
    }
  };

  return (<>
    <Container>
      {/* @ts-ignore */}
      <ImageComponent src={data?.logo || data?.questions[0]?.logo} className="mt-20" />
      {/* <img src={data?.logo} className="w-100 rounded-2 mt-20" alt="comment" /> */}
      {data?.createdBy?.companyName && <>
        <div className='d-flex mt-20 gap-3 align-items-center'>
          <img src={data?.createdBy?.profilePic} alt={data?.createdBy?.companyName} className="rounded-10 cursor-pointer object-fit-cover" style={{ width: "50px", height: "50px", minWidth: "50px" }} />
          <div className='mt-10'>
            <p className='fw-600 font-ubuntu font-14'>{data?.createdBy?.companyName}</p>
            <p className="mt-1 font-ubuntu font-14">
              This checkpoint is created by the organization.
            </p>
          </div>
        </div>
      </>}
      <p className="fw-600 mt-20 c-black">Checkpoint Number</p>
      <p className="c-black text-wrap">{data?.name}</p>
      <p className="fw-600 mt-20 c-black">Question</p>
      <p className="c-black text-wrap">{data?.questions[0]?.text}</p>
      <p className="fw-600 c-black mt-20">Description</p>
      <p className="c-black text-wrap">{data?.description}</p>
      <div className="mt-20">
        <Label className="c-black fw-600" htmlFor="comment-image">Add Image</Label>
        {
          imgState?.imgSrc ?
            <div onClick={() => document.getElementById("comment-image")?.click()}  className="border-1 position-relative d-flex cursor-pointer justify-content-center align-items-center h-100 rounded-2 overflow-hidden" style={{ maxHeight: "550px", border: imgState?.imgSrc ? "" : "1px solid #fff" }} >
              <img src={imgState?.imgSrc} className="w-100 h-100 object-fit-cover" alt="logo" />
            </div> :
            <Label className="p-40 d-flex flex-column justify-content-center align-items-center rounded-2 bg-white" htmlFor="comment-image" style={{ border: "1px dashed #dee2e6" }}>
              <FontAwesomeIcon icon={faCamera} className="c-black" size="4x" />
              <p className="text-center mt-20 c-gray">Upload or capture jpg or png, Max size: 5 MB!</p>
            </Label>
        }
        <Input type="file"
          onChange={handleFile}
          id="comment-image"
          accept="image/*"
          className="d-none" />

      </div>
      <div className="mt-20">
        <Label className="c-black fw-600" htmlFor="comment">Add your thoughts <span className="text-danger">*</span></Label>
        <Input
          id="comment"
          name="comment"
          onBlur={handleBlur}
          invalid={Boolean(touched?.comment && errors?.comment)}
          rows="5"
          onChange={(e) => {
            handleFormikTrim("comment", e.target.value, setFieldValue)
          }}
          value={values.comment}
          type="textarea" placeholder="Add your thoughts" />
        {
          touched?.comment ? errors?.comment ?
            <span className="text-danger">{errors?.comment}</span> : null : null
        }
      </div>
      <button type="submit" className="custom-primary mt-20 w-100" onClick={() => handleSubmit()}>Submit</button>
    </Container>
  </>
  )
}

export default AddComment