import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { Input, Label } from "reactstrap"
import { images } from "../../../assets/images/Images"
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst"
import { environment } from "../../../utils/constants/environment"
import { redirectNewTap } from "../../../utils/functions/CommonFunctions"

const MapStyle = ({ setMapStyle }: { setMapStyle: React.Dispatch<React.SetStateAction<string>> }) => {
    const navigate = useNavigate()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMapStyle(e.target.value)
    }
    return (
        <>
            <div className="map-style">
                <Label className="c-black cursor-pointer prevent-select mr-20" check onClick={() => navigate(RoutePathConst.public.allNft)}>
                    <FontAwesomeIcon icon={faArrowLeftLong} className="mr-10" />
                    Back
                </Label>
                <Label className="c-black cursor-pointer prevent-select" check>
                    <Input className="mr-10" defaultChecked type="radio" onChange={handleChange} name="mapStyle" value={"clkjag37x00cc01qyfrlccmc9"} />
                    satellite streets
                </Label>
                <Label className="c-black cursor-pointer prevent-select" check>
                    <Input className="mr-10" type="radio" onChange={handleChange} name="mapStyle" value={"cll0jegsn009a01p834w3h5u9"} />
                    light
                </Label>
                <Label className="c-black cursor-pointer prevent-select" check>
                    <Input className="mr-10" type="radio" onChange={handleChange} name="mapStyle" value={"cll0jmzbu009g01ph29xc8jmq"} />
                    dark
                </Label>
                <Label className="c-black cursor-pointer prevent-select" check>
                    <Input className="mr-10" type="radio" onChange={handleChange} name="mapStyle" value={"cll0jls0e008q01pb86ml6ysb"} />
                    streets
                </Label>
                <Label className="c-black cursor-pointer prevent-select" check>
                    <Input className="mr-10" type="radio" onChange={handleChange} name="mapStyle" value={"cll0jr2no009j01qs2dmnewe5"} />
                    outdoors
                </Label>
            </div>
            <div className="smart-city-header d-flex justify-content-center align-items-center">
                <h5 className="c-black mr-10 mb-0 fw-600">SmartCity on Blockchain</h5>
                <img className="cursor-pointer" onClick={() => redirectNewTap(environment.edexaUrl || "")} src={images.edexaLogo} width={100} alt="edexa logo" />
            </div>
        </>
    )
}

export default MapStyle