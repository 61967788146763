import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
import { createReportAPI } from "../../../apis/reportAPI/CreateReportAPI";
import { setLoading } from "../../../store/LoaderSlice";
import { RootState } from "../../../store/Store";
import { moduleType } from "../../../utils/constants/Constants";
import { handleFormikTrim } from "../../../utils/functions/CommonFunctions";

const ReportSubmitModal = ({ modal, handleToggle, data, type }: any) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const { deviceId } = useSelector((state: RootState) => state.deviceId)
    const form = useFormik({
        initialValues: {
            name: "",
            email: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Name is required"),
            email: Yup.string().email("Email is invalid")
                .required("Email is required")
        }),
        onSubmit: (value) => {
            setIsLoading(true)
            dispatch(setLoading(true))
            const newData: any = {
                name: value.name,
                email: value.email,
                deviceId,
                type: type || moduleType.checkpoint,
            }
            if (type) {
                newData.eventId = data?.eventId
                newData.reviewIds = data.reviews.map((res: any) => {
                    return res.reviewId
                })
            } else {
                newData.reviewIds = data.map((res: any) => {
                    return res.reviewId
                })
            }
            createReportAPI(newData).then(res => {
                if (res.status === 201) {
                    handleToggle({ status: "success" })
                    dispatch(setLoading(false))
                    setIsLoading(false)
                }
            }).catch(() => setIsLoading(false))
        }
    })

    const { errors, values, handleBlur, setFieldValue, touched, handleSubmit, resetForm } = form

    useEffect(() => {
        if (!modal.isOpen) {
            resetForm()
        }
    }, [modal.isOpen])

    return (
        <Modal isOpen={modal.isOpen} centered>
            <ModalBody className="p-4 position-relative">
                <FontAwesomeIcon icon={faXmark} onClick={handleToggle} className="cursor-pointer c-green r-25 position-absolute" />
                <p className="c-black font-20 fw-600">Submit Report</p>
                <div className="mt-20">
                    <Label className="c-black">Name</Label>
                    <Input placeholder="Name"
                        name="name"
                        id="name"
                        onBlur={handleBlur}
                        value={values.name}
                        onChange={(e) => {
                            handleFormikTrim(e.target.name, e.target.value, setFieldValue)
                        }}
                        invalid={Boolean(touched?.name && errors?.name)}
                    />
                    {touched?.name ? errors?.name ? <span className="text-danger">{errors?.name}</span> : null : null}
                </div>
                <div className="mt-20">
                    <Label className="c-black">Email</Label>
                    <Input placeholder="Email"
                        name="email"
                        id="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                            handleFormikTrim(e.target.name, e.target.value, setFieldValue)
                        }}
                        invalid={Boolean(touched?.email && errors?.email)}
                    />
                    {touched?.email ? errors?.email ? <span className="text-danger">{errors?.email}</span> : null : null}
                </div>
                <button type="submit" disabled={isLoading} className="custom-primary mt-20" onClick={() => handleSubmit()}>Submit Report
                    {isLoading ?
                        <Spinner className="ml-10" size={"sm"} />
                        : null}
                </button>
            </ModalBody>
        </Modal>
    )
}

export default ReportSubmitModal