import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const TransferNFTAPI = (body: ITransferNFTAPIparams): Promise<IApiRes> => {
    return HTTPService.put(API_END_POINTS.NFT.TRANSFER_NFT, body);
};

interface ITransferNFTAPIparams {
    userId: string,
    id: string
}
