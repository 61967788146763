import { faArrowLeftLong, faCalendarDays, faCubes, faLocationDot, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Container, Modal, ModalBody } from "reactstrap"
import { IGetEventByIdAPIData, getEventByIdAPI } from "../../../apis/eventAPI/GetEventByIdAPI"
import { joinEventAPI } from "../../../apis/eventAPI/JoinEventAPI"
import { images } from "../../../assets/images/Images"
import ImageComponent from "../../../components/ImageComponent"
import LoadingComponent from "../../../components/LoadingComponent"
import socket from "../../../sockets/socketConfig"
import { setLoading } from "../../../store/LoaderSlice"
import { RootState } from "../../../store/Store"
import { moduleType } from "../../../utils/constants/Constants"
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst"
import { environment } from "../../../utils/constants/environment"
import { getFormattedDateDaysTime, getFormattedDateMonths, toastSuccess } from "../../../utils/functions/CommonFunctions"
import ReportCheckpointCard from "../../checkpoint/reportCheckpoint/ReportCheckpointCard"
import ReportSubmitModal from "../../checkpoint/reportCheckpoint/ReportSubmitModal"
import NFTFooter from "../../nft/components/NFTFooter"
import NFTHeader from "../../nft/components/NFTHeader"
import NFTQrCodeModal from "../../nft/components/NFTQrCodeModal"
import CountdownTimer from "./CountdownTimer"

const EventDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { deviceId } = useSelector((state: RootState) => state.deviceId)

    const [isLoading, setIsLoading] = useState(true)
    const [joinModal, setJoinModal] = useState(false)
    const [data, setData] = useState<IGetEventByIdAPIData>()
    const [modalReport, setModalReport] = useState({
        isOpen: false
    })
    const [thankYou, setThankYou] = useState({
        isOpen: false
    })
    const [modal, setModal] = useState({
        isOpen: false,
        id: ""
    });

    const getEventDataAction = (eventId: string) => {
        setIsLoading(true)
        getEventByIdAPI({ eventId, deviceId }).then(res => {
            if (res.data) {
                if (res.data?.type === "private" && !res.data.isJoined) {
                    setJoinModal(true)
                } else {
                    setData(res.data)
                }
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (params?.eventId && deviceId) {
            getEventDataAction(params?.eventId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.eventId, deviceId])

    const handleToggle = () => {
        setModal({ isOpen: !modal.isOpen, id: `${String(environment.domainUrl) + "event/" + data?.eventId}` });
    };

    const onFinish = () => {
        getEventDataAction(params?.eventId!)
    }

    const handleToggleReport = ({ status }: { status?: "success" }) => {
        setModalReport({ isOpen: false })
        if (status === "success") {
            setThankYou({ isOpen: true })
        }
    }

    const handleThankToggle = () => {
        setThankYou({ isOpen: false })
        getEventDataAction(params?.eventId!)
    }

    const joinModalToggle = () => {
        navigate(RoutePathConst.private.scan)
    }

    const joinEventAction = () => {
        dispatch(setLoading(true))
        joinEventAPI({ eventId: params?.eventId!, deviceId }).then(res => {
            if (res.status === 200) {
                toastSuccess(res.message)
                getEventDataAction(params?.eventId!)
                setJoinModal(false)
                dispatch(setLoading(false))
            }
        }).catch(() => {
            setJoinModal(false)
            navigate(RoutePathConst.private.scan)
        })
    }

    useEffect(() => {
        const handleEventSocket = (body: { blckChainId: string, txId: string, eventId: string }) => {
            setData((res: any) => {
                res.blckChainId = body.blckChainId
                res.eventId = body?.eventId
                return { ...res }
            })
        }
        if (data?.blckChainId === null && data?.eventId) {
            socket.on(`event_${data?.eventId}`, handleEventSocket)
        }
        return () => {
            socket.off(`event_${data?.eventId}`, handleEventSocket)
        }
    }, [data?.eventId])

    return (
        <>
            <NFTHeader />
            <Modal centered isOpen={joinModal}>
                <ModalBody>
                    <p className="fw-600 font-20">Join Event</p>
                    <p className="mt-20">Are you sure you want to join this Private Event?</p>
                    <div className="d-flex mt-20 gap-3 justify-content-end">
                        <button className="custom-primary-outline" onClick={joinModalToggle}>Cancel</button>
                        <button className="custom-primary" onClick={joinEventAction}>Join</button>
                    </div>
                </ModalBody>
            </Modal>
            <Container className="mt-100">{
                isLoading ? <LoadingComponent />
                    :
                    data &&
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <FontAwesomeIcon icon={faArrowLeftLong} className="cursor-pointer" onClick={() => navigate(RoutePathConst.private.event)} />
                            <p className="font-26 c-black fw-600">Event Details</p>
                            <img src={images.qrCode} alt="qrcode" onClick={handleToggle} className="cursor-pointer" />
                        </div>
                        <div className="w-100 position-relative">
                            {
                                data?.type === "private" ?
                                    <div style={{ right: "10px", bottom: "10px" }} className="position-absolute text-danger bg-white px-1 rounded-1">
                                        Private
                                    </div> : null
                            }
                            <ImageComponent src={data?.logo} className="mt-20" />
                        </div>
                        <p className="c-black font-26 fw-600 mt-20 text-break">{data?.name}</p>
                        <div className="d-flex mt-20 gap-3">
                            <div className="bg-danger-subtle d-flex justify-content-center align-items-center rounded-10" style={{ width: "50px", height: "50px", minWidth: "50px" }}>
                                <FontAwesomeIcon className="text-danger" size="xl" icon={faCalendarDays} />
                            </div>
                            <div>
                                <p className="c-black fw-600">{getFormattedDateMonths(data?.startTime!)}</p>
                                <p className="c-black">{getFormattedDateDaysTime(data?.startTime)}</p>
                            </div>
                        </div>
                        <div className="d-flex mt-20 gap-3">
                            <div className="bg-danger-subtle d-flex justify-content-center align-items-center rounded-10" style={{ width: "50px", height: "50px", minWidth: "50px" }}>
                                <FontAwesomeIcon className="text-danger" size="xl" icon={faLocationDot} />
                            </div>
                            <div>
                                <p className="c-black fw-600">Location</p>
                                <p className="c-black text-break">{data?.address}</p>
                            </div>
                        </div>

                        {
                            data?.blckChainId ?
                                <div className="d-flex mt-20 gap-3">
                                    <div className="bg-danger-subtle d-flex justify-content-center align-items-center rounded-10" style={{ width: "50px", height: "50px", minWidth: "50px" }}>
                                        <FontAwesomeIcon icon={faCubes} className="text-danger" size="xl" />
                                    </div>
                                    <div>
                                        <p className="c-black fw-600">Blockchain ID</p>
                                        <div className="d-flex justify-content-between gap-3">
                                            <p className="c-black text-break">{data?.blckChainId}</p>
                                            <p className="text-danger cursor-pointer" onClick={() => {
                                                navigate("/validate-hash?validateHash=" + data?.blckChainId, { state: { hashType: moduleType.event } })
                                            }}>Verify</p>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {data?.createdBy?.companyName && <>
                            <div className='d-flex mt-20 gap-3 align-items-center'>
                                <img src={data?.createdBy?.profilePic} alt={data?.createdBy?.companyName} className="rounded-10 cursor-pointer object-fit-cover" style={{ width: "50px", height: "50px", minWidth: "50px" }} />
                                <div className='mt-10'>
                                    <p className='fw-600 font-ubuntu font-14'>{data?.createdBy?.companyName}</p>
                                    <p className="mt-1 font-ubuntu font-14">
                                        This event is hosted by the organization.
                                    </p>
                                </div>
                            </div>
                        </>}
                        <p className="c-black font-20 mt-20 fw-600">About Event</p>
                        <p className="c-black text-break">{data?.description}</p>
                        {
                            data?.reviews?.length > 0 ?
                                <>
                                    <p className="text-danger mt-30 fw-600">Submit reports</p>
                                    {
                                        data?.reviews?.map((res, index) => (
                                            <ReportCheckpointCard data={res} lastIndex={index + 1 === data?.reviews?.length} index={index + 1} key={index} />
                                        ))
                                    }
                                </>
                                : null
                        }
                        {
                            new Date().getTime() > new Date(data?.startTime).getTime()
                                ? <>
                                    <button className="custom-primary w-100 mt-20" onClick={() => navigate(RoutePathConst.private.scan)}>Start Scanning</button>
                                    {
                                        data?.reviews?.length > 0 ?
                                            <button className="custom-primary-outline w-100 mt-20" onClick={() => {
                                                setModalReport({ isOpen: true })
                                            }}>Submit Report</button>
                                            : null
                                    }
                                </>
                                :
                                <>
                                    <CountdownTimer futureDate={data?.startTime} onFinish={onFinish} />
                                </>
                        }
                    </>
            }
            </Container>
            <NFTQrCodeModal handleToggle={handleToggle} modal={modal} />
            <ReportSubmitModal modal={modalReport} data={data} handleToggle={handleToggleReport} type="event" />
            <Modal isOpen={thankYou.isOpen} centered>
                <ModalBody className="p-4 text-center position-relative">
                    <FontAwesomeIcon icon={faXmark} onClick={handleThankToggle} className="cursor-pointer c-green r-25 position-absolute" />
                    <h1 className="c-green fw-600">Thank You</h1>
                    <p className="c-black font-20 mt-20 fw-600">Thank you for being part of CheckPoints on Blockchain</p>
                    <p className="text-danger font-20 mt-20 fw-600">Your CheckPoints score is {data?.reviews?.length}</p>
                    <p className="c-gray mt-20 ">Your completed CheckPoints were sent to your e-mail address. For more info Admin will get back to you.</p>
                    <button className="custom-primary-outline mt-20 w-100" onClick={handleThankToggle}>Go Back</button>
                </ModalBody>
            </Modal>
            <NFTFooter />
        </>
    )
}

export default EventDetails