/* eslint-disable react-hooks/exhaustive-deps */
import { faEnvelope, faEye, faEyeSlash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import * as Yup from "yup";
import { loginAPI } from "../../apis/authAPI/loginAPI";
import { registerAPI } from "../../apis/authAPI/registerAPI";
import { setLogin } from "../../store/AuthSlice";
import { setLoading } from "../../store/LoaderSlice";
import { RootState } from "../../store/Store";
import { setUserLoginData } from "../../store/UserSlice";
import { authType, cookieKeys, onlyNewPassword } from "../../utils/constants/Constants";
import { RoutePathConst } from "../../utils/constants/RoutesPathConst";
import { environment } from "../../utils/constants/environment";
import { encryptString, filterParameter, handleFormikTrim, setEncryptedCookie, toastError, toastSuccess } from "../../utils/functions/CommonFunctions";
import CompanyInfo from "./components/CompanyInfo";
import LoginQRCodeComponent from "./components/LoginQRCode";
import "./login.scss";

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { deviceId } = useSelector((state: RootState) => state.deviceId)
  const [login, setLoginState] = useState<authType>(authType.login)
  const [passwordIcon, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  useEffect(() => {
    resetForm()
    if (location?.pathname === RoutePathConst.public.LoginQRCode) {
      setLoginState(() => authType.loginQRCode)
    } else if (location?.pathname === RoutePathConst.public.register) {
      setLoginState(() => authType.register)
    } else {
      setLoginState(() => authType.login)
    }
  }, [login, location])

  const handleLogin = (data: any) => {
    const params = filterParameter(data)
    loginAPI(params).then(res => {
      if (res.status === 200) {
        toastSuccess(res.message)
        dispatch(setLogin(true))
        dispatch(setUserLoginData(res.data.user))
        // dispatch(setNewNotification(true))
        dispatch(setLoading(false))
        setEncryptedCookie(cookieKeys.cookieUser, res.data)
      }
    }).catch((err) => {
      if (err.status === 403) {
        navigate(RoutePathConst.public.resendEmail);
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      fullName: login.includes('Register') ? Yup.string().required("Full name is required").min(3, "Minimum 3 characters required").max(80, "Maximum 80 characters allowed") : Yup.string(),
      email: Yup.string().email("Email is invalid").required("Email is required"),
      password: Yup.string().required("Password is required").matches(onlyNewPassword, "Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character")
    }),
    onSubmit: (value) => {
      dispatch(setLoading(true))
      const data = {
        email: value.email,
        password: value.password,
        name: value?.fullName,
        deviceId,
        loginType: "normal",
        deviceType: "0",
      }
      const params = filterParameter(data)
      if (login === authType.login) {
        handleLogin(params)
      } else if (login === authType.register) {
        registerAPI(params).then(res => {
          if (res.status === 200) {
            toastSuccess(res.message)
            navigate(RoutePathConst.public.login)
            dispatch(setLoading(false))
          }
        })
      }
    }
  })
  const { errors, handleSubmit, resetForm, values, handleBlur, setFieldValue, touched } = formik

  // @ts-ignore
  const togglePassword = (key: string) => setShowPassword({ ...passwordIcon, [key]: !passwordIcon?.[key] });

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, name: string) => {
    if (name !== "password") {
      e.key.toLowerCase() === "enter" && handleSubmit();
    }
  }

  const responseGoogle = (response: any) => {
    dispatch(setLoading(true))
    const decoded: any = jwt_decode(response?.credential);
    handleLogin({ email: decoded?.email, deviceType: "0", deviceId, loginType: "social", idToken: encryptString(response?.credential), authId: encryptString(environment?.googleClientId || "") });
  };



  return (
    <div className="d-flex flex-column flex-xl-row flex-md-row justify-content-xl-around justify-content-center gap-2 flex-wrap align-items-center responsive-login">
      <CompanyInfo />
      <div className="bg-white overflow-hidden rounded-10 shadow login">

        <div className="p-40 login-layout">

          {
            login === authType.loginQRCode ? <>
              <LoginQRCodeComponent deviceId={deviceId} />
            </> : <>


              <div className="d-flex justify-content-between align-items-center">
                <p className="font-26">
                  {/* {login === authType.login ? authType.login : authType.register} */}
                  {login === authType.login ? "Sign In" : "Sign Up"}
                </p>
              </div>
              <p className="c-gray mt-1">Continue with TowerConnect</p>
              {
                login === authType.register ?
                  <div className="mt-30">
                    <InputGroup>
                      <Input
                        invalid={Boolean(touched?.fullName && errors?.fullName)}
                        value={values.fullName} name="fullName" id="fullName" onChange={(e) => handleFormikTrim(e.target.name, e.target.value, setFieldValue)} onBlur={handleBlur}
                        placeholder="Full name"
                        onKeyDown={(e) => onKeyPress(e, "fullName")}
                      />
                      <InputGroupText
                        className={touched?.fullName && errors?.fullName ? "input-group-wrapper pass-input" : "input-group-wrapper"}
                      >
                        <InputGroupText style={{ border: 0 }}><FontAwesomeIcon icon={faUser} /></InputGroupText>
                      </InputGroupText>
                    </InputGroup>
                    {touched?.fullName && errors?.fullName && <span className="text-danger">{errors.fullName}</span>}
                  </div> : null
              }
              <div className={`${login ? "mt-30" : "mt-20"} `}>
                <InputGroup>
                  <Input
                    invalid={Boolean(touched?.email && errors?.email)}
                    onChange={(e) => handleFormikTrim(e.target.name, e.target.value, setFieldValue)} value={values.email} name="email" id="email" onBlur={handleBlur} placeholder="Email"
                    onKeyDown={(e) => onKeyPress(e, "email")}
                  />
                  <InputGroupText
                    className={touched?.email && errors?.email ? "input-group-wrapper pass-input" : "input-group-wrapper"}
                  >
                    <InputGroupText style={{ border: 0 }}><FontAwesomeIcon icon={faEnvelope} /></InputGroupText>
                  </InputGroupText>
                </InputGroup>
                {touched?.email && errors?.email && <span className="text-danger">{errors.email}</span>}
              </div>
              <div className="mt-20">
                <InputGroup>
                  <Input
                    type={passwordIcon.password ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    name="password"
                    onKeyDown={(e) => onKeyPress(e, "password")}
                    onChange={(e) => handleFormikTrim(e.target.name, e.target.value, setFieldValue)}
                    onBlur={handleBlur}
                    value={values?.password}
                    onKeyPress={(e) => {
                      e.key.toLowerCase() === "enter" && handleSubmit();
                    }}
                    invalid={Boolean(touched?.password && errors?.password)}
                  />
                  <InputGroupText
                    className={touched?.password && errors?.password ? "cursor-pointer input-group-wrapper pass-input" : "input-group-wrapper cursor-pointer"}
                    onClick={() => togglePassword("password")}>
                    <InputGroupText style={{ border: 0 }}>{passwordIcon.password ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</InputGroupText>
                  </InputGroupText>
                </InputGroup>
                {touched?.password && errors?.password && <span className="text-danger">{errors.password}</span>}
              </div>
              {
                login === authType.login ? <div className="d-flex justify-content-end mt-20"><p className="c-green cursor-pointer" onClick={() => navigate(RoutePathConst.public.forgotPassword)}>Forgot Password?</p></div> : null
              }
              <button type="submit" className={`custom-primary text-uppercase ${login ? "mt-20" : "mt-20"} w-100 fw-600`} onClick={() => handleSubmit()}>
                {login === authType.login ? "Sign In" : "Sign Up"}
              </button>
              <div className="d-flex my-2 align-items-center gap-3 justify-content-between">
                <hr className="w-100" />
                <p className="text-center fw-600">
                  OR
                </p>
                <hr className="w-100" />
              </div>
              <div className="d-flex justify-content-center">
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    responseGoogle(credentialResponse)
                  }}
                  onError={() => {
                    toastError('Login Failed')
                  }}
                  useOneTap
                />
              </div>
              {
                login === authType.login ?
                  <p className="c-gray mt-20 text-center">Don’t have an account? <span className="c-green cursor-pointer" onClick={() => navigate(RoutePathConst.public.register)}>Sign Up</span></p> : null}{
                login === authType.register ?
                  <p className="c-gray mt-20 text-center">Already have an account? <span className="c-green cursor-pointer" onClick={() => navigate(RoutePathConst.public.login)}>Sign In</span></p>
                  : null
              }
              {login === authType.login ? <div className="d-flex justify-content-center">
                <p className="c-green text-center cursor-pointer prevent-select" onClick={() => navigate(RoutePathConst.public.LoginQRCode)}>Login with QR Code</p>
              </div> : null}

            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Login


