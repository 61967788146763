import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const getReviewAPI = (params: IGetReviewAPI): Promise<IGetReviewAPIRes> => {
    return HTTPService.get(API_END_POINTS.review.get, params)
}

interface IGetReviewAPI {
    deviceId: string;
    type: string;
}

export interface IGetReviewAPIResData {
    images: string[] | [],
    status: number,
    comment: string,
    eventId: null | string,
    qrCodeId: null | string,
    quesId: string,
    checkPointId: string,
    reviewId: string,
    deviceId: string,
    location: null | string,
    txId: string,
    createdAt: string,
    updatedAt: string,
    qrCode: {
        name: string,
        description: string
    },
    question: {
        text: string
    }
}

interface IGetReviewAPIRes extends IApiRes {
    data: IGetReviewAPIResData[]
}