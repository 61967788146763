import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { IApiRes } from "../ActionType";

export const GetOrganizationAPI = (body: any): Promise<IGetOrganizationAPI> => {
    return HTTPService.get(API_END_POINTS.organization.get + body);
};

export interface IGetOrganizationAPIData {
    firstName: string,
    lastName: string,
    email: string,
    profilePic: string,
    bannerImage: string,
    userId: string,
    companyName: string | null,
    description: string | null,
    usages: {
        consumedQrCodes: number,
        consumedCheckPoints: number,
        consumedEvents: number,
        consumedNfts: number,
        consumedMapMarkers: number
    },
    orgSocials: {
        link:string;
        logo:string;
        name:string;
        socialId:string;
        type:string;
    }[]
}

interface IGetOrganizationAPI extends IApiRes {
    data: IGetOrganizationAPIData
}
