import moment from "moment";
import { useEffect, useState } from "react";

const TimeStamp = ({ timer }: { timer: string }) => {
	const [formattedTime, setFormattedTime] = useState(moment(timer).fromNow());

	useEffect(() => {
		// Update the formatted time every minute
		const intervalId = setInterval(() => {
			setFormattedTime(moment(timer).fromNow());
		}, 60000); // 60000 ms = 1 minute

		// Clean up the interval when the component is unmounted
		return () => clearInterval(intervalId);
	}, [timer]);

	return <p className="text-capitalize c-gray">{formattedTime}</p>;
};

export default TimeStamp;
