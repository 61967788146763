import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	deviceId: ""
};

const deviceIdSlice = createSlice({
	name: "deviceId",
	initialState,
	reducers: {
		setDeviceId: (state, action) => {
			state.deviceId = action.payload;
		}
	}
});

export const { setDeviceId } = deviceIdSlice.actions;

export default deviceIdSlice.reducer;
