import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { GetReportListAPI, IGetReportListAPIRes } from "../../apis/validateHash/GetReportListAPI";
import { RoutePathConst } from "../../utils/constants/RoutesPathConst";
import NFTFooter from "../nft/components/NFTFooter";
import LogoSection from "./components/LogoSection";
import ValidateHashCard from "./components/ValidateHashCard";

const ValidateHashById = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [reportList, setReportList] = useState<IGetReportListAPIRes[]>([]);

	const getReportListAction = (id: string) => {
		setIsLoading(true);
		GetReportListAPI(id)
			.then((res) => {
				if (res.status === 200) {
					setReportList(res.data);
					setIsLoading(false);
				} else if (res.status === 400 || res.status === 404) {
					navigate(RoutePathConst.public.validateHash);
					setIsLoading(false);
				}
			})
			.catch(() => {
				navigate(RoutePathConst.public.validateHash);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (params?.id) {
			getReportListAction(params?.id);
		} else {
			setIsLoading(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.id]);

	return (
		<div className="p-validate">
			<LogoSection />
			<div className="p-validate-card">
				<div className="p-validate-card-report">
					{isLoading ? (
						<div className="d-flex justify-content-center align-items-center">
							<Spinner />
							<p className="ml-10 fw-600">Loading...</p>
						</div>
					) : (
						<>
							<div className="d-flex justify-content-between align-items-center">
								<h4 className="fw-600 text-center">Report Details</h4>
								<p className="fw-bold cursor-pointer text-white" onClick={() => navigate(RoutePathConst.public.validateHash)}>
									Verify Hash
								</p>
							</div>
							<div className="overflow-auto mt-20 pb-2 validate-hash-media">
								{reportList?.map((item, index) => (
									<ValidateHashCard index={index} item={item} key={index} />
								))}
							</div>
						</>
					)}
				</div>
			</div>
			<footer className={"d-flex footer-validate justify-content-center w-100"}>
				<NFTFooter />
			</footer>
		</div>
	);
};

export default ValidateHashById;
