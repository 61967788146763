import { Navigate, Outlet, RouteObject } from "react-router-dom";
import EventRedirectModal from "../pages/EventRedirect/EventRedirectModal";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import ResendEmail from "../pages/auth/ResendEmail";
import ResetPassword from "../pages/auth/ResetPassword";
import WelcomePage from "../pages/auth/components/WelcomePageIos";
import Checkpoint from "../pages/checkpoint/Checkpoint";
import CheckpointDetails from "../pages/checkpoint/checkpointDetails/CheckpointDetails";
import Event from "../pages/event/Event";
import EventDetails from "../pages/event/eventDetails/EventDetails";
import NFTDetails from "../pages/nft/NFTDetails";
import NFTListComponent from "../pages/nft/NFTListComponent";
import { QRCodeScanner } from "../pages/nft/components/QRCodeScanner";
import NFTForm from "../pages/nftForm/NFTForm";
import OwnNFT from "../pages/ownNft/OwnNFT";
import Profile from "../pages/profile/Profile";
import ScanQRCode from "../pages/scanQRCode/ScanQRCode";
import SmartCityMap from "../pages/smartCityMap/SmartCityMap";
import UnderMaintenance from "../pages/underMaintenance/UnderMaintenance";
import ValidateHash from "../pages/validateHash/ValidateHash";
import ValidateHashById from "../pages/validateHash/ValidateHashById";
import { RoutePathConst } from "../utils/constants/RoutesPathConst";

export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <NFTListComponent />
      },
      {
        path: "/qr-code-scanner",
        element: <QRCodeScanner />
      },
      {
        // welcome page for ios
        path: RoutePathConst.public.welcomePageIos,
        element: <WelcomePage />,
      },
      {
        path: RoutePathConst.public.allNft,
        element: <NFTListComponent />,
      },
      {
        path: RoutePathConst.public.nftDetails,
        element: <NFTDetails />,
      },
      {
        path: RoutePathConst.public.userResetPassword,
        element: <ResetPassword />,
      },
      {
        path: RoutePathConst.public.verifyEmail,
        element: <NFTListComponent />
      },
      {
        path: RoutePathConst.public.smartCity,
        element: <SmartCityMap />
      },
      {
        path: RoutePathConst.public.validateHash,
        element: <ValidateHash />
      },
      {
        path: RoutePathConst.public.validateHashId,
        element: <ValidateHashById />
      },
      {
        path: RoutePathConst.public.qrCode,
        element: <EventRedirectModal />
      },
      {
        path: RoutePathConst.public.login,
        element: <Login />
      },
      {
        path: RoutePathConst.public.register,
        element: <Login />
      },
      {
        path: RoutePathConst.public.LoginQRCode,
        element: <Login />
      },
      {
        path: RoutePathConst.public.forgotPassword,
        element: <ForgotPassword />
      },
      {
        path: RoutePathConst.public.resendEmail,
        element: <ResendEmail />
      },
      // checkpoint page
      {
        path: RoutePathConst.private.checkpoint,
        element: <Checkpoint />
      },
      {
        path: RoutePathConst.private.checkpointId,
        element: <CheckpointDetails />
      },
      {
        path: RoutePathConst.private.scan,
        element: <ScanQRCode />
      },
      // Events
      {
        path: RoutePathConst.private.event,
        element: <Event />
      }, {
        path: RoutePathConst.private.eventId,
        element: <EventDetails />
      },
      { path: "*", element: <Navigate to={RoutePathConst.public.allNft} /> },
    ],
  },
];

export const privateRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <NFTListComponent />,
      },
      {
        path: "/qr-code-scanner",
        element: <QRCodeScanner />
      },
      {
        // welcome page for ios
        path: RoutePathConst.public.welcomePageIos,
        element: <WelcomePage />,
      },
      {
        path: RoutePathConst.public.allNft,
        element: <NFTListComponent />,
      },
      {
        path: RoutePathConst.public.nftDetails,
        element: <NFTDetails />,
      },
      {
        path: RoutePathConst.private.ownNft,
        element: <OwnNFT />,
      },
      {
        path: RoutePathConst.public.userResetPassword,
        element: <ResetPassword />,
      },
      {
        path: RoutePathConst.public.verifyEmail,
        element: <NFTListComponent />
      },
      {
        path: RoutePathConst.public.smartCity,
        element: <SmartCityMap />
      },
      {
        path: RoutePathConst.public.validateHash,
        element: <ValidateHash />
      },
      {
        path: RoutePathConst.public.validateHashId,
        element: <ValidateHashById />
      },
      {
        path: RoutePathConst.public.qrCode,
        element: <EventRedirectModal />
      },
      {
        path: RoutePathConst.private.profile,
        element: <Profile />
      },
      {
        path: RoutePathConst.private.createNFT,
        element: <NFTForm />
      },
      // checkpoint page
      {
        path: RoutePathConst.private.checkpoint,
        element: <Checkpoint />
      },
      {
        path: RoutePathConst.private.checkpointId,
        element: <CheckpointDetails />
      },
      {
        path: RoutePathConst.private.scan,
        element: <ScanQRCode />
      },
      // Events
      {
        path: RoutePathConst.private.event,
        element: <Event />
      }, {
        path: RoutePathConst.private.eventId,
        element: <EventDetails />
      },
      { path: "*", element: <Navigate to={RoutePathConst.public.allNft} /> },
    ],
  },
];

export const underMaintenanceRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <UnderMaintenance />,
      },
      {
        path: RoutePathConst.public.underMaintenance,
        element: <UnderMaintenance />
      },
      { path: "*", element: <Navigate to={RoutePathConst.public.underMaintenance} /> },
    ],
  },
];
