import { useNavigate } from "react-router-dom"
import { images } from "../../../assets/images/Images"
import { RoutePathConst } from "../../../utils/constants/RoutesPathConst"
import { environment } from "../../../utils/constants/environment"
import { redirectNewTap } from "../../../utils/functions/CommonFunctions"

const CompanyInfo = () => {
    const navigate = useNavigate()
    return (
        <div className="d-flex flex-column align-items-center mb-sm-2">
            <img src={images.logo} width={350} alt="logo" className="cursor-pointer logo-responsive" onClick={() => navigate(RoutePathConst.public.allNft)} />
            <hr className="w-100" />
            <p className="c-black font-20">Say <b>HELLO</b> to BLOCKCHAIN and</p>
            <p className="c-black font-20 text-change"><b>MAKE</b> your EXPERIENCES with innovative tech</p>
            <div className="d-flex mt-10 justify-content-center align-items-center gap-1">
                <p className="c-black fw-600">Powered by</p>
                <img src={images.edexaLogo} alt="edexa-logo" className="cursor-pointer" onClick={() => redirectNewTap(environment.edexaUrl || "")} />
            </div>
        </div>
    )
}

export default CompanyInfo