import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { IPublicGetByNFTAPIData } from "../../../apis/nftAPI/GetByNFTAPI";
import { NFTReserveAPI } from "../../../apis/nftAPI/NFTReserveAPI";
import { TransferNFTAPI } from "../../../apis/nftAPI/NFTTransferAPI";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { setLoading } from "../../../store/LoaderSlice";
import { reverseNFTStatus } from "../../../utils/constants/Constants";
import { RoutePathConst } from '../../../utils/constants/RoutesPathConst';
import { toastSuccess } from "../../../utils/functions/CommonFunctions";
import NFTTransferToggle from "./NFTTransferToggle";

interface INFTReserveToggle {
	data: IPublicGetByNFTAPIData | undefined;
	status: reverseNFTStatus | null;
	getAction: (id: string) => void;
}

const NFTReserveToggle = ({ data, status, getAction }: INFTReserveToggle) => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modal, setModal] = useState({
		open: false,
	});
	const [isOpen, setIsopen] = useState<any>({
		open: false,
		label: "",
		key: "",
		checked: ""
	});

	const handleModalToggle = () => {
		setModal({ open: false })
	}
	const handleToggle = () => {
		if (isOpen?.callBack) {
			setModal({ open: true })
		}
		setIsopen({
			open: false,
			label: "",
			key: "",
			checked: "",
		});
	};


	const handleAction = () => {
		if (status === reverseNFTStatus.reserveRequest) {
			dispatch(setLoading(true));
			const isReserved = true;
			NFTReserveAPI(isOpen.checked, isReserved).then(res => {
				if (res.status === 200) {
					handleToggle();
					toastSuccess(res.message);
					dispatch(setLoading(false));
					getAction(params?.id || "")
				}
			})
		} else {
			dispatch(setLoading(true));
			const body = {
				userId: isOpen?.key,
				id: params?.id!
			}
			TransferNFTAPI(body).then((res) => {
				if (res.status === 200) {
					handleToggle();
					toastSuccess(res.message);
					dispatch(setLoading(false));
					// getAction(params?.id || "") // To stop the API call after the sucessfull transfer of  public and private NFT
					navigate(RoutePathConst.public.allNft);
					isOpen.callBack()
				}
			})
		}
	}
	const handleClick = (modalType: "reserve" | "transfer", key?: string, callBack?: () => void) => {
		let text = ""
		if (modalType === "reserve") {
			text = `Are you sure you want to reserve this <span class="fw-600">${data?.title}</span> Digital Owner?`
		}
		if (modalType === "transfer") {
			text = `Are you sure you want to transfer, ownership this <span class="fw-600">${data?.title}</span> Digital Owner?`
		}
		setIsopen({
			open: true,
			label: text,
			key: key || "",
			checked: data?.id || "",
			callBack
		});
	}

	return (
		<div className="w-100 rounded-10 mt-10 bg-white p-20">
			<div className="d-flex justify-content-between align-items-center">
				<h5 className="fw-600 mb-0 c-black">Digital Owner</h5>
				{
					status === reverseNFTStatus.allReadyRequest ?
						<span className="fw-600 font-14 text-uppercase px-10 c-green prevent-select">Already Requested</span>
						: null
				}
				{
					status === reverseNFTStatus.reserveRequest ?
						<Button onClick={() => handleClick("reserve")} className="c-bg-green border-0">
							Reserve Digital Owner
						</Button>
						: null
				}
				{
					status === reverseNFTStatus.transferNFT ?
						<Button
							// onClick={() =>
							// 	dispatch(setComingSoonModal({ isOpen: true, label: "transfer" }))
							// }
							onClick={() => setModal({ open: true })}
							className="c-bg-green border-0">
							Transfer Digital Owner
						</Button>
						: null
				}
			</div>
			<div
				className="row overflow-hidden gap-lg-0 gap-3 mt-10">
				<p>
					{
						status === reverseNFTStatus.transferNFT ?
							"Need to add wallet address to transfer Digital Owner. Once Digital Owner is transferred, ownership will be transferred and you cannot access this Digital Owner anymore."
							: "You can reserve the Digital Owner online. You will be notified once the process has been completed."
					}
				</p>
			</div>
			<ConfirmationModal isOpen={isOpen} toggle={handleToggle} handleAction={handleAction} />
			<NFTTransferToggle handleClick={handleClick} handleClose={handleModalToggle} isOpen={modal} />
		</div >
	);
};

export default NFTReserveToggle;
