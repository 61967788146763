import { createSlice } from "@reduxjs/toolkit";
import { cookieKeys } from "../utils/constants/Constants";
import { getDecryyptedCookie } from "../utils/functions/CommonFunctions";

const initialState = {
	user: getDecryyptedCookie(cookieKeys.cookieUser)?.user || {
		email: "",
		name: "",
		userId: "",
		createdAt: "",
		loginType: "",
		walletAddress: "",
		profileImage: "",
		isNewUser: false
	}
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserLoginData: (state, action) => {
			state.user = action.payload;
		}
	}
});

export const { setUserLoginData } = userSlice.actions;

export default userSlice.reducer;
