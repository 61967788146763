export const API_END_POINTS = {
	USER_APP: {
		VERIFY: "user/verify-token",
		FORGET_PASSWORD: "user/reset-password"
	},
	NFT: {
		CREATE_NFT: "user/mint",
		GET_NFT: "nft",
		GET_BY_NFT: "nft",
		TRANSFER_NFT: "user/nft/transfer",
		UPDATE_NFT: "user/nft/",
		DELETE_NFT: "nft/",
		RESERVE_NFT: "user/nft/"
	},
	VALIDATE_HASH: {
		GET_REPORT_LIST: "report/",
		GET_VALIDATE_HASH: "review/hash/",
		GET_EVENT_VALIDATE_HASH: "event/hash/"
	},
	SMART_CITY_MAP: {
		GET: "map-box"
	},
	CATEGORY: {
		GET: "category"
	},
	UNDER_MAINTENANCE: {
		GET: "settings"
	},
	USER: {
		login: "user/signin",
		register: "user/signup",
		logout: "user/signout",
		updateProfile: "user/profile",
		userValidate: "user/validate",
		forgotPassword: "user/forget-password",
		resendInvitation: "user/resend-invitation"
	},
	OWN: {
		GET_NFT_LIST: "user/nft",
		GET_BY_NFT: "user/nft/"
	},
	NOTIFICATION: {
		get: "user/notifications",
		delete: "user/notifications/"
	},
	checkpoint: {
		get: "/check-points"
	},
	review: {
		create: "/review",
		get: "/review"
	},
	report: {
		create: "/report"
	},
	event: {
		getList: "/event/",
		getById: "/event",
		joinEvent: "/event"
	},
	qrCode: {
		getCommentData: "/qr-code"
	},
	labels: "/labels",
	organization:{
		get:"/organizations/"
	}
};
