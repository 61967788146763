import HTTPService from "../../axios/AxiosService";
import { API_END_POINTS } from "../../utils/constants/ApiEndPoints";
import { towerConnectType } from "../../utils/constants/Constants";
import { IApiRes } from "../ActionType";

export const GetSmartCityMapAPI = (): Promise<IGetSmartCityMapAPI> => {
	return HTTPService.get(API_END_POINTS.SMART_CITY_MAP.GET);
};

export interface IGetSmartCityMapAPIData {
	createdAt: string;
	dataId: string;
	id: number;
	latitude: string;
	longitude: string;
	marker: {
		bannerImage: string;
		blckChainId: string;
		description: string;
		image: string;
		id: string;
		name: string;
		createdByAdmin:{
			profilePic:string;
			bannerImage:string;
			userId:string;
			companyName:string;
		},
		organization: {
			bannerImage: string;
			companyName: string;
			profilePic: string;
			userId: string;
		},
	},
	type: towerConnectType;
	updatedAt: string;
}

interface IGetSmartCityMapAPI extends IApiRes {
	data: {
		data: IGetSmartCityMapAPIData[];
		count: number;
	};
}
